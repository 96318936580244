import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../../components/DizeeComponent/DixeeInput2';
import { useDispatch, useSelector } from 'react-redux';
import { clearEvent } from '../../../../../store/eventData/eventdataSlice';
import AddedEvent from '../../../../../components/AddSection/Event/AddedEvent';
import EventOverlay from '../../../../../components/AddSection/Event/EventOverlay';
import CopyLink from '../../../../../svg/CopyLink';
import { getEventThunk } from '../../../../../store/addsection/addsectionThunk';
import Plus from '../../../../../svg/Plus';
import { clearAlerts, setErrorAlert } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import { updateBandsintownSettings } from '../../../../../store/bandsintown/bandsintownSlice';
import { ReactComponent as BandsintownLogo } from '../../../../../assets/images/components/bandsintown-svgrepo-com.svg';

export default function BlukImportEvent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('dizeeToken');
    const [apiKey, setApiKey] = useState('');
    const [artistName, setArtistName] = useState('');
    const [queuedEvents, setQueuedEvents] = useState(() => {
        const saved = localStorage.getItem('eventQueueEntries');
        return saved ? JSON.parse(saved) : [];
    });
    const [error, setError] = useState('');
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [linkForBackend, setLinkForBackend] = useState('');
    const [itemForBackend, setItemForBackend] = useState({});
    
    const event = useSelector((state) => state.addsection.event);
    const bandsintownSettings = useSelector(state => state.bandsintown.settings);

    useEffect(() => {
        dispatch(clearAlerts());
        dispatch(getEventThunk({ token }));
    }, [dispatch, token]);

    useEffect(() => {
        if (bandsintownSettings) {
            setApiKey(bandsintownSettings.apiKey || '');
            setArtistName(bandsintownSettings.artistName || '');
        }
    }, [bandsintownSettings]);

    const handleAdd = async () => {
        if (!apiKey || !artistName) {
            dispatch(setErrorAlert('Please fill in both API key and artist name'));
            return;
        }
        
        try {
            try {
                await dispatch(updateBandsintownSettings({ 
                    token,
                    artistName: artistName.trim(),
                    apiKey: apiKey.trim()
                })).unwrap();
            } catch (settingsError) {
                console.log('Settings update attempted');
            }

            const response = await fetch(
                `https://rest.bandsintown.com/artists/${encodeURIComponent(artistName.trim())}/events?app_id=${apiKey.trim()}`
            );
            
            if (!response.ok) {
                throw new Error('Failed to fetch events');
            }

            const data = await response.json();

            if (Array.isArray(data) && data.length > 0) {
                const existingUrls = event?.map(e => e.links?.[0]?.url) || [];
                const queueUrls = queuedEvents.map(e => e.links?.[0]?.url);
                const allExistingUrls = [...existingUrls, ...queueUrls];

                const formattedEvents = data
                    .filter(e => !allExistingUrls.includes(e.url))
                    .map(event => {
                        const formattedDate = new Date(event.datetime).toLocaleDateString('en-US', { 
                            year: 'numeric',
                            month: 'short', 
                            day: 'numeric'
                        });
                        
                        return {
                            id: Date.now() + Math.random(),
                            type: 2,
                            avatar: null,
                            venue: event.venue.name,
                            date: formattedDate,
                            location: `${event.venue.city}, ${event.venue.country}`,
                            link: event.url,
                            links: [{
                                source: 'bandsintown',
                                url: event.url
                            }],
                            publish: {
                                value: 'draft'
                            }
                        };
                    });

                if (formattedEvents.length === 0) {
                    dispatch(setErrorAlert('No new events found for this artist'));
                    return;
                }
                
                const updatedQueue = [...queuedEvents, ...formattedEvents];
                setQueuedEvents(updatedQueue);
                localStorage.setItem('eventQueueEntries', JSON.stringify(updatedQueue));
            } else {
                dispatch(setErrorAlert('No events found for this artist'));
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            dispatch(setErrorAlert('Error fetching events. Please check your API key and artist name.'));
        }
    };

    const handleGoBack = () => {
        dispatch(clearEvent());
        navigate('/add-section/add-event');
    };

    const handleNext = () => {
        if (queuedEvents.length > 0) {
            navigate('/add-section/event/publish', { 
                state: { 
                    entries: queuedEvents,
                    returnPath: '/add-section/bulk-import-events'
                } 
            });
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEvents = queuedEvents.filter((_, i) => i !== index);
        setQueuedEvents(newEvents);
        localStorage.setItem('eventQueueEntries', JSON.stringify(newEvents));
    };

    const showAdd = apiKey && artistName;
    const showNext = queuedEvents.length > 0;

    return (
        <LayoutHeader>
            <div className="w-[350px] sm:w-[390px] h-[80vh] bg-black flex flex-col items-center relative">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[8px]'>
                        <BandsintownLogo className="w-[15px] h-[15px]" />
                        <span className='text-white'>Add API key</span>
                    </div>
                    <div className='flex gap-[30px]'>
                        {showAdd && (
                            <button onClick={handleAdd} className='text-white cursor-pointer'>Sync</button>
                        )}
                        {showNext && (
                            <button onClick={handleNext} className='text-white cursor-pointer'>Next</button>
                        )}
                        <button onClick={handleGoBack} className='text-white cursor-pointer' style={{ fontSize: '12px' }}>
                            Go back
                        </button>
                    </div>
                </div>

                <div className='flex w-full flex-col gap-y-[20px]'>
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="API Key"
                                    placeholder="Your Bandsintown API key"
                                    className="dizee-input w-full"
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                />
                            </div>
                            <CopyLink className='h-[14px] w-[14px] mx-1' />
                        </div>
                    </div>

                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Artist Name"
                                    placeholder="Enter artist name"
                                    className="dizee-input w-full"
                                    value={artistName}
                                    onChange={(e) => setArtistName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {queuedEvents.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full mt-[40px]">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>In Queue</p>
                                </div>
                            </div>
                            {queuedEvents.map((item, index) => (
                                <div key={index} className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
                                    <div className='flex flex-col items-start justify-start h-full py-2'>
                                        <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>{item.venue}</p>
                                        <p className='text-[#696974]' style={{ fontSize: '12px' }}>{item.date}</p>
                                        <p className='text-[#696974]' style={{ fontSize: '12px' }}>{item.location}</p>
                                    </div>
                                    <button onClick={() => handleDeleteFromQueue(index)}>
                                        <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer' />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                    {event?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full mt-[40px]">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added events</p>
                                </div>
                            </div>
                            {event.map((item, index) => (
                                <AddedEvent 
                                    key={index}
                                    item={item}
                                    setLinkForBackend={setLinkForBackend}
                                    setOverlayVisible={setOverlayVisible}
                                    setItemForBackend={setItemForBackend}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {isOverlayVisible && (
                <EventOverlay
                    setOverlayVisible={setOverlayVisible}
                    linkForBackend={linkForBackend}
                    itemForBackend={itemForBackend}
                />
            )}
        </LayoutHeader>
    );
}
