import React from 'react'

export default function Douyin(props) {
    const { color } = props
    return (
        <svg 
            width="120"
            height="50"
            viewBox="0 0 24 10"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: 'scale(2)' }}
            {...props}
        >
            <g clipPath="url(#clip0_5462_14923)">
                <mask id="mask0_5462_14923" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="10">
                    <path d="M24 0H0V9.40253H24V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_5462_14923)">
                    <path d="M11.6623 1.66211H10.9258V2.74364H10.0703V3.47975H10.9258V4.98031L10.0703 5.10487V5.88065L10.9258 5.75604V7.04143C10.9258 7.14338 10.8408 7.2283 10.7388 7.2283H10.1213V7.96442H11.0448C11.3847 7.96442 11.6623 7.68694 11.6623 7.34719V5.64847L12.4101 5.53524V4.75947L11.6623 4.87269V3.48542H12.4101V2.74931H11.6623V1.66211Z" fill={color || "white"}/>
                    <path d="M15.8354 1.66211H15.1046V5.50692L12.6797 5.71644V6.49217L15.1046 6.28836V8.0154H15.8354V6.22039L16.5832 6.15242V5.37669L15.8354 5.44462V1.66211Z" fill={color || "white"}/>
                    <path d="M14.7237 2.51847L12.9844 2.20703V2.98845L14.7237 3.29422V2.51847Z" fill={color || "white"}/>
                    <path d="M14.7237 4.13173L12.9844 3.82031V4.60172L14.7237 4.91312V4.13173Z" fill={color || "white"}/>
                    <path d="M22.5593 3.80921L22.6726 3.20898H21.8964L21.7775 3.80921H19.7492L19.6585 3.20898H18.8767L18.973 3.80921H17.5V4.52833H23.9983V3.80921H22.5593Z" fill={color || "white"}/>
                    <path d="M23.7936 2.17173H21.329L21.2044 1.66211H20.2469L20.3715 2.17173H17.7031V2.88519H23.7936V2.17173Z" fill={color || "white"}/>
                    <path d="M22.7961 5.05469H18.7113C18.3713 5.05469 18.0938 5.33213 18.0938 5.67187V7.35365C18.0938 7.69334 18.3713 7.97083 18.7113 7.97083H22.7961C23.1361 7.97083 23.4137 7.69334 23.4137 7.35365V5.67187C23.4137 5.33213 23.1417 5.05469 22.7961 5.05469ZM18.9719 5.72285H22.5412C22.6431 5.72285 22.7281 5.80776 22.7281 5.90971V6.20981H18.7906V5.90971C18.7849 5.8021 18.8699 5.72285 18.9719 5.72285ZM22.5412 7.30834H18.9719C18.8699 7.30834 18.7849 7.22338 18.7849 7.12147V6.81571H22.7225V7.12147C22.7225 7.22338 22.6431 7.30834 22.5412 7.30834Z" fill={color || "white"}/>
                    <path d="M6.10258 0.144531H4.56557V6.35669C4.56557 7.0969 3.97443 7.70487 3.23878 7.70487C2.50313 7.70487 1.91198 7.0969 1.91198 6.35669C1.91198 5.62973 2.48999 5.03496 3.19938 5.00852V3.44888C1.63611 3.47531 0.375 4.7574 0.375 6.35669C0.375 7.96925 1.66238 9.26453 3.25192 9.26453C4.84142 9.26453 6.12883 7.956 6.12883 6.35669V3.17131C6.70685 3.59427 7.41619 3.8454 8.16499 3.85863V2.29897C7.00901 2.25932 6.10258 1.30766 6.10258 0.144531Z" fill={color || "white"}/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_5462_14923">
                    <rect width="24" height="9.408" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
