import { useState, useMemo } from "react";
import Check from "../../../../svg/Check";
import dizeeLogo from "../../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../../components/OnBoard/Typewriter";
import styled from "styled-components";
import { DizeeInput2 } from "../../../../components/DizeeComponent/DixeeInput2";
import { personalCategories } from "../../../../assets/categories/personalCategories";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const OptionsContainer = styled.div`
  width: 200px;
  margin-bottom: 40px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 12px;
  margin-bottom: 40px;
  height: 180px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const processCategories = () => {
  return personalCategories.map(category => ({
    label: category,
    value: category.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_')
  }));
};

const defaultOptions = [
  { label: "Music", value: "music" },
  { label: "Sports", value: "sports" },
  { label: "Content Creation", value: "content_creation" },
  { label: "Games", value: "games" },
  { label: "Photography", value: "photography" },
  { label: "Movies/Film", value: "movies_film" }
];

const categories = processCategories();

const Interest = ({ setStep, setUser, user }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCategories = useMemo(() => {
    return categories.filter(cat => 
      cat.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const handleInterestSelect = (value) => {
    const updatedInterests = [...(user?.social?.interest || [])];
    const index = updatedInterests.indexOf(value);
    
    if (index === -1 && updatedInterests.length < 8) {
      updatedInterests.push(value);
    } else if (index !== -1) {
      updatedInterests.splice(index, 1);
    }
    
    setUser({ ...user, social: { ...user.social, interest: updatedInterests } });
  };

  return (
    <Container>
      <FormSection>
        <OptionsContainer>
          <div className="mb-[68px]">
            <TypeWriter text={"Select all of your interests"} speed={45} />
          </div>
          
          <div className="mb-4">
            <DizeeInput2
              placeholder="Search categories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="dizee-input w-[200px]"
            />
          </div>

          <Options>
            {(searchTerm ? filteredCategories : defaultOptions)
              .map((type) => (
                <div
                  key={type.value}
                  className="flex justify-between cursor-pointer"
                  onClick={() => handleInterestSelect(type.value)}
                >
                  <div className="flex items-center">
                    {type.label}
                    <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                      {user?.social?.interest?.includes(type.value) && (
                        <Check className="h-full w-full mt-[2px]" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </Options>

          <ButtonContainer>
            <div
              className="mr-[43px] cursor-pointer"
              onClick={() => setStep(22)}
            >
              Next
            </div>
            <div 
              className="cursor-pointer" 
              onClick={() => setStep(2)}
            >
              Go back
            </div>
          </ButtonContainer>
        </OptionsContainer>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          Select up to 8 interests that best describe your personal preferences and activities.
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Interest;
