import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutPersonalHeader from "../../layout/LayoutPersonalHeader";
import { follow_unfollow_auth_thunk, getExploreUserThunk } from "../../store/auth/authThunk";
import { followProUserThunk } from "../../store/user/userThunk";
import { setUserArticle } from "../../store/user/userSlice";
import CrossArrow from "../../svg/CrossArrow";
import BottomBar from "../../components/Personal/BottomBar";
import { removeUploadsFromUrl } from "../../components/DizeeComponent/DixeeInput2";
import { AnimatePresence, motion, useMotionValue, useTransform } from "framer-motion";

import "../../css/slide.css";

import TinderCard from "react-tinder-card";

export default function Explore() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [curUser, setUser] = useState({});
  const userArticle = useSelector((state) => state.user.userArticle); // Currently logged-in user
  let token = localStorage.getItem("dizeeToken");

  // Add image loading state
  const [imageLoaded, setImageLoaded] = useState(false);

  // Modify image dimensions handling for mobile
  const [imageDimensions, setImageDimensions] = useState({
    width: 340,  // Default width for mobile
    height: 480  // Default height for mobile
  });

  // Calculate optimal dimensions while maintaining aspect ratio
  const calculateDimensions = (img) => {
    const maxDimension = 1200;
    let width = img.naturalWidth;
    let height = img.naturalHeight;

    if (width > height && width > maxDimension) {
      height = (height * maxDimension) / width;
      width = maxDimension;
    } else if (height > maxDimension) {
      width = (width * maxDimension) / height;
      height = maxDimension;
    }

    setImageDimensions({ width, height });
  };

  // Modify the fetch users effect to handle image URLs
  useEffect(() => {
    dispatch(getExploreUserThunk({ username: userArticle?._id, size: 12 })).then((res) => {
      if (res?.payload?.status === 200) {
        const fetchedUsers = res.payload?.data?.response.map(user => ({
          ...user,
          isFollowing: user.followers?.includes(userArticle?._id),
          // Fix mobile vs desktop image URL handling
          avatar: user.avatar ? (
            user.avatar.includes('/uploads/') ? 
              `${process.env.REACT_APP_API_URL}${user.avatar}` : 
              user.avatar
          ) : null
        }));
        setUsers(fetchedUsers);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [userArticle?.username]);

  const x = useMotionValue(0);
  const rotate = useTransform(x, [-200, 200], [-30, 30]);
  const opacity = useTransform(x, [-200, 0, 200], [0.5, 1, 0.5]);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSwipe = (direction) => {
    setSwipeDirection(direction);
    // action..
    dispatch(
      followProUserThunk({
        user: userArticle,
        target: users[currentIndex],
        token
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          setTimeout(() => {
            if (currentIndex < users.length) {
              setCurrentIndex(currentIndex + 1);
            } else {
              // setCurrentIndex(0);
            }
            setSwipeDirection(null);
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <LayoutPersonalHeader>
      <div className="w-[300px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: imageLoaded ? 1 : 0, scale: 1 }}
            exit={{
              opacity: 0,
              x: swipeDirection === 'right' ? 200 : -200,
              transition: { duration: 0.2 }
            }}
            className="relative"
          >
            {currentIndex < users.length && (
              <motion.div
                style={{ x, rotate, opacity }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                onDragEnd={(e, { offset }) => {
                  if (Math.abs(offset.x) > 100) {
                    handleSwipe(offset.x > 0 ? 'right' : 'left');
                  }
                }}
              >
                <div className="w-[340px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]">
                  <div className="max-w-[340px] sm:max-w-[390px] h-[480px] flex relative rounded-t-[20px] overflow-hidden">
                    <img
                      src={users[currentIndex]?.avatar}
                      alt={users[currentIndex]?.username}
                      className="w-full h-full object-cover tinder"
                      style={{
                        objectFit: 'cover',
                        aspectRatio: '340/480',
                        width: '100%',
                        height: '100%'
                      }}
                      onLoad={(e) => {
                        const img = e.target;
                        // Force aspect ratio while maintaining image quality
                        const aspectRatio = img.naturalWidth / img.naturalHeight;
                        const containerRatio = 340 / 480;
                        
                        if (aspectRatio > containerRatio) {
                          img.style.width = '100%';
                          img.style.height = 'auto';
                        } else {
                          img.style.width = 'auto';
                          img.style.height = '100%';
                        }
                        setImageLoaded(true);
                      }}
                      onError={(e) => {
                        console.error('Image load error:', e);
                        setImageLoaded(true);
                      }}
                    />
                    <div
                      className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end pl-4"
                      style={{
                        backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                      }}
                    >
                      <div className="flex flex-col justify-center pb-2">
                        <div
                          className="text-xl flex flex-row gap-x-[5px] items-center text-white font-bold tinder-p"
                          style={{ color: "#FBFBFD" }}
                        >
                          {users[currentIndex]?.username}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>

        <BottomBar />
      </div>
    </LayoutPersonalHeader>
  );
}
