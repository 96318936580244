export const personalCategories = [
    "Academic",
    "Accessories",
    "Accountant",
    "Actor",
    "Acupuncturist",
    "Addiction",
    "Adult",
    "Advertising",
    "Advertising/Marketing",
    "Aerospace",
    "African",
    "Agricultural",
    "Agriculture",
    "Airline",
    "Airport",
    "Album",
    "Allergist",
    "Alternative",
    "Amateur",
    "American",
    "Amphitheater",
    "Amusement",
    "Animal",
    "Antique",
    "Apartment",
    "Apostolic",
    "App",
    "Apparel",
    "Appliance",
    "Appliances",
    "Aquarium",
    "Aquatic",
    "Arabian",
    "Arboretum",
    "Arcade",
    "Architect",
    "Architectural",
    "Argentinian",
    "Aromatherapy",
    "Art",
    "Artist",
    "Arts",
    "Asian",
    "Assemblies",
    "Astrologist",
    "Athlete",
    "Atv",
    "Auction",
    "Audio",
    "Audiologist",
    "Auditorium",
    "Australian",
    "Author",
    "Auto",
    "Automated",
    "Automation",
    "Automotive",
    "Automotive,",
    "Aviation",
    "Awning",
    "Baby",
    "Babysitter",
    "Bags",
    "Bags/Luggage",
    "Bail",
    "Bakery",
    "Band",
    "Bank",
    "Bankruptcy",
    "Bar",
    "Barbecue",
    "Barber",
    "Bartending",
    "Beach",
    "Beauty",
    "Beauty,",
    "Bed",
    "Beer",
    "Belgian",
    "Bicycle",
    "Big",
    "Bike",
    "Biotechnology",
    "Blinds",
    "Blogger",
    "Board",
    "Boat",
    "Boat/Ferry",
    "Book",
    "Bookstore",
    "Borough",
    "Botanical",
    "Bottled",
    "Boutique",
    "Bowling",
    "Boxing",
    "Brand",
    "Brazilian",
    "Breakfast",
    "Brewery",
    "Bridal",
    "Bridge",
    "British",
    "Broadcasting",
    "Brokerage",
    "Bubble",
    "Buffet",
    "Building",
    "Burger",
    "Bus",
    "Business",
    "Businesses",
    "Butcher",
    "Cabin",
    "Cabinet",
    "Cable",
    "Cafe",
    "Cafeteria",
    "Camera",
    "Camera/Photo",
    "Campground",
    "Campus",
    "Canadian",
    "Candy",
    "Canoe",
    "Cantonese",
    "Car",
    "Career",
    "Cargo",
    "Caribbean",
    "Carnival",
    "Carpenter",
    "Carpet",
    "Cars",
    "Cash",
    "Casino",
    "Castle",
    "Caterer",
    "Cause",
    "Cave",
    "Cemetery",
    "Champagne",
    "Charity",
    "Charter",
    "Cheese",
    "Chef",
    "Chemical",
    "Chicken",
    "Child",
    "Chimney",
    "Chinese",
    "Chiropractor",
    "Chocolate",
    "Choir",
    "Christian",
    "Church",
    "Circus",
    "City",
    "Cleaning",
    "Clothing",
    "Coach",
    "Cocktail",
    "Coffee",
    "Collectibles",
    "College",
    "Colombian",
    "Comedian",
    "Comedy",
    "Comfort",
    "Comic",
    "Commercial",
    "Community",
    "Company",
    "Competition",
    "Computer",
    "Computers",
    "Concert",
    "Concrete",
    "Construction",
    "Consulate",
    "Consulting",
    "Contemporary",
    "Continental",
    "Contractor",
    "Convenience",
    "Convent",
    "Convention",
    "Cooking",
    "Copywriting",
    "Corporate",
    "Cosmetic",
    "Cosmetics",
    "Cosmetology",
    "Costume",
    "Cottage",
    "Counselor",
    "Country",
    "Credit",
    "Cricket",
    "Criminal",
    "Cruise",
    "Cultural",
    "Cupcake",
    "Currency",
    "Dairy",
    "Damage",
    "Dance",
    "Dancer",
    "Dating",
    "Day",
    "Deck",
    "Defense",
    "Deli",
    "Demolition",
    "Dentist",
    "Department",
    "Dermatologist",
    "Design",
    "Designer",
    "Dessert",
    "Digital",
    "Dim",
    "Diner",
    "Disc",
    "Discount",
    "Distillery",
    "Dive",
    "Diving",
    "Divorce",
    "Dj",
    "Doctor",
    "Dog",
    "Dominican",
    "Donut",
    "Drafting",
    "Drink",
    "Drive-In",
    "Driving",
    "Drug",
    "Dry",
    "Dui",
    "E-Cigarette",
    "Eco",
    "E-Commerce",
    "Editor",
    "Education",
    "Educational",
    "Electrician",
    "Electronics",
    "Elementary",
    "Elevator",
    "Emergency",
    "Employment",
    "Energy",
    "Engineering",
    "Entertainment",
    "Entrepreneur",
    "Environmental",
    "Episode",
    "Equestrian",
    "Escape",
    "Esports",
    "Estate",
    "Ethiopian",
    "European",
    "Evangelical",
    "Event",
    "Exotic",
    "Fabric",
    "Family",
    "Farm",
    "Farmers",
    "Fashion",
    "Fast",
    "Fence",
    "Fertility",
    "Festival",
    "Fictional",
    "Field",
    "Filipino",
    "Film",
    "Finance",
    "Financial",
    "Fire",
    "Fireplace",
    "Fish",
    "Fishing",
    "Fitness",
    "Fjord/Loch",
    "Flea",
    "Flight",
    "Florist",
    "Flyboarding",
    "Fondue",
    "Food",
    "Foodservice",
    "Footwear",
    "Forestry",
    "Franchising",
    "French",
    "Frozen",
    "Fruit",
    "Funeral",
    "Furniture",
    "Gamer",
    "Games/Toys",
    "Gaming",
    "Garage",
    "Garden",
    "Gardener",
    "Gas",
    "Gastropub",
    "Gay",
    "Gelato",
    "General",
    "German",
    "Gift",
    "Glass",
    "Gluten-Free",
    "Go-Kart",
    "Golf",
    "Government",
    "Granite",
    "Graphic",
    "Greek",
    "Grocery",
    "Gun",
    "Gutter",
    "Gym/Physical",
    "Hair",
    "Halal",
    "Hardware",
    "Hat",
    "Haunted",
    "Hawaiian",
    "Health",
    "Health/Beauty",
    "Healthcare",
    "Heating,",
    "High",
    "Hiking",
    "Hindu",
    "Historical",
    "History",
    "Home",
    "Homebrew",
    "Hong",
    "Hookah",
    "Horse",
    "Horseback",
    "Hospital",
    "Hospitality",
    "Hostel",
    "Hot",
    "Hotel",
    "House",
    "Household",
    "Housing",
    "Hungarian",
    "Ice",
    "Image",
    "Immigration",
    "Independent",
    "Indian",
    "Indo",
    "Indonesian",
    "Industrial",
    "Information",
    "In-Home",
    "Inn",
    "Insurance",
    "Intellectual",
    "Interest",
    "Interior",
    "Internet",
    "Investing",
    "Investment",
    "Irish",
    "Island",
    "Italian",
    "Janitorial",
    "Japanese",
    "Jazz",
    "Jet",
    "Jewelry",
    "Jewelry/Watches",
    "Journalist",
    "Just",
    "Karaoke",
    "Kennel",
    "Kids",
    "Kitchen",
    "Kitchen/Cooking",
    "Kiteboarding",
    "Korean",
    "Kosher",
    "Labor",
    "Landmark",
    "Landscape",
    "Language",
    "Laser",
    "Lasik/Laser",
    "Latin",
    "Laundromat",
    "Law",
    "Lawyer",
    "Lebanese",
    "Legal",
    "Library",
    "Lifestyle",
    "Lighthouse",
    "Lighting",
    "Ligurian",
    "Limo",
    "Lingerie",
    "Literary",
    "Live",
    "Livery",
    "Livestock",
    "Loan",
    "Lobbyist",
    "Local",
    "Locality",
    "Locksmith",
    "Lodge",
    "Lottery",
    "Lounge",
    "Luggage",
    "Magazine",
    "Magician",
    "Makeup",
    "Malaysian",
    "Management",
    "Marina",
    "Marine",
    "Market",
    "Marketing",
    "Marriage",
    "Martial",
    "Masonry",
    "Massage",
    "Maternity",
    "Mattress",
    "Meat",
    "Media",
    "Media/News",
    "Medical",
    "Meditation",
    "Mediterranean",
    "Meeting",
    "Men's",
    "Mental",
    "Merchandising",
    "Metal",
    "Methodist",
    "Mexican",
    "Middle",
    "Miniature",
    "Mining",
    "Mission",
    "Mobile",
    "Modeling",
    "Modern",
    "Monarch",
    "Monument",
    "Moroccan",
    "Mortgage",
    "Mosque",
    "Motel",
    "Motivational",
    "Motor",
    "Motorcycle",
    "Motorsports",
    "Mountain",
    "Movie",
    "Movie/Television",
    "Moving",
    "Museum",
    "Music",
    "Musical",
    "Musician",
    "Musician/Band",
    "Nail",
    "Nanny",
    "National",
    "Nature",
    "Naturopath",
    "Neapolitan",
    "Neighborhood",
    "Neurologist",
    "New",
    "News",
    "Newspaper",
    "Newsstand",
    "Night",
    "Nondenominational",
    "Non-Governmental",
    "Nonprofit",
    "Notary",
    "Nurseries",
    "Nursing",
    "Nutritionist",
    "Observatory",
    "Obstetrician-Gynecologist",
    "Occupational",
    "Ocean",
    "Office",
    "Opera",
    "Ophthalmologist",
    "Optician",
    "Optometrist",
    "Oral",
    "Orchestra",
    "Organic",
    "Organization",
    "Orthodontist",
    "Osteopathic",
    "Other",
    "Outdoor",
    "Outlet",
    "Paddleboarding",
    "Paintball",
    "Pakistani",
    "Palace",
    "Park",
    "Party",
    "Passport",
    "Patio/Garden",
    "Paving",
    "Pawn",
    "Pediatrician",
    "Pentecostal",
    "Performance",
    "Performing",
    "Periodontist",
    "Personal",
    "Peruvian",
    "Pest",
    "Pet",
    "Petroleum",
    "Petting",
    "Pharmaceutical",
    "Pharmaceuticals",
    "Pharmacy",
    "Pho",
    "Phone/Tablet",
    "Photographer",
    "Photography",
    "Physical",
    "Pilates",
    "Pizza",
    "Planetarium",
    "Plastic",
    "Playground",
    "Plumbing",
    "Podcast",
    "Podiatrist",
    "Police",
    "Polish",
    "Political",
    "Politician",
    "Pop-Up",
    "Port",
    "Portable",
    "Portuguese",
    "Post",
    "Pregnancy",
    "Preschool",
    "Printing",
    "Private",
    "Proctologist",
    "Producer",
    "Product/Service",
    "Professional",
    "Promenade",
    "Property",
    "Psychologist",
    "Psychotherapist",
    "Pub",
    "Public",
    "Publisher",
    "Race",
    "Radio",
    "Rafting/Kayaking",
    "Ramen",
    "Real",
    "Record",
    "Recreation",
    "Recruiter",
    "Recycling",
    "Reference",
    "Reflexologist",
    "Refrigeration",
    "Region",
    "Regional",
    "Religious",
    "Rental",
    "Reproductive",
    "Residence",
    "Restaurant",
    "Retail",
    "Retirement",
    "Rideshare",
    "Robotics",
    "Rock",
    "Rodeo",
    "Roller",
    "Roofing",
    "Rose",
    "Rugby",
    "Rv",
    "Safety",
    "Sake",
    "Salsa",
    "Sandwich",
    "School",
    "Science",
    "Science,",
    "Scientist",
    "Scooter",
    "Screen",
    "Scuba",
    "Sculpture",
    "Seafood",
    "Seasonal",
    "Secretarial",
    "Security",
    "Self-Storage",
    "Service",
    "Sewer",
    "Sewing",
    "Sex",
    "Shaved",
    "Shipping",
    "Shopping",
    "Show",
    "Sightseeing",
    "Signs",
    "Sikh",
    "Skate",
    "Skateboard",
    "Ski",
    "Skin",
    "Skydiving",
    "Smoothie",
    "Snorkeling",
    "Soba",
    "Soccer",
    "Social",
    "Society",
    "Software",
    "Solar",
    "Song",
    "Sorority",
    "Soul",
    "Soup",
    "South",
    "Southern",
    "Souvenir",
    "Spa",
    "Spanish",
    "Specialty",
    "Speech",
    "Sport",
    "Sporting",
    "Sports",
    "Sportswear",
    "Stadium,",
    "State",
    "Stately",
    "Statue",
    "Steakhouse",
    "Storage",
    "Structural",
    "Subway",
    "Sunglasses",
    "Supermarket",
    "Surf",
    "Surfing",
    "Surveyor",
    "Sushi",
    "Swimming",
    "Swimwear",
    "Symphony",
    "Talent",
    "Tanning",
    "Tapas",
    "Tattoo",
    "Tax",
    "Taxi",
    "Taxidermist",
    "Tea",
    "Teacher",
    "Teens",
    "Teeth",
    "Telecommunication",
    "Telemarketing",
    "Tennis",
    "Test",
    "Tex-Mex",
    "Textile",
    "Thai",
    "Theatrical",
    "Theme",
    "Therapist",
    "Thrift",
    "Ticket",
    "Tiling",
    "Tire",
    "Tobacco",
    "Tools/Equipment",
    "Tour",
    "Tourist",
    "Towing",
    "Toy",
    "Trade",
    "Traffic",
    "Train",
    "Transit",
    "Translator",
    "Transportation",
    "Travel",
    "Tree",
    "Trophies",
    "Tuscan",
    "Tutor/Teacher",
    "Tv",
    "Tv/Movie",
    "Uniform",
    "Urban",
    "Urologist",
    "Vacation",
    "Vegetarian/Vegan",
    "Veterinarian",
    "Video",
    "Vietnamese",
    "Vintage",
    "Visual",
    "Vitamin",
    "Vitamins/Supplements",
    "Waste",
    "Water",
    "Waxing",
    "Web",
    "Website",
    "Wedding",
    "Weight",
    "Well",
    "Whisky",
    "Wholesale",
    "Wig",
    "Wildlife",
    "Window",
    "Wine",
    "Wine,",
    "Wine/Spirits",
    "Winery/Vineyard",
    "Women's",
    "Work",
    "Workplace",
    "Writer",
    "Writing",
    "Yoga",
    "Youth",
    "Zoo"
];