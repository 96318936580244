import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../components/DizeeComponent/DixeeInput2';
import Instagram from '../../../../svg/Social/Instagram';
import Facebook from '../../../../svg/Social/Facebook';
import TikTok from '../../../../svg/Social/Tiktok';
import Twitter from '../../../../svg/Social/TwitterX';
import Youtube from '../../../../svg/Social/Youtube';
import Snapchat from '../../../../svg/Social/Snapchat';
import Threads from '../../../../svg/Social/Thread';
import Douyin from '../../../../svg/Social/Douyin';
import WeChat from '../../../../svg/Social/Wechat';
import CopyLink from '../../../../svg/CopyLink';
import ImageSelectionCard from '../../../../components/ImageSelectionCard';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../../utils/upload';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../store/alert/alertSlice';
import { getSocialFeedThunk } from '../../../../store/addsection/addsectionThunk';
import AddedSocialFeed from '../../../../components/AddSection/SocialFeed/AddedSocialFeed';
import SocialFeedOverlay from '../../../../components/AddSection/SocialFeed/SocialFeedOverlay';
import QueuedSocialFeedCard from '../../../../components/AddSection/SocialFeed/QueuedSocialFeedCard';


export default function AddSocialFed() {
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const [link, setLink] = useState('');
    const [title, setTitle] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [previousUrl, setPreviousUrl] = useState('');

    const socialfeed = useSelector((state) => state.addsection.socialfeed);
    const dispatch = useDispatch();

    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [linkForBackend, setLinkForBackend] = useState('')
    const [itemForBackend, setItemForBackend] = useState('');

    const [entries, setEntries] = useState(() => {
        const saved = localStorage.getItem('socialFeedQueueEntries');
        return saved ? JSON.parse(saved) : [];
    });

    const isFormComplete = useMemo(() => {
        return Boolean(
            link?.trim() && 
            title?.trim() && 
            (selectedImage || imagePreview)
        );
    }, [link, title, selectedImage, imagePreview]);

    useEffect(() => {
        dispatch(clearAlerts())
        dispatch(getSocialFeedThunk({ token }))
    }, [dispatch, token])

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const resetImage = () => {
        setSelectedImage(null);
        setImagePreview(null);
    };

    const filterLink = (link) => {
        if (socialfeed?.length > 0) {
            for (let m of socialfeed) {
                if (m.links[0].url === link) {
                    return true;
                }
            }
        }
        return false;
    };

    const getPlatformFromUrl = (url) => {
        if (!url) return { name: 'Link', icon: <CopyLink className='h-[14px] w-[14px] mx-0.6' /> };
        
        const urlLower = url.toLowerCase();
        
        if (urlLower.includes('instagram.com')) return { name: 'Instagram', icon: <Instagram className='h-[14px] w-[14px] mx-0.6' /> };
        if (urlLower.includes('facebook.com')) return { name: 'Facebook', icon: <Facebook className='h-[14px] w-[14px] mx-0.6' /> };
        if (urlLower.includes('tiktok.com')) return { name: 'TikTok', icon: <TikTok className='h-[14px] w-[14px] mx-0.6' /> };
        if (urlLower.includes('twitter.com') || urlLower.includes('x.com')) return { name: 'Twitter', icon: <Twitter className='h-[14px] w-[14px] mx-2' /> };
        if (urlLower.includes('youtube.com')) return { name: 'YouTube', icon: <Youtube className='h-[14px] w-[14px] mx-0.6' /> };
        if (urlLower.includes('snapchat.com')) return { name: 'Snapchat', icon: <Snapchat className='h-[14px] w-[14px] mx-0.6' /> };
        if (urlLower.includes('threads.net')) return { name: 'Threads', icon: <Threads className='h-[14px] w-[14px] mx-0.6' /> };
        if (urlLower.includes('douyin.com')) return { name: 'Douyin', icon: <Douyin className='h-[16px] w-[16px] mx-0.6' /> };
        if (urlLower.includes('wechat.com')) return { name: 'WeChat', icon: <WeChat className='h-[16px] w-[16px] mx-0.6' /> };
        
        return { name: 'Link', icon: <CopyLink className='h-[14px] w-[14px] mx-0.6' /> };
    };

    const handleAdd = async () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all fields'))
            return
        }

        if (filterLink(link)) {
            dispatch(setErrorAlert('Link already exists'))
            return
        }

        dispatch(setLoader(true));
        try {
            const imageUrl = await uploadImage(selectedImage);
            
            const newEntry = {
                avatar: imageUrl,
                platform: getPlatformFromUrl(link).name,
                title: title.trim(),
                links: [{ url: link }]
            };

            const updatedEntries = [...entries, newEntry];
            setEntries(updatedEntries);
            localStorage.setItem('socialFeedQueueEntries', JSON.stringify(updatedEntries));

            // Reset form
            setLink('');
            setTitle('');
            setSelectedImage(null);
            setImagePreview(null);
            
            dispatch(setLoader(false));
        } catch (error) {
            dispatch(setErrorAlert('Error uploading image'));
            dispatch(setLoader(false));
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
        localStorage.setItem('socialFeedQueueEntries', JSON.stringify(newEntries));
    };

    const handleAddSocialFeed = async () => {
        if (entries.length === 0 && !isFormComplete) {
            dispatch(setErrorAlert('Please add at least one entry or complete the current form'));
            return;
        }

        let finalEntries = [...entries];

        if (isFormComplete) {
            dispatch(setLoader(true));
            try {
                const imageUrl = await uploadImage(selectedImage);
                if (!imageUrl) {
                    dispatch(setErrorAlert('Error uploading image'));
                    dispatch(setLoader(false));
                    return;
                }

                const currentEntry = {
                    avatar: imageUrl,
                    platform: getPlatformFromUrl(link).name,
                    title: title.trim(),
                    links: [{
                        source: 'custom',
                        url: link.startsWith('http') ? link : `https://${link}`
                    }]
                };

                finalEntries.push(currentEntry);
                localStorage.setItem('socialFeedQueueEntries', JSON.stringify(finalEntries));

                // Reset form fields
                setLink('');
                setTitle('');
                setSelectedImage(null);
                setImagePreview(null);

                dispatch(setLoader(false));

                navigate('/add-section/add-social-feed/publish', { 
                    state: { 
                        entries: finalEntries,
                        returnPath: '/add-section/add-social-feed'
                    } 
                });
            } catch (error) {
                dispatch(setErrorAlert('Error preparing entry'));
                dispatch(setLoader(false));
            }
        } else {
            // If only queue entries exist
            localStorage.setItem('socialFeedQueueEntries', JSON.stringify(finalEntries));
            navigate('/add-section/add-social-feed/publish', { 
                state: { 
                    entries: finalEntries,
                    returnPath: '/add-section/add-social-feed'
                } 
            });
        }
    };

    const clearForm = () => {
        setLink('');
        setTitle('');
        setSelectedImage(null);
        setImagePreview(null);
    };

    const showQueue = isFormComplete;
    const showNext = entries.length > 0 || isFormComplete;
    const showClear = !isFormComplete && (link || selectedImage || imagePreview);

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Social feed</span>
                    </div>
                    <div className='flex gap-[30px]'>
                        {showQueue && (
                            <button 
                                onClick={handleAdd}
                                className='text-white cursor-pointer'
                            >
                                Queue
                            </button>
                        )}
                        {showNext && (
                            <button 
                                onClick={handleAddSocialFeed} 
                                className='text-white cursor-pointer'
                            >
                                Next
                            </button>
                        )}
                        {showClear && (
                            <button 
                                onClick={clearForm} 
                                className='text-white cursor-pointer'
                            >
                                Clear
                            </button>
                        )}
                        <p onClick={() => navigate('/add-section')} 
                           className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full gap-y-[50px]" >
                    <div className='w-full'>
                        <ImageSelectionCard
                            txt="Add cover image"
                            dotimgclss={false}
                            onImageChange={handleImageChange}
                            imagePreview={imagePreview}
                            resetImage={resetImage}
                        />
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Title"
                                    placeholder="Add a title"
                                    className="dizee-input w-full"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Enter a link"
                                    className="dizee-input w-full"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                            <div className="ml-4">
                                {getPlatformFromUrl(link).icon}
                            </div>
                        </div>
   
                    </div>

                    {entries?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p className='text-[12px]'>In Queue</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-[10px] w-full'>
                                {entries.map((item, index) => (
                                    <QueuedSocialFeedCard
                                        key={index}
                                        socialFeed={item}
                                        onDelete={() => handleDeleteFromQueue(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {socialfeed?.length > 0 && <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <p>Added posts</p>
                            </div>
                        </div>
                        {
                            socialfeed?.length > 0 && socialfeed?.map((item, index) => <AddedSocialFeed key={index} item={item} setOverlayVisible={setOverlayVisible} setItemForBackend={setItemForBackend} setLinkForBackend={setLinkForBackend} setPreviousUrl={setPreviousUrl} />)
                        }
                    </div>}
                </div>
            </div>
            {isOverlayVisible && <SocialFeedOverlay setOverlayVisible={setOverlayVisible} previousUrl={previousUrl} itemForBackend={itemForBackend} linkForBackend={linkForBackend} setLink={setLink} setImagePreview={setImagePreview} setSelectedImage={setSelectedImage} setPreviousUrl={setPreviousUrl} />}

        </LayoutHeader>
    );
}
