import { useEffect, useState } from 'react';
import styled from 'styled-components';

const TypeWriterText = styled.div`
  font-size: 12px;
  color: white;
  margin-bottom: 12px;
  min-height: 1.5em;
  line-height: 1.8;
`;

const TypeWriter = ({ text, delay = 50 }) => {
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        const typeMessage = async () => {
            setDisplayText(''); // Reset text when message changes
            for (let i = 0; i <= text.length; i++) {
                setDisplayText(text.slice(0, i));
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        };

        typeMessage();
    }, [text, delay]);

    return <TypeWriterText>{displayText}</TypeWriterText>;
};

export default TypeWriter; 