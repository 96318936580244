import React, { useEffect, useState } from 'react'
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput'
import LayoutHeader from '../../../../../layout/LayoutHeader'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert, setLoader, setSuccessAlert } from '../../../../../store/alert/alertSlice';
import { postDataAPI } from '../../../../../utils/fetchData';
import { loginWithOtp, verifyPhone } from '../../../../../store/auth/authThunk';

export default function VerifyNewPhone() {
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken')
    const dispatch = useDispatch()
    const [code, setCode] = useState("")
    const { phone, email } = useSelector(state => state.user.userArticle)

    useEffect(() => {
        if (code.length === 6) {
            dispatch(clearAlerts())
            dispatch(loginWithOtp({ email, code, phone })).then((res) => {
                if (res.payload.status === 200) {
                    dispatch(setSuccessAlert('Phone number has changed successfully'));
                    navigate("/more/setting/advanced");
                }
            });
        }
    }, [code, dispatch, phone, navigate]);

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Change phone number</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex flex-col gap-y-6">
                            <div className="text-white">
                                Code has been sent to (****) *** {phone?.slice(-4)}
                            </div>
                            <div className="flex flex-col gap-y-[40px]">
                                <DizeeInput
                                    placeholder={"Enter the code"}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <div className="flex justify-start w-full gap-[43px]">
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={() => {
                                            if (code.length === 6) {
                                                dispatch(clearAlerts())
                                                dispatch(loginWithOtp({ email, code, phone }))
                                            }
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={() => dispatch(verifyPhone(phone))}
                                    >
                                        Resend code
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}
