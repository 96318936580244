import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default stylesheet
import "../../../../css/datePickerStyle.css"
import { useLocation, useNavigate } from 'react-router-dom';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { useDispatch, useSelector } from 'react-redux';
import { updateEventDate } from '../../../../store/eventData/eventdataSlice';
import { updateEditEventDate } from '../../../../store/eventEditData/eventEditDataSlice';
import { clearEditEventDate } from '../../../../store/eventEditData/eventEditDataSlice';
import { setErrorAlert } from '../../../../store/alert/alertSlice';
import { updateEventThunk } from '../../../../store/addsection/addsectionThunk';

export default function DatePickerPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { route, isEditing, eventId, currentDate, previousState, fromOverlay } = location.state;
    const eventDate = useSelector((state) => 
        fromOverlay ? state.eventEditData.date : state.eventdata.date
    );

    useEffect(() => {
        if (currentDate) {
            dispatch(fromOverlay ? 
                updateEditEventDate(new Date(currentDate)) : 
                updateEventDate(new Date(currentDate))
            );
        }
    }, []);

    const navigation = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!eventDate) {
            dispatch(setErrorAlert('Please select a date'));
            return;
        }

        const formattedDate = new Date(eventDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });

        if (fromOverlay && eventId) {
            try {
                const result = await dispatch(updateEventThunk({ 
                    linkForBackend: eventId, 
                    saveType: "edit",
                    updates: {
                        type: "event",
                        venue: previousState?.editForm?.venue || '',
                        location: previousState?.editForm?.location || '',
                        date: formattedDate,
                        link: previousState?.editForm?.link || '',
                        links: [{ url: previousState?.editForm?.link || '' }]
                    },
                    token: localStorage.getItem('dizeeToken')
                })).unwrap();

                if (result.status === 200) {
                    dispatch(clearEditEventDate());
                    navigation('/add-section/custom-event-link', { 
                        state: { 
                            isEditing: true,
                            eventId,
                            dateUpdated: true,
                            showEditor: false,
                            overlayVisible: true,
                            editForm: {
                                ...previousState?.editForm,
                                date: formattedDate
                            }
                        }
                    });
                }
            } catch (error) {
                console.error('Error saving date:', error);
                dispatch(setErrorAlert('Error saving changes'));
            }
        } else {
            navigation(route, { 
                state: { 
                    dateUpdated: true,
                    date: formattedDate
                }
            });
        }
    };

    const onBack = (e) => {
        e.preventDefault();
        if (fromOverlay && previousState?.returnToEdit) {
            navigation('/add-section/custom-event-link', { 
                state: { 
                    isEditing: true,
                    eventId,
                    showEditor: true,
                    overlayVisible: true,
                    editForm: previousState.editForm
                }
            });
        } else {
            navigation(-1);
        }
    };

    const handleDateChange = (date) => {
        dispatch(fromOverlay ? 
            updateEditEventDate(date) : 
            updateEventDate(date)
        );
    };

    // Add z-index styling for date picker when opened from edit
    const datePickerStyle = previousState?.keepOverlay ? 
        "fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-[70]" : 
        "h-[75vh] flex items-center justify-center";

    return (
        <LayoutHeader>
            <div className={datePickerStyle}>
                <div className="flex items-center justify-center gap-4 sm:gap-16">
                    <div className="datepicker-container flex flex-col justify-center items-start h-[80vh]">
                        <DatePicker
                            selected={eventDate}
                            onChange={handleDateChange}
                            inline
                        />
                        <div>
                            <button className="text-white text-[12px] px-4 py-2 rounded-md mt-4" onClick={handleSubmit}>Submit</button>
                            <button className="text-white text-[12px] px-4 py-2 rounded-md mt-4" onClick={onBack}>Go back</button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}

