import React, { useState } from 'react'
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import More from '../../../svg/More';
import PreviewOverlay from '../../PreviewOverlay';

export default function AddedPlaylistCard({ item, setAvatarForBackend, setOverlayVisible }) {
    const [previewOverlayOpen, setPreviewOverlayOpen] = useState(false);

    return (
        <div className='w-[350px] sm:w-[390px] p-5 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                <img src={removeUploadsFromUrl(item?.avatar)} alt='alt' className='object-cover h-[50px] w-[50px] rounded-[3px]' />
                <div className='flex flex-col items-start justify-start h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>{maxLength(item?.title, 28)}</p>
                </div>
            </div>
            <div className='flex'>
                <div 
                    className='text-white cursor-pointer'
                    onClick={() => setPreviewOverlayOpen(true)}
                >
                    {item?.publish?.value === 'publish' ? 'preview' : item?.publish?.value}
                </div>
                <More className='h-[20px] w-[20px] cursor-pointer ml-7' onClick={() => {
                    setOverlayVisible(true)
                    setAvatarForBackend(item?.avatar)
                }} />
            </div>

            {previewOverlayOpen && (
                <PreviewOverlay 
                    isOpen={previewOverlayOpen}
                    onClose={() => setPreviewOverlayOpen(false)}
                    selectedSection="playlist"
                    item={item}
                    type="playlist"
                />
            )}
        </div>
    )
}
