import React, { useState } from 'react'
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import More from '../../../svg/More';
import Instagram from '../../../svg/Social/Instagram';
import Youtube from '../../../svg/Social/Youtube';
import Tiktok from '../../../svg/Social/Tiktok';
import Facebook from '../../../svg/Social/Facebook';
import Twitter from '../../../svg/Social/TwitterX';
import Snapchat from '../../../svg/Social/Snapchat';
import Threads from '../../../svg/Social/Thread';
import Douyin from '../../../svg/Social/Douyin';
import WeChat from '../../../svg/Social/Wechat';
import CopyLink from '../../../svg/CopyLink';
import PreviewOverlay from '../../PreviewOverlay';

const platformMapping = {
    Instagram: <Instagram className='h-[14px] w-[14px]' />,
    Facebook: <Facebook className='h-[14px] w-[14px]' />,
    TikTok: <Tiktok className='h-[14px] w-[14px]' />,
    Twitter: <Twitter className='h-[14px] w-[14px]' />,
    YouTube: <Youtube className='h-[14px] w-[14px]' />,
    Snapchat: <Snapchat className='h-[14px] w-[14px]' />,
    Threads: <Threads className='h-[14px] w-[14px]' />,
    Douyin: <Douyin className='h-[16px] w-[16px] ml-[6px]' />,
    WeChat: <WeChat className='h-[16px] w-[16px]' />,
    igreel: <Instagram className='h-[14px] w-[14px]' />,
    iggrid: <Instagram className='h-[14px] w-[14px]' />,
    ytshorts: <Youtube className='h-[14px] w-[14px]' />,
    tt: <Tiktok className='h-[14px] w-[14px]' />,
    Link: <CopyLink className='h-[14px] w-[14px]' />
};

export default function AddedSocialFeed({ item, setOverlayVisible, setItemForBackend, setLinkForBackend, setPreviousUrl }) {
    const [previewOverlayOpen, setPreviewOverlayOpen] = useState(false);

    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                <img 
                    src={removeUploadsFromUrl(item?.avatar)} 
                    alt='Social Feed Cover' 
                    className='object-cover h-[50px] w-[50px] rounded-[3px]' 
                />
                <div className='flex flex-col items-start justify-start h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                        {maxLength(item?.title || '', 30)}
                    </p>
                    <div className='text-gray-400'>
                        {platformMapping[item.platform]}
                    </div>
                </div>
            </div>
            <div className='flex items-center'>
                <div 
                    className='text-white cursor-pointer mr-7'
                    onClick={() => setPreviewOverlayOpen(true)}
                >
                    {item?.publish?.value === 'publish' ? 'preview' : item?.publish?.value}
                </div>
                <More className='h-[20px] w-[20px] cursor-pointer' onClick={() => {
                    setItemForBackend(item)
                    setOverlayVisible(true)
                    setLinkForBackend(item?.links[0]?.url)
                    setPreviousUrl(item?.links[0]?.url)
                }} />
            </div>

            {previewOverlayOpen && (
                <PreviewOverlay 
                    isOpen={previewOverlayOpen}
                    onClose={() => setPreviewOverlayOpen(false)}
                    selectedSection="socialfeed"
                    item={item}
                    type="socialfeed"
                />
            )}
        </div>
    )
}
