import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import DizeeInput from "../../components/DizeeComponent/DizeeInput";
import { verifyEmailOTP, verifyPhone } from "../../store/auth/authThunk";
import { clearAlerts, setErrorAlert } from "../../store/alert/alertSlice";
import styled from 'styled-components';
import dizeeLogo from "../../assets/images/dizee logo.svg";
import TypeWriter from "../../components/OnBoard/Typewriter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 43px;
`;

const ActionButton = styled.button`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  background: transparent;
  border: none;
  padding: 0;
  font-family: inherit;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const EmailOtp = ({ setStep, setUser, user }) => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { email, phone } = useSelector((state) => state.user);
    const [code, setCode] = useState("");

    const handleConfirm = (e) => {
        e.preventDefault();
        if (code.length === 6) {
            dispatch(verifyEmailOTP({ code, email })).then((res) => {
                if (res.payload.status === 200) {
                    setStep(2);
                }
            });
        } else {
            dispatch(setErrorAlert("Text verification code is not correct"));
        }
    };

    const handleSendToPhone = () => {
        dispatch(clearAlerts());
        navigation('/login-otp', { state: { email } });
    };

    return (
        <Container>
            <FormSection>
                <TypeWriter 
                    text="Verify with your email address" 
                    speed={45} 
                />
                <form onSubmit={handleConfirm}>
                    <div className="mb-[68px]">
                        <DizeeInput
                            placeholder={"Enter the code"}
                            onChange={(e) => setCode(e.target.value)}
                            value={code}
                        />
                    </div>
                    <ButtonContainer>
                        <ActionButton type="submit">
                            Confirm
                        </ActionButton>
                        <ActionButton 
                            type="button" 
                            onClick={handleSendToPhone}
                        >
                            Send to phone
                        </ActionButton>
                    </ButtonContainer>
                </form>
            </FormSection>
            
            <Footer>
                <LogoImage src={dizeeLogo} alt="DIZEE" />
                <FooterText>
                    Enter the verification code sent to your email address.
                </FooterText>
            </Footer>
        </Container>
    );
};

export default EmailOtp;
