import React, { useEffect, useState } from 'react'
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput'
import LayoutHeader from '../../../../../layout/LayoutHeader'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { clearAlerts, setErrorAlert, setLoader, setSuccessAlert } from '../../../../../store/alert/alertSlice';
import { postDataAPI } from '../../../../../utils/fetchData';
import { loginWithOtp, verifyEmailOTP, sendOtpToEmailThunk } from '../../../../../store/auth/authThunk';
import { setUserArticle } from '../../../../../store/user/userSlice';
import { update_user_article_thunk } from '../../../../../store/user/userThunk';

export default function VerifyNewEmail() {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken')
    const dispatch = useDispatch()
    const userArticle = useSelector((state) => state.user.userArticle);
    const [code, setCode] = useState("")
    const [email, setEmail] = useState("");

    useEffect(() => {
        let _ = localStorage.getItem("dizee-temp-email");
        setEmail(_);
    }, [])

    const maskEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) return null;
        const [localPart, domain] = email.split("@");
        const maskedLocal = localPart.slice(0, 1) + "*****";
        return `${maskedLocal}@${domain}`;
    }

    useEffect(() => {
        if (code.length === 6) {
            dispatch(clearAlerts())
            dispatch(verifyEmailOTP({ email, code })).then((res) => {
                if (res.payload.status === 200) {
                    dispatch(setSuccessAlert("Email has changed successfully"));
                    dispatch(setUserArticle({
                        ...userArticle,
                        email
                    }));
                    let body = {
                        ...userArticle,
                        email
                    };
                    dispatch(update_user_article_thunk({ token, body }))
                    navigate("/more/setting/");
                }
            });
        }
    }, [code]);

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Change email address</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex flex-col gap-y-6">
                            <div className="text-white">
                                Code has been sent to {maskEmail(email)}
                            </div>
                            <div className="flex flex-col gap-y-[40px]">
                                <DizeeInput
                                    placeholder={"Enter the code"}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <div className="flex justify-start w-full gap-[43px]">
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={() => {
                                            if (code.length === 6) {
                                                dispatch(clearAlerts())
                                                dispatch(verifyEmailOTP({ email, code }))
                                            }
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={() => dispatch(sendOtpToEmailThunk(email))}
                                    >
                                        Resend code
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}
