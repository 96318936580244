import React, { useEffect, useState } from 'react'
import LayoutHeader from '../../../../../layout/LayoutHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { sendOtpToEmailThunk } from '../../../../../store/auth/authThunk';

export default function NewEmail() {
    const token = localStorage.getItem('dizeeToken')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userArticle = useSelector((state) => state.user.userArticle);
    const [email, setEmail] = useState("")

    const sendVerifyCode = async () => {
        dispatch(sendOtpToEmailThunk(email));
        localStorage.setItem(`dizee-temp-email`, email);
        navigate('/more/setting/verify-new-email');
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Change email address</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex flex-col gap-y-6">
                            <div className="text-white">
                                Enter your new email address
                            </div>
                            <div className="flex flex-col gap-y-[40px]">
                                <DizeeInput
                                    placeholder={"Email address"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <div className="flex justify-start w-full">
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={sendVerifyCode}
                                    >
                                        Send verification code
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}
