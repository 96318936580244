import React, { useState } from 'react';
import '../../../../css/ColorPicker.css';
import styled from "styled-components";
import dizeeLogo from "../../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../../components/OnBoard/Typewriter";
import colorValidation from "../../../../assets/config/colorValidation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const ColorPickerContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const HexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HexLabel = styled.span`
  text-transform: uppercase;
  font-family: inherit;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
`;

const HexInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  font-size: 12px;
  width: 70px;
  text-transform: uppercase;
  font-family: inherit;
  line-height: 18px;
  height: 18px;
  
  &:focus {
    outline: none;
  }
`;

const ColorDisplay = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid white;
  align-self: center;
`;

// Utility function to convert HSL to Hex
export const hslToHex = (h, s, l) => {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0'); // Convert to hex
    };
    return `#${f(0)}${f(8)}${f(4)}`;
};

// Add hex validation function
const isValidHexChar = (char) => {
    return /^[0-9A-Fa-f]$/.test(char);
};

const MainColorPicker = ({ setStep, setUser, user }) => {
    const [hue, setHue] = useState(0);
    const [brightness, setBrightness] = useState(50);
    const [inputValue, setInputValue] = useState("");
    const [validationMessage, setValidationMessage] = useState("");
    
    // Function to handle hue changes
    const handleHueChange = (e) => {
        setHue(e.target.value);
        setInputValue(''); // Clear input value to show live hex updates
    };

    // Function to handle brightness/saturation changes
    const handleBrightnessChange = (e) => {
        setBrightness(e.target.value);
        setInputValue(''); // Clear input value to show live hex updates
    };

    const handleHexInput = (e) => {
        let value = e.target.value.toUpperCase();
        
        // Remove non-hex characters
        value = value.replace(/[^0-9A-F#]/g, '');
        
        // Ensure # is at the start
        if (!value.startsWith('#') && value !== '') {
            value = '#' + value;
        }
        
        setInputValue(value);
        
        // Only update color if we have a valid hex code
        if (/^#[0-9A-F]{6}$/.test(value)) {
            const rgb = hexToRgb(value);
            const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
            
            // Update sliders based on HSL values
            setHue(hsl.h);
            setBrightness(hsl.l);
            
            // Update the background of brightness slider
            const brightnessSlider = document.querySelector('.brightness-slider');
            if (brightnessSlider) {
                brightnessSlider.style.background = `linear-gradient(to left, white, hsl(${hsl.h},100%, 50%))`;
            }
        }
    };

    // Calculating the current color using HSL
    const selectedColor = `hsl(${hue}, 100%, ${brightness}%)`;
    const hexColor = hslToHex(hue, 100, brightness);

    const handleConfirm = () => {
        const mainColor = hexColor.toUpperCase();
        
        // No validation needed for main color
        setUser({
            ...user,
            background: hexColor.replace("#", "")
        });
        
        if (hexColor.replace("#", "")) {
            setStep(21);
        }
    };

    return (
        <Container>
            <FormSection>
                <div className="w-[300px]">
                    <div className="mb-[68px]">
                        <TypeWriter text="Select main color" speed={45} />
                    </div>

                    <ColorPickerContainer>
                        <div className='flex flex-col justify-center items-start gap-y-[30px]'>
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChange}
                                className="hue-slider"
                            />
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={brightness}
                                onChange={handleBrightnessChange}
                                className="brightness-slider"
                                style={{
                                    background: `linear-gradient(to left, white, hsl(${hue},100%, 50%))`
                                }}
                            />
                            <HexContainer>
                                <HexLabel className="font-default">HEX</HexLabel>
                                <HexInput
                                    value={inputValue || hexColor.toUpperCase()}
                                    onChange={handleHexInput}
                                    maxLength={7}
                                    placeholder="#000000"
                                />
                                <ColorDisplay
                                    style={{
                                        backgroundColor: selectedColor
                                    }}
                                />
                            </HexContainer>
                        </div>
                    </ColorPickerContainer>

                    <ButtonContainer>
                        <ActionButton
                            className="mr-[43px]"
                            onClick={handleConfirm}
                        >
                            Confirm
                        </ActionButton>
                        <ActionButton onClick={() => setStep(6)}>
                            Go back
                        </ActionButton>
                    </ButtonContainer>
                </div>
            </FormSection>
            
            <Footer>
                <LogoImage src={dizeeLogo} alt="DIZEE" />
                <FooterText>
                    {validationMessage || "This will be used as the main color in your dizee brand kit, used for backgrounds"}
                </FooterText>
            </Footer>
        </Container>
    );
};

// Utility functions for color conversion
const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100)
    };
};

export default MainColorPicker;
