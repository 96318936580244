import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2, DizeeNumberInput } from '../../../../../components/DizeeComponent/DixeeInput2';
import CopyLink from '../../../../../svg/CopyLink';
import ImageSelectionCard from '../../../../../components/ImageSelectionCard';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../../../utils/upload';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { addProductToSectionThunk, getProductThunk } from '../../../../../store/addsection/addsectionThunk';
import AddedProductCard from '../../../../../components/AddSection/Product/AddedProductCard';
import ProductOverlay from '../../../../../components/AddSection/Product/ProductOverlay';
import QueuedProductCard from '../../../../../components/AddSection/Product/QueuedProductCard';


export default function ManuallyAddProduct() {
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const [link, setLink] = useState('');
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [linkForBackend, setLinkForBackend] = useState('')
    const [previousUrl, setPreviousUrl] = useState('');
    const [previousSource, setPreviousSource] = useState('');

    const product = useSelector((state) => state.addsection.product);
    const dispatch = useDispatch();

    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState('');

    const [entries, setEntries] = useState(() => {
        const saved = localStorage.getItem('productQueueEntries');
        return saved ? JSON.parse(saved) : [];
    });

    const isFormComplete = useMemo(() => {
        return Boolean(
            productName?.trim() && 
            link?.trim() && 
            (selectedImage || imagePreview) && 
            price?.trim() && 
            description?.trim()
        );
    }, [productName, link, selectedImage, imagePreview, price, description]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const resetImage = () => {
        setSelectedImage(null);
        setImagePreview(null);
    };
    useEffect(() => {
        dispatch(clearAlerts())
        dispatch(getProductThunk({ token }))
    }, [dispatch, token])

    const filterLink = (link) => {
        if (product?.length > 0) {
            for (let m of product) {
                for (let musicLink of m.links) {
                    if (musicLink.url === link) {
                        return true;
                    }
                }
            }
        }
        return false;
    };



    const handleAdd = async () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all fields'))
            return
        }

        const isExistLink = filterLink(link);
        if (!previousUrl && isExistLink) {
            dispatch(setErrorAlert('Link already exist'));
            return;
        }

        dispatch(setLoader(true));
        let url = '';
        if (selectedImage) {
            url = await uploadImage(selectedImage);
            if (!url) {
                dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                dispatch(setLoader(false));
                return;
            }
        }
        dispatch(setLoader(false));

        // Create new entry
        const newEntry = {
            id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
            type: 0,
            productName,
            description,
            price,
            image: url || imagePreview,
            links: [{
                source: previousSource || 'custom',
                url: link.startsWith('http') ? link : `https://${link}`
            }]
        };

        // Update entries state and localStorage
        const updatedEntries = [...entries, newEntry];
        setEntries(updatedEntries);
        localStorage.setItem('productQueueEntries', JSON.stringify(updatedEntries));
        
        // Clear form after adding to queue
        clearForm();
    };

    const handleNext = async () => {
        if (entries.length === 0 && !isFormComplete) {
            dispatch(setErrorAlert('Please add at least one entry or complete the current form'));
            return;
        }

        if (isFormComplete) {
            let url = '';
            if (selectedImage) {
                url = await uploadImage(selectedImage);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
            }

            const currentEntry = {
                type: 0,
                productName,
                description,
                price,
                image: url || imagePreview,
                links: [{
                    source: previousSource || 'custom',
                    url: link.startsWith('http') ? link : `https://${link}`
                }]
            };

            navigate('/add-section/product/manual/publish', { 
                state: { 
                    entries: [...entries, currentEntry],
                    returnPath: '/add-section/manually-add-product'
                } 
            });
        } else {
            // If entries exist in queue, proceed with those
            navigate('/add-section/product/manual/publish', { 
                state: { 
                    entries,
                    returnPath: '/add-section/manually-add-product'
                } 
            });
        }
    };

    // Add these button control flags
    const showQueue = isFormComplete;
    const showNext = entries.length > 0 || isFormComplete;
    const showClear = !isFormComplete && (productName || link || selectedImage || imagePreview || price || description);

    // Add clearForm function
    const clearForm = () => {
        setProductName('');
        setDescription('');
        setLink('');
        setPrice('');
        setSelectedImage(null);
        setImagePreview(null);
    };

    // Add this function with the other state management functions
    const handleDeleteFromQueue = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
        localStorage.setItem('productQueueEntries', JSON.stringify(updatedEntries));
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>

                        <span className='text-white'>Manually add product</span>
                    </div>
                    <div className='flex gap-[30px]'>
                        {showQueue && (
                            <button 
                                onClick={handleAdd}
                                className='text-white cursor-pointer'
                            >
                                Queue
                            </button>
                        )}
                        {showNext && (
                            <button 
                                onClick={handleNext} 
                                className='text-white cursor-pointer'
                            >
                                Next
                            </button>
                        )}
                        {showClear && (
                            <button 
                                onClick={clearForm} 
                                className='text-white cursor-pointer'
                            >
                                Clear
                            </button>
                        )}
                        <p onClick={() => navigate('/add-section/add-product')} 
                           className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full gap-y-[50px]" >
                    <div className='w-full'>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Enter a link"
                                    className="dizee-input w-full"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                            <CopyLink className='h-[14px] w-[14px] mx-1' />
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Product name"
                                    className="dizee-input w-full"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Description"
                                    className="dizee-input w-full"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeNumberInput
                                    label="Link"
                                    placeholder="Price"
                                    type="number"
                                    className="dizee-input w-full"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                        </div>
                        <ImageSelectionCard
                            txt="Add cover image"
                            dotimgclss={false}
                            onImageChange={handleImageChange}
                            imagePreview={imagePreview}
                            resetImage={resetImage}
                        />
                    </div>
                    {/* Add Queue Display Section */}
                    {entries?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>In Queue</p>
                                </div>
                            </div>
                            {entries.map((item, index) => (
                                <QueuedProductCard
                                    key={index}
                                    product={item}
                                    onDelete={() => handleDeleteFromQueue(index)}
                                />
                            ))}
                        </div>
                    )}

                    {/* Existing Added Products Section */}
                    {product?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added Products</p>
                                </div>
                            </div>
                            {
                                product?.length > 0 && product?.map((item, index) => <AddedProductCard key={index} item={item} setOverlayVisible={setOverlayVisible} setItemForBackend={setItemForBackend} setLinkForBackend={setLinkForBackend} />)
                            }
                        </div>
                    )}
                </div>
            </div>
            {isOverlayVisible && <ProductOverlay setOverlayVisible={setOverlayVisible} linkForBackend={linkForBackend} itemForBackend={itemForBackend} setLink={setLink} setProductName={setProductName} setPrice={setPrice} setDescription={setDescription} setImagePreview={setImagePreview} setSelectedImage={setSelectedImage} setPreviousUrl={setPreviousUrl} setPreviousSource={setPreviousSource} />}

        </LayoutHeader>
    );
}
