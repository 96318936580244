import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductThunk, updateProductThunk, getProductThunk } from '../../../store/addsection/addsectionThunk';
import { clearAlerts, setErrorAlert, setLoader, setSuccessAlert } from '../../../store/alert/alertSlice';
import { Calendar } from '@nayojs/react-datetime-picker';
import DizeeTimeInput from '../../DizeeComponent/DizeeTimeInput';
import DizeeModal from '../../DizeeComponent/DizeeModal';
import DizeeButton from '../../DizeeComponent/DizeeButton';
import DizeeSelect from '../../DizeeComponent/DizeeSelect';
import { uploadImage } from '../../../utils/upload';

export default function ProductOverlay({ setOverlayVisible, linkForBackend, itemForBackend }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const product = useSelector((state) => state.addsection.product);
    const index = product.findIndex(product => product.links[0].url === linkForBackend);

    const [showCalendar, setShowCalendar] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [preview, setPreview] = useState(product[index]?.image || null);
    const [link, setLink] = useState('');
    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [previousSource, setPreviousSource] = useState('');

    const [editForm, setEditForm] = useState({
        productName: '',
        description: '',
        price: '',
        link: ''
    });

    useEffect(() => {
        if (!product[index]) {
            setOverlayVisible(false);
            return;
        }
    }, [product, index]);

    useEffect(() => {
        if (product[index]) {
            setEditForm({
                productName: product[index].productName || '',
                description: product[index].description || '',
                price: product[index].price || '',
                link: product[index].links?.[0]?.url || ''
            });
            setImagePreview(product[index].image || '');
            setPreviousUrl(product[index].links?.[0]?.url || '');
            setPreviousSource(product[index].links?.[0]?.source || 'custom');
        }
    }, [product, index]);

    const handleEditorClick = () => {
        dispatch(clearAlerts());
        setShowEditor(true);
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const previewUrl = URL.createObjectURL(file);
            
            // Update the product item with preview
            const updatedProduct = {
                ...product[index],
                image: previewUrl
            };

            // Update product array in state
            const newProductArray = [...product];
            newProductArray[index] = updatedProduct;
            
            // Set local state
            setSelectedImage(file);
            setPreview(previewUrl);
        }
    };

    const handleImageUpload = async (file) => {
        dispatch(setLoader(true));
        dispatch(clearAlerts());
        
        try {
            const url = await uploadImage(file);
            if (!url) {
                dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                return null;
            }

            // Update the product item with the uploaded URL
            const updatedProduct = {
                ...product[index],
                image: url
            };

            // Update the product array
            const newProductArray = [...product];
            newProductArray[index] = updatedProduct;

            // Send update to backend
            await dispatch(updateProductThunk({ 
                link: linkForBackend,
                id: product[index].id,
                saveType: "edit",
                updates: { 
                    image: url,
                    id: product[index].id
                },
                token 
            }));

            return url;
        } catch (error) {
            console.error('Error uploading image:', error);
            dispatch(setErrorAlert('Error uploading image'));
            return null;
        } finally {
            dispatch(setLoader(false));
        }
    };

    const onSaveEdit = useCallback(async () => {
        dispatch(clearAlerts());
        
        try {
            if (!editForm?.productName?.trim() || !editForm?.description?.trim()) {
                dispatch(setErrorAlert('Please fill in all required fields'));
                return;
            }

            let imageUrl = product[index].image;
            if (selectedImage) {
                imageUrl = await handleImageUpload(selectedImage);
                if (!imageUrl) return; // Stop if image upload failed
            }

            const updates = {
                id: product[index].id,
                productName: editForm.productName.trim(),
                description: editForm.description.trim(),
                price: editForm.price?.trim() || '0',
                image: imageUrl,
                type: 'product'
            };

            const result = await dispatch(updateProductThunk({
                token: localStorage.getItem('dizeeToken'),
                link: linkForBackend,
                id: product[index].id,
                saveType: 'edit',
                updates
            })).unwrap();

            if (result?.status === 200) {
                dispatch(getProductThunk({ token: localStorage.getItem('dizeeToken') }));
                setShowEditor(false);
                setOverlayVisible(false);
                dispatch(setSuccessAlert('Product updated successfully'));
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            dispatch(setErrorAlert(error?.message || 'Failed to update product'));
        }
    }, [dispatch, editForm, product, index, setOverlayVisible, selectedImage]);

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    const selectDateHandler = (date) => {
        setDate(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
    }
    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    }
    const onSaveSchedule = () => {
        dispatch(clearAlerts());
        if (date.length < 1 || time.length < 5) {
            return;
        }
        const saveType = "schedule"
        const timer = `${date} ${time} ${pm ? 'pm' : 'am'}`
        dispatch(updateProductThunk({ timer, link: linkForBackend, saveType, token }));
        setOverlayVisible(false);
    }

    const handleScheduleClick = () => {
        dispatch(clearAlerts());
        setShowCalendar(true);
    };

    const handlePublishClick = () => {
        const saveType = "publish"
        dispatch(clearAlerts());
        dispatch(updateProductThunk({ link: linkForBackend, saveType, token }));
        setOverlayVisible(false);
    };

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(deleteProductThunk({ token: token, link: linkForBackend }));
        setOverlayVisible(false);
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >

            {showEditor && (
                <DizeeModal isOverlayVisible={showEditor} setOverlayVisible={setShowEditor}>
                    <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                        <div className='px-4 my-[50px] flex w-full justify-between'>
                            <p className='cursor-pointer text-white'>Edit details</p>
                            <div className='cursor-pointer flex gap-x-4'>
                                <DizeeButton label={'Confirm'} onClick={onSaveEdit} />
                                <DizeeButton label={'Go back'} onClick={() => setShowEditor(false)} />
                            </div>
                        </div>
                        <div className='px-4 my-[80px] flex flex-col w-full'>
                            <input
                                value={editForm.productName}
                                onChange={(e) => setEditForm(prev => ({ ...prev, productName: e.target.value }))}
                                className='bg-transparent border-none focus:outline-none w-full text-white py-6'
                                placeholder="Product Name"
                            />
                            <input
                                value={editForm.description}
                                onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
                                className='bg-transparent border-none focus:outline-none w-full text-white py-6'
                                placeholder="Description"
                            />
                            <input
                                value={editForm.price}
                                onChange={(e) => setEditForm(prev => ({ ...prev, price: e.target.value }))}
                                className='bg-transparent border-none focus:outline-none w-full text-white py-6'
                                placeholder="Price"
                                type="number"
                            />
                            <div className='flex flex-row items-center justify-between'>
                                <p className="cursor-pointer text-white" onClick={() => document.getElementById("cover-image").click()}>
                                    Change cover image
                                </p>
                                {preview && (
                                    <img 
                                        src={preview}
                                        alt="Product Cover"
                                        className="object-cover h-[50px] w-[50px] rounded-[3px] ml-4"
                                        onClick={() => document.getElementById("cover-image").click()}
                                    />
                                )}
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                id="cover-image"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                        </div>
                    </div>
                </DizeeModal>
            )}

            {product[index].publish.value === "draft" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={handleEditorClick}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : product[index].publish.value === "schedule" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={handleEditorClick}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Edit Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={handleEditorClick}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>}

            {showCalendar &&
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <Calendar selectDateHandler={selectDateHandler} calendarStyles={{
                            containerStyles: { backgroundColor: '#000' },
                            dateStyles: { color: '#fff' },
                            selectedDateStyles: { backgroundColor: '#000', color: '#ff2' },
                            headerStyles: { background: '#000' },
                            headerTitleTextStyles: { color: '#fff' },
                            headerTitleStyles: { color: '#fff' },
                        }} />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime='11:12'
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            }
            {/* <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditClick()}>edit</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> */}
        </div>
    )
}
