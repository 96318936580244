import React from 'react'
import { Link } from 'react-router-dom'
import Check from '../../../svg/Check'

export default function MusicPlatform({ txt, link, selected, onSelect }) {
    return (
        <div className='px-4 py-3 flex w-full items-center cursor-pointer' onClick={onSelect}>
            <span className='text-white text-[12px] hover:text-[#4d4d4d] transition-colors duration-200'>{txt}</span>
            {selected && (
                <div className='ml-2'>
                    <Check className='h-[12px] w-[18px] cursor-pointer' />
                </div>
            )}
        </div>
    )
}
