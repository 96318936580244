import React, { useEffect, useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearAlerts } from '../store/alert/alertSlice';
import ClipLoader from 'react-spinners/ClipLoader';

// Custom styles for more Apple-like notifications
const toastStyles = {
    success: {
        style: {
            background: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            borderRadius: '12px',
            padding: '12px 24px',
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            transition: 'all 0.3s ease',
            border: '1px solid rgba(255, 255, 255, 0.1)',
        }
    },
    error: {
        style: {
            background: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            borderRadius: '12px',
            padding: '12px 24px',
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            transition: 'all 0.3s ease',
            border: '1px solid rgba(255, 255, 255, 0.1)',
        }
    }
};

const Alert = () => {
    const dispatch = useDispatch();
    const alert = useSelector((state) => state.alert);
    
    const showErrorToast = useCallback((message) => {
        if (!message || message.includes('approve_subscription')) return;
        
        toast.error(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: 'error-toast',
            transition: Slide,
            ...toastStyles.error
        });
    }, []);

    const showSuccessToast = useCallback((message) => {
        if (!message) return;
        
        toast.success(message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: 'success-toast',
            ...toastStyles.success
        });
    }, []);

    useEffect(() => {
        let timeoutId;

        if (alert?.error && !alert?.silentError) {
            showErrorToast(alert.error);
            timeoutId = setTimeout(() => {
                dispatch(clearAlerts());
            }, 3000);
        }

        if (alert?.success) {
            showSuccessToast(alert.success);
            timeoutId = setTimeout(() => {
                dispatch(clearAlerts());
            }, 3000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [alert?.error, alert?.success, dispatch, showErrorToast, showSuccessToast]);

    return (
        <ToastContainer 
            className={'z-50'} 
            limit={1} 
            enableMultiContainer={false}
            transition={Slide}
        />
    );
};

export default memo(Alert);
