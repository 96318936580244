import React, { useEffect, useState } from 'react';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { useNavigate } from 'react-router';
import Plus from '../../../../../svg/Plus';
import { useDispatch, useSelector } from 'react-redux';
import { delegate_access, delete_delegate_access, get_delegate_access } from '../../../../../store/setting/settingThunk';
import { setErrorAlert } from '../../../../../store/alert/alertSlice';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import styled from 'styled-components';

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  padding: 0 24px;
  width: 390px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
`;

export default function DelegateAccess() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const token = localStorage.getItem('dizeeToken');
    const delegateaccess = useSelector(state => state?.setting?.delegateaccess);
    const [confirmDeleteEmail, setConfirmDeleteEmail] = useState(null); // State to track the email for deletion confirmation

    useEffect(() => {
        dispatch(get_delegate_access({ token }));
    }, [dispatch, token]);

    const handleInvite = () => {
        if (!email) {
            dispatch(setErrorAlert("Email cannot be empty"));
            return;
        }
        let body = { email };
        dispatch(delegate_access({ body, token }));
        setEmail('');
    };

    const handleDelete = (email) => {
        dispatch(delete_delegate_access({ email, token }));
        setConfirmDeleteEmail(null); // Reset the confirmation state after deletion
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Delegate access</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex flex-col gap-y-6">
                            <div className="text-white">
                                Add team members to manage your page
                            </div>
                            <div className="flex flex-col gap-y-[40px]">
                                <div className='flex w-full items-center gap-x-[43px]'>
                                    <DizeeInput
                                        placeholder={"Enter an email"}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="flex-1"
                                    />
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px] whitespace-nowrap"
                                        onClick={handleInvite}
                                    >
                                        Invite
                                    </button>
                                </div>
                            </div>
                        </div>

                        {delegateaccess?.length > 0 && (
                            <div className='mt-[40px] flex flex-col gap-y-6'>
                                <div className="text-white">Your team</div>
                                <div className='flex flex-col gap-y-[20px]'>
                                    {delegateaccess?.map((item, index) => (
                                        <div key={index} className='flex justify-between items-center'>
                                            <p className='text-white'>{item?.email}</p>
                                            {item?.status === 'pending' ? (
                                                <p className='text-white opacity-50'>{item?.status}</p>
                                            ) : confirmDeleteEmail === item.email ? (
                                                <button 
                                                    onClick={() => handleDelete(item?.email)}
                                                    className='text-white text-[12px] h-[18px] leading-[18px]'
                                                >
                                                    Confirm deletion
                                                </button>
                                            ) : (
                                                <div 
                                                    onClick={() => setConfirmDeleteEmail(item.email)}
                                                    className="rotate-45 cursor-pointer"
                                                >
                                                    <Plus className="h-[12px] w-[12px] text-white" />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Footer>
                    <FooterText>
                        Grant trusted team members access to manage your Dizee page. 
                        They'll be able to edit content and settings while you maintain full control.
                    </FooterText>
                </Footer>
            </div>
        </LayoutHeader>
    );
}
