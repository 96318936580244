import { useEffect } from "react";
import Check from "../../../svg/Check";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import { NextButton } from "../../../components/DizeeComponent/NextButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const RoleOptions = styled.div`
  display: flex;
  gap: 22px;
  font-size: 12px;
  color: white;
  height: 18px;
  margin: 2px 0;
  line-height: 18px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  position: relative;
`;

const comment = {
  personal: "A Personal account on Dizee gives you the tools to showcase your work, engage with your audience, and grow your brand. Perfect for creators, businesses, and brands looking to make an impact.",
  professional: "A Professional account on Dizee gives you the tools to showcase your work, engage with your audience, and grow your brand. Perfect for creators, businesses, and brands looking to make an impact.",
};

const Step2 = ({ setStep, setUser, user }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && user.professionRole && user.professionRole !== "personal") {
      setStep(3);
    }
  };

  const handleNext = () => {
    if (user.professionRole === "personal") {
      return;
    }
    if (user.professionRole) {
      setStep(3);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [user.professionRole]);

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"What describes you best?"} speed={45} />
        <div style={{ marginTop: '2px' }}>
          <RoleOptions>
            <div
              className="cursor-pointer flex items-center relative"
              onClick={() => setUser({ ...user, professionRole: "personal" })}
            >
              <div className={user.professionRole === "personal" ? "opacity-50" : ""}>Personal</div>
              <div className="w-[18px] h-[12px] ml-2">
                {user.professionRole === "personal" && (
                  <Check className="h-[12px] w-[18px] opacity-50" />
                )}
              </div>
            </div>

            <div
              className="cursor-pointer flex items-center"
              onClick={() => setUser({ ...user, professionRole: "professional" })}
            >
              <div>Professional</div>
              <div className="w-[18px] h-[12px] ml-2">
                {user.professionRole === "professional" && (
                  <Check className="h-[12px] w-[18px]" />
                )}
              </div>
            </div>
          </RoleOptions>
        </div>

        <ButtonContainer>
          <ActionButton
            onClick={handleNext}
            className={`${user.professionRole ? "font-default" : "font-gray"} 
              ${user.professionRole === "personal" ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            Next
          </ActionButton>
        </ButtonContainer>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          {comment[user?.professionRole] || "Select the type of account that best suits your needs."}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step2;
