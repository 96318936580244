import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { submitFeedbackThunk } from '../../store/feedback/feedbackThunk';
import { setErrorAlert, setSuccessAlert } from '../../store/alert/alertSlice';

export default function FeedbackWidget() {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  
  const handleSubmit = async () => {
    if (!feedback.trim()) {
      dispatch(setErrorAlert('Please enter your feedback'));
      return;
    }

    if (!navigator.onLine) {
      dispatch(setErrorAlert('Please check your internet connection'));
      return;
    }
    
    try {
      setIsSubmitting(true);
      const result = await dispatch(submitFeedbackThunk({ 
        feedback: feedback.trim(), 
        page: location.pathname 
      })).unwrap();
      
      if (result?.status === 200) {
        dispatch(setSuccessAlert(result.message || 'Feedback submitted successfully'));
        setIsSubmitted(true);
        setTimeout(() => {
          setIsOpen(false);
          setIsSubmitted(false);
          setFeedback('');
        }, 2000);
      }
    } catch (error) {
      console.error('Feedback submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed bottom-20 right-4 z-50">
      {!isOpen ? (
        <button
          onClick={() => setIsOpen(true)}
          className="w-[40px] h-[40px] bg-[#FF2300] rounded-[4px] flex items-center justify-center cursor-pointer hover:opacity-90 transition-opacity"
        >
          <span className="text-white text-[20px]">?</span>
        </button>
      ) : (
        <div 
          className="w-[300px] bg-black rounded-[4px] p-4" 
          style={{ border: '0.5px solid rgba(255, 255, 255, 0.1)' }}
        >
          <div className="flex justify-between items-center mb-4">
            <span className="text-white text-[12px]">How can we improve?</span>
            <button 
              onClick={() => setIsOpen(false)}
              className="text-white text-[12px] hover:text-[#4d4d4d] transition-colors duration-200"
            >
              Close
            </button>
          </div>
          {!isSubmitted ? (
            <>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="w-full h-[100px] bg-transparent text-white text-[12px] focus:outline-none resize-none"
                placeholder="Type your feedback here..."
              />
              <div className="flex justify-end">
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting || !feedback.trim()}
                  className={`text-white text-[12px] hover:text-[#4d4d4d] transition-colors duration-200 
                    ${(isSubmitting || !feedback.trim()) ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </>
          ) : (
            <div className="text-white text-[12px] text-center py-4">
              Thank you for your feedback!
            </div>
          )}
        </div>
      )}
    </div>
  );
} 