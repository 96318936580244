import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutThunk } from '../store/auth/authThunk';
import { clearAlerts } from '../store/alert/alertSlice';
import DotSvg from '../svg/DotSvg';
import { removeUploadsFromUrl } from '../components/DizeeComponent/DixeeInput2';
import { Link, useLocation } from 'react-router-dom';
import { update_user_article_thunk } from '../store/user/userThunk';
import PublishOverlay from '../components/PublishOverlay';

export default function Header() {
    let token = localStorage.getItem('dizeeToken');
    const location = useLocation();
    const currentPath = location.pathname;
    const userArticle = useSelector((state) => state.user.userArticle);


    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showPublish, setShowPublish] = useState(false);

    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const profilePicRef = useRef(null);

    let user = localStorage.getItem('dizeeUser');
    user = JSON.parse(user);


    const toggleDropdown = () => {
        setDropdownVisible(prev => !prev);
    };

    const handleLogout = () => {
        dispatch(clearAlerts());
        dispatch(logoutThunk());
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current && !dropdownRef.current.contains(event.target) &&
            profilePicRef.current && !profilePicRef.current.contains(event.target)
        ) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const updateUserArticle = async (bool) => {
        // dispatch(setUserArticle({ ...userArticle, unpublish: bool }));
        dispatch(update_user_article_thunk({ token, body: { ...userArticle, unpublish: bool } }));
    }

    return (
        <>
            {

                <div className='fixed top-0 left-0 w-full h-[100px] flex items-center justify-between px-[3vw] z-[9] bg-black'>
                    <div className="flex-1"></div>
                    <div className='flex items-center justify-between w-full sm:w-auto sm:gap-[100px] md:gap-[120px] lg:gap-[220px]'>
                        <Link to={'/'} className={`${currentPath === '/' ? 'text-white' : 'text-[#4d4d4d]'} text-[14px] cursor-pointer`}>
                            brand page
                        </Link>
                        <span className='text-[#4d4d4d] text-[14px] cursor-not-allowed opacity-50'>
                            focus page
                        </span>
                        <Link to={'/more'} className={`${currentPath.includes('/more') ? 'text-white' : 'text-[#4d4d4d]'} text-[14px] cursor-pointer hidden sm:block`}>
                            more
                        </Link>
                        <span className='text-[#4d4d4d] text-[14px] cursor-not-allowed opacity-50'>
                            publish
                        </span>
                        {/* Mobile: Direct link to More */}
                        <Link to="/more" className="sm:hidden">
                            <img
                                src={removeUploadsFromUrl(`${user?.avatar}`)}
                                alt="imagedp"
                                className="h-[32px] w-[32px] rounded-full cursor-pointer ml-4"
                            />
                        </Link>
                    </div>
                    {/* Desktop/Tablet: Profile circle with dropdown */}
                    <div className="flex-1">
                        <div className="relative hidden sm:flex justify-end">
                            <img
                                src={removeUploadsFromUrl(`${user?.avatar}`)}
                                alt="imagedp"
                                className="h-[40px] w-[40px] rounded-full cursor-pointer"
                                onClick={toggleDropdown}
                                ref={profilePicRef}
                            />
                            {dropdownVisible && (
                                <div
                                    ref={dropdownRef}
                                    className="absolute right-0 mt-[50px] w-[200px] bg-black rounded-[4px] py-3 z-50"
                                    style={{
                                        boxShadow: '0 0 0 0.5px rgba(255, 255, 255, 0.1)',
                                        borderTop: '0.5px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    <div className="px-4 py-2">
                                        <p className="text-[12px] text-white">
                                            diz.ee/{userArticle?.domain}
                                        </p>
                                    </div>
                                    <Link 
                                        to="/more/setting" 
                                        className="block px-4 py-2 text-[12px] text-white hover:text-[#4d4d4d] transition-colors duration-200"
                                        onClick={() => setDropdownVisible(false)}
                                    >
                                        Settings
                                    </Link>
                                    <button
                                        className="w-full text-left px-4 py-2 text-[12px] text-white hover:text-[#4d4d4d] transition-colors duration-200"
                                        onClick={() => {
                                            handleLogout();
                                            setDropdownVisible(false);
                                        }}
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            {showPublish && <PublishOverlay setShowPublish={setShowPublish} />}
        </>

    );
}
