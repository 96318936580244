import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TypeWriter from '../../components/OnBoard/Typewriter';
import dizeeLogo from '../../assets/images/dizee logo.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const TypewriterWrapper = styled.div`
  position: absolute;
  top: 200px;
  width: 280px;
`;

const ChatContainer = styled.div`
  width: 280px;
  height: 160px;
  overflow-y: auto;
  margin: 60px 0 20px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
`;

const Message = styled.div`
  font-size: 12px;
  color: white;
  min-height: 18px;
  line-height: 18px;
  margin: 2px 0;
  text-align: left;
`;

const Input = styled.input`
  background: transparent;
  border: none;
  font-size: 12px;
  width: 280px;
  color: white;
  height: 18px;
  line-height: 18px;
  padding: 0;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    caret-color: white;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.3)' : 'white'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  height: 18px;
  line-height: 18px;
  margin-right: 43px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

export default function SupportHub() {
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isAiTyping, setIsAiTyping] = useState(false);
  const [needsHumanSupport, setNeedsHumanSupport] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, isAiTyping]);

  const handleSubmit = async () => {
    if (!query.trim() || isAiTyping) return;
    
    setChatHistory(prev => [...prev, { isUser: true, text: query }]);
    setQuery('');
    setIsAiTyping(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setChatHistory(prev => [...prev, { 
        isUser: false, 
        text: "I understand your question. Let me help you with that..." 
      }]);
      setIsAiTyping(false);
    } catch (error) {
      console.error('AI response error:', error);
      setNeedsHumanSupport(true);
    }
  };

  return (
    <Container>
      <FormSection>
        <TypewriterWrapper>
          <TypeWriter text="How can we assist you today?" speed={45} />
        </TypewriterWrapper>
        
        <ChatContainer ref={chatContainerRef}>
          <MessagesWrapper>
            {chatHistory.map((msg, idx) => (
              <Message key={idx}>
                {msg.text}
              </Message>
            ))}
            {isAiTyping && (
              <Message>Thinking...</Message>
            )}
          </MessagesWrapper>
        </ChatContainer>

        <Input
          placeholder="Type your question..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
        />

        <ButtonContainer>
          <ActionButton 
            onClick={handleSubmit}
            disabled={!query.trim() || isAiTyping}
          >
            Ask question
          </ActionButton>
          {needsHumanSupport && (
            <ActionButton onClick={() => navigate('/customer-service/create-ticket')}>
              Create Ticket
            </ActionButton>
          )}
        </ButtonContainer>
      </FormSection>

      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          Our AI assistant is here to help. If needed, we'll connect you with our support team.
        </FooterText>
      </Footer>
    </Container>
  );
} 