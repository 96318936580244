import React from 'react';
import { getContrastColor, maxLength } from './DizeeComponent/DixeeInput2';
import { getAccentLightStyle, getAccentStyle, haxToRgbLight } from '../constants/constants';
import Spotify from '../svg/Social/Spotify';
import AppleMusic from '../svg/Social/AppleMusic';
import AmazonMusic from '../svg/MusicPlatform/AmazonMusic';
import SoundCloud from '../svg/MusicPlatform/SoundCloud';
import Tidal from '../svg/MusicPlatform/Tidal';
import { useNavigate, useParams } from 'react-router-dom';
import MusicDataPage from '../pages/Main/Pages/Music/MusicDataPage';
import { postDataAPI } from '../utils/fetchData';

const componentMapping = {
    spotify: (props) => <Spotify {...props} />,
    apple_music: (props) => <AppleMusic {...props} />,
    amazon: (props) => <AmazonMusic {...props} />,
    soundcloud: (props) => <SoundCloud {...props} />,
    tidal: (props) => <Tidal {...props} />
};

const CusCardMusic = ({ imgurl, txt, artist, userArticle, links, item }) => {
    const navigate = useNavigate();
    const { domain: dynamicVar } = useParams();
    const contrastColor = getContrastColor(userArticle?.background);
    const accentStyle = getAccentStyle(contrastColor);
    const accentLightStyle = getAccentLightStyle(haxToRgbLight(contrastColor));
    const [showModal, setShowModal] = React.useState(false);

    // Add useEffect to handle body scroll locking
    React.useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        
        // Cleanup when component unmounts
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showModal]);

    const handleClick = async () => {
        // For manual entries (single link with no source)
        if (item?.links?.length === 1 && !item?.links[0]?.source) {
            let url = item.links[0].url;
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'https://' + url;
            }
            
            window.open(url, '_blank');
            try {
                await postDataAPI('analytics/create-music-analytics', {
                    link: url,
                    domain: dynamicVar,
                    title: item.title,
                    source: item.links[0].source
                }, null);
            } catch (err) {
                console.log(err);
            }
        } else {
            // Scroll to top first
            window.scrollTo({ top: 0, behavior: 'smooth' });
            
            // Show modal and lock scrolling after scroll completes
            setTimeout(() => {
                setShowModal(true);
                document.body.style.overflow = 'hidden';
            }, 500);
        }
    };

    return (
        <>
            <div className="w-[130px] flex-shrink-0 flex flex-col h-auto">
                <img
                    onClick={handleClick}
                    src={imgurl} 
                    alt="imageurl" 
                    className="h-[130px] object-cover w-[130px] cursor-pointer rounded-[3px]" 
                />
                <div className="w-full pt-3 justify-center flex flex-col items-center gap-y-1">
                    <p className="text-white w-full text-[12px]" style={getAccentStyle(userArticle?.accent)}>
                        {maxLength(txt, 15)}
                    </p>
                    <p className="text-gray-300 w-full text-[12px]" style={getAccentLightStyle(haxToRgbLight(userArticle?.accent))}>
                        {maxLength(artist, 15)}
                    </p>
                </div>
            </div>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-[9999]" 
                    style={{ 
                        top: '-25vh',  // Move up by 25% of viewport height
                        height: '125vh' // Increase height to compensate
                    }}
                >
                    <div className="relative">
                        <MusicDataPage 
                            domain={userArticle?.domain} 
                            id={item?.id} 
                            setShowModal={setShowModal} 
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default CusCardMusic;


// <div className="w-[130px] flex-shrink-0 flex flex-col h-auto">
//     {/* Image container with hover group */}
//     <div className="relative overflow-hidden group">
//         <img
//             onClick={() => window.open(links[0]?.url, '_blank')}
//             src={imgurl}
//             alt="imageurl"
//             className="h-[130px] object-cover w-[130px] cursor-pointer rounded-[3px]"
//         />
//         {/* Overlay, visible on hover via group-hover */}
//         <div className="hidden group-hover:flex absolute top-0 left-0 bg-black bg-opacity-50 w-full h-full rounded-[3px] justify-center items-center text-white text-sm">
//             <div className="flex flex-wrap justify-center items-center gap-2 px-2">
//                 {links.map(link => (
//                     <span key={link.source} className="mx-1 cursor-pointer" onClick={() => window.open(link.url, '_blank')}>
//                         {componentMapping[link.source] && componentMapping[link.source]({ className: 'w-4 h-4' })}
//                     </span>
//                 ))}
//             </div>
//         </div>
//     </div>

//     {/* Text part, not affected by hover */}
//     <div className="w-full pt-3 flex flex-col items-center gap-y-1">
//         <p
//             className="text-white w-full text-[12px] text-start"
//             style={accentStyle}
//         >
//             {maxLength(txt, 15)}
//         </p>
//         <p
//             className="text-gray-300 w-full text-[12px] text-start"
//             style={accentLightStyle}
//         >
//             {maxLength(artist, 15)}
//         </p>
//     </div>
// </div>