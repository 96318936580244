import React from 'react'
import LayoutHeader from '../../../../layout/LayoutHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { sendOtpToEmailThunk, verifyEmailOTP, verifyPhone } from '../../../../store/auth/authThunk';
import RedirectCard from '../../../../components/More/RedirectCard';

export default function Advanced() {
    const token = localStorage.getItem('dizeeToken')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userArticle = useSelector((state) => state.user.userArticle);
    const { phone, email } = useSelector((state) => state.user.userArticle);

    const changePhoneNumber = async () => {
        dispatch(verifyPhone(phone));
        navigate('/more/setting/verify-old-phone');
    }

    const changeEmail = async () => {
        dispatch(sendOtpToEmailThunk(email));
        navigate('/more/setting/verify-old-email');
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Advanced</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div onClick={changeEmail} className="w-full cursor-pointer">
                    <RedirectCard txt="Update email" dotimgclss={false} link="" />
                </div>

                <div onClick={changePhoneNumber} className="w-full cursor-pointer">
                    <RedirectCard txt="Change phone number" dotimgclss={false} link="" />
                </div>

                <div onClick={() => navigate('/more/setting/advanced/redirect')} className="w-full cursor-pointer">
                    <RedirectCard txt="Redirect link" dotimgclss={false} link="" />
                </div>

                {/* Commenting out delegate access option
                <div onClick={() => navigate('/more/setting/advanced/delegate-access')} className="w-full cursor-pointer">
                    <RedirectCard txt="Manage delegate access" dotimgclss={false} link="" />
                </div>
                */}

                <div onClick={() => navigate('/more/setting/advanced/close-account')} className="w-full cursor-pointer">
                    <div className="w-full px-4 py-[14px] border-b border-[#ffffff1a]">
                        <div className="flex justify-between items-center">
                            <p className="text-[#FF4444] text-[12px] leading-[18px]">
                                Close account
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}
