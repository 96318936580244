import { useEffect, useState } from "react";
import DizeeInput from "../../../components/DizeeComponent/DizeeInput";
import { verifyOTP, verifyPhone } from "../../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../../store/alert/alertSlice";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const Step1 = ({ setStep, setUser, user }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const { verifiedOTP } = useSelector((state) => state.auth);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (verifiedOTP) {
      setStep(2);
    }
  }, [verifiedOTP, setStep]);

  useEffect(() => {
    // Timer countdown
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(prev => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const verifyCode = async (e) => {
    e.preventDefault();
    if (!otp) return;
    dispatch(clearAlerts());
    try {
      await dispatch(verifyOTP({ 
        phone: user.phone,
        code: otp
      })).unwrap();
      setStep(2);
    } catch (error) {
      console.error('Verification failed:', error);
    }
  };

  const handleResend = async () => {
    if (resendTimer > 0) return;
    
    dispatch(clearAlerts());
    try {
      // Make sure we have the phone number
      if (!user?.phone) {
        console.error('Phone number is required');
        return;
      }
      
      await dispatch(verifyPhone(user.phone));
      setResendTimer(30); // Start 30 second cooldown
      setOtp(""); // Clear the input field
    } catch (error) {
      console.error('Resend failed:', error);
    }
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"Enter verification code"} speed={45} />
        <form onSubmit={verifyCode}>
          <DizeeInput
            placeholder={"Enter code"}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <ButtonContainer>
            <ActionButton
              className="mr-[43px]"
              onClick={verifyCode}
            >
              Next
            </ActionButton>
            <ActionButton 
              onClick={handleResend}
              style={{ 
                cursor: resendTimer > 0 ? 'not-allowed' : 'pointer',
                opacity: resendTimer > 0 ? 0.5 : 1,
                pointerEvents: resendTimer > 0 ? 'none' : 'auto'
              }}
            >
              Resend
            </ActionButton>
          </ButtonContainer>
        </form>
      </FormSection>
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          By clicking "Next" you agree to the DIZEE TOS{'\n'}
          and Privacy Policy.
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step1;
