import React from 'react';
import More from '../../svg/More';
import { getContrastColor } from '../DizeeComponent/DixeeInput2';
import CusCardMusic from '../CusCardMusic';

export default function Music(props) {
    let { userArticle, music, setMusicOverlay, title } = props;

    console.log('Music component received:', {
        musicLength: music?.length,
        musicItems: music,
        userArticle
    });

    // Filter for only published items
    const publishedMusic = music?.filter(m => m?.publish?.value === 'publish');

    console.log('Published music items:', publishedMusic);

    return (
        <>
            {publishedMusic?.length > 0 &&
                <div className="text-[#FDFAFA] my-4 flex w-full px-4 flex-row justify-between items-center">
                    <div>
                        <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                            {title || `MUSIC`}
                        </p>
                    </div>
                    <More className='h-[20px] w-[20px] cursor-pointer' color={getContrastColor(userArticle?.background) ? `#${getContrastColor(userArticle?.background)}` : '#ffffff'} onClick={() => setMusicOverlay(true)} />
                </div>
            }
            {publishedMusic?.length > 0 && (
                <div className="w-full mb-[60px] overflow-x-auto px-4 flex flex-row gap-x-4">
                    {publishedMusic.map((item, index) => (
                        <CusCardMusic
                            key={item.id || item._id || index}
                            imgurl={item?.avatar}
                            txt={item?.title}
                            artist={item?.description}
                            userArticle={userArticle}
                            links={item?.links}
                            item={item}
                        />
                    ))}
                </div>
            )}
        </>
    );
}