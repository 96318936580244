import React from 'react'
import CrossArrow from '../../svg/CrossArrow'
import more from '../../assets/images/components/More.png'
import { getContrastColor, maxLength } from '../DizeeComponent/DixeeInput2'
import { getAccentColor, getAccentStyle } from '../../constants/constants'
import More from '../../svg/More'

export default function Playlist(props) {
    let { userArticle, playlist, setOverlay, title } = props

    console.log('Playlist component received:', {
        playlistLength: playlist?.length,
        playlistItems: playlist,
        userArticle
    });

    // Filter for only published items - matching Music/Product implementation
    const publishedPlaylist = playlist?.filter(p => p?.publish?.value === 'publish');

    console.log('Published playlist items:', publishedPlaylist);

    // Get accent color once for consistency
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#ffffff';

    return (
        <>
            {publishedPlaylist?.length > 0 && (
                <div className='w-full mb-[60px]'>
                    <div className="text-[#FDFAFA] my-4 flex w-full px-4 flex-row justify-between items-center">
                        <div>
                            <p className="mr-2" style={{ color: accentColor }}>
                                {title || 'PLAYLIST'}
                            </p>
                        </div>
                        <More 
                            className='h-[20px] w-[20px] cursor-pointer' 
                            color={getContrastColor(userArticle?.background) ? `#${getContrastColor(userArticle?.background)}` : '#ffffff'} 
                            onClick={() => setOverlay(true)} 
                        />
                    </div>
                    <div className='w-full flex flex-col justify-center items-center gap-y-[16px]'>
                        {publishedPlaylist.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className='flex w-full item-center justify-between px-4'>
                                    <p className='text-white' style={getAccentStyle(userArticle?.accent)}>
                                        {maxLength(item?.title, 30)}
                                    </p>
                                    <div 
                                        className='flex gap-x-1'
                                        onClick={() => window.open(`/playlist-data/${userArticle?.domain}`, '_blank')}
                                    >
                                        <p className='text-white cursor-pointer' style={getAccentStyle(userArticle?.accent)}>
                                            LISTEN NOW
                                        </p>
                                        <button className="flex items-center justify-center">
                                            <CrossArrow 
                                                className="h-[14px] w-[14px]"
                                                color={accentColor} 
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='w-full mx-4'>
                                    <div className={`mx-4`} style={{ borderBottom: `.3px solid ${accentColor}` }}></div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}