import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { follow_unfollow_auth_thunk, getRandomUserThunk } from '../../../../store/auth/authThunk';
import styled from 'styled-components';
import dizeeLogo from "../../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../../components/OnBoard/Typewriter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const FollowersContainer = styled.div`
  width: 200px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const FollowersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FollowerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const UserInfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Username = styled.p`
  font-size: 12px;
  color: white;
`;

const FollowButton = styled.button`
  font-size: 12px;
  color: ${props => props.isFollowing ? '#FF4444' : 'white'};
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: auto;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.button`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  background: transparent;
  border: none;
  padding: 0;
  font-family: inherit;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

export default function Finalpage({ setStep, setUser, user }) {
    const dispatch = useDispatch();
    const [followers, setFollowers] = useState([]);
    const currentUser = useSelector((state) => state.user.userArticle);
    const username = user?.username;

    useEffect(() => {
        dispatch(getRandomUserThunk({ username }))
            .then((res) => {
                if (res?.payload?.status === 200) {
                    setFollowers(res.payload?.data?.response);
                }
            })
            .catch(console.error);
    }, [username, dispatch]);

    const handleFollowUnfollow = async (followerId, isFollowing, domainVar, follower) => {
        dispatch(follow_unfollow_auth_thunk({ username: username, user: follower }))
            .then((res) => {
                if (res?.payload?.status === 200) {
                    setFollowers((prevFollowers) =>
                        prevFollowers.map((f) =>
                            f._id === followerId
                                ? { ...f, isFollowing: !isFollowing }
                                : f
                        )
                    );
                }
            })
            .catch(console.error);
    };

    return (
        <Container>
            <FormSection>
                <TypeWriter text="Follow some accounts to get started" speed={45} />
                
                <FollowersContainer>
                    <FollowersList>
                        {followers?.map((follower) => (
                            <FollowerItem key={follower._id}>
                                <UserInfoSection>
                                    <Avatar src={follower.avatar} alt={follower.username} />
                                    <Username>{follower.username}</Username>
                                </UserInfoSection>
                                {currentUser?._id !== follower._id && (
                                    <FollowButton
                                        isFollowing={follower.isFollowing}
                                        onClick={() => handleFollowUnfollow(
                                            follower._id,
                                            follower.isFollowing,
                                            follower?.domain,
                                            follower
                                        )}
                                    >
                                        {follower.isFollowing ? 'Unfollow' : 'Follow'}
                                    </FollowButton>
                                )}
                            </FollowerItem>
                        ))}
                    </FollowersList>
                </FollowersContainer>

                <ButtonContainer>
                    <ActionButton onClick={() => window.location.href = "/"}>
                        Get dizee
                    </ActionButton>
                </ButtonContainer>
            </FormSection>
            
            <Footer>
                <LogoImage src={dizeeLogo} alt="DIZEE" />
                <FooterText>
                    Follow some accounts to personalize your experience and stay in touch with your favorite brands.
                </FooterText>
            </Footer>
        </Container>
    );
}
