import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const ChartSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  width: 280px;
`;

const FlowNode = styled.div`
  font-size: 12px;
  color: white;
  padding: 10px;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  text-align: left;
`;

const Arrow = styled.div`
  width: 1px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  margin: 0 auto;
`;

const Decision = styled.div`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
  margin: 5px 0;
  font-style: italic;
`;

export default function ServiceChart() {
  return (
    <Container>
      <ChartSection>
        <FlowNode>Customer Issue Identified</FlowNode>
        <Arrow />
        
        <FlowNode>AI Support Assistant</FlowNode>
        <Decision>Issue resolved?</Decision>
        <Arrow />
        
        <FlowNode>Ticket Creation</FlowNode>
        <Arrow />
        
        <FlowNode>Customer Service Team Review</FlowNode>
        <Decision>Requires technical support?</Decision>
        <Arrow />
        
        <FlowNode>Technical Team Analysis</FlowNode>
        <Decision>Requires executive review?</Decision>
        <Arrow />
        
        <FlowNode>Executive Team Resolution</FlowNode>
        <Arrow />
        
        <FlowNode>Issue Resolution & Feedback</FlowNode>
      </ChartSection>
    </Container>
  );
} 