import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkUserName } from "../../../store/auth/authThunk";
import axios from "../../../utils/axios";
import styled from "styled-components";
import Check from "../../../svg/Check";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const Input = styled.input`
  background: transparent;
  border: none;
  font-size: 12px;
  width: 96px;
  padding: 0;
  color: ${(props) => {
    if (props.isAvailable === null) return "white";
    if (props.isAvailable === true) return "#90EE90";
    if (props.isAvailable === false) return "#FF9999";
    return "white";
  }};
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    caret-color: white;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.3)' : 'white'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  height: 18px;
  line-height: 18px;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const comment = {
  empty: "Choose a unique username that represents you or your brand.",
  available: "Great choice! This username is available.",
  unavailable: "This username is already taken. Please try another one."
};

const Step7 = ({ setStep, setUser, user }) => {
  const dispatch = useDispatch();
  const [available, setAvailable] = useState(false);
  const { checkusername } = useSelector((state) => state.auth);

  const onChangeUserName = async (e) => {
    try {
      const username = e.target.value;
      setUser({ ...user, username });

      if (username.length > 0) {
        const res = await axios.post("/auth/checkUserName", { username });
        if (!res.data.data.exist) {
          dispatch(checkUserName(true));
          setAvailable(true);
        } else {
          dispatch(checkUserName(false));
          setAvailable(false);
        }
      } else {
        dispatch(checkUserName(false));
        setAvailable(false);
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.username !== undefined && (available || checkusername)) {
      setStep(12);
    }
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"What should we call you?"} speed={45} />
        <form onSubmit={handleSubmit}>
          <div className="mb-[68px] flex items-center">
            <Input
              placeholder="Add a username"
              value={user?.username || ''}
              onChange={onChangeUserName}
              isAvailable={user.username && (available || checkusername)}
            />
            <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
              {user.username && (available || checkusername) && (
                <Check className="h-full w-full mt-[2px]" />
              )}
            </div>
          </div>

          <ButtonContainer>
            <ActionButton
              disabled={!user.username || !(available || checkusername)}
              onClick={() => {
                if (user.username && (available || checkusername)) {
                  setStep(12);
                }
              }}
              className="mr-[43px]"
            >
              Next
            </ActionButton>
            <ActionButton
              onClick={() => {
                if (user.professionRole !== undefined) {
                  setStep(user.professionRole === "personal" ? 31 : 6);
                }
              }}
            >
              Go back
            </ActionButton>
          </ButtonContainer>
        </form>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          {!user?.username ? comment.empty : (available ? comment.available : comment.unavailable)}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step7;
