import styled from "styled-components";

const NextButton = styled.button`
  font-size: 12px;
  color: white;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  text-align: left;
  height: 48px;
`;

export {
    NextButton
}