import { createAsyncThunk } from '@reduxjs/toolkit';
import { postDataAPI } from '../../utils/fetchData';
import { setErrorAlert } from '../alert/alertSlice';

export const submitFeedbackThunk = createAsyncThunk(
  'feedback/submit',
  async ({ feedback, page }, { rejectWithValue, dispatch }) => {
    try {
      const token = localStorage.getItem('dizeeToken');
      if (!token) {
        dispatch(setErrorAlert('Authentication required'));
        return rejectWithValue('No token found');
      }

      const response = await postDataAPI('feedback/submit', { feedback, page }, token);
      
      if (response?.data?.status === 200) {
        return response.data;
      }
      
      throw new Error(response?.data?.message || 'Failed to submit feedback');
    } catch (error) {
      if (!navigator.onLine) {
        dispatch(setErrorAlert('Please check your internet connection'));
        return rejectWithValue('No internet connection');
      }
      
      dispatch(setErrorAlert(error?.response?.data?.message || 'Failed to submit feedback'));
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
); 