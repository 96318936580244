import React from 'react'
import more from '../../../assets/images/components/More.png';
import More from '../../../svg/More';
import { FILTERS } from '../../../constants/constants';


const Card = ({ title, desc, photo, saveType, setOverlayVisible, setSaveType, setLink, setTitleState, timeBackend, setTimerBackend, item }) => {
    return (
        <div className='w-full p-5 flex flex-row justify-between'>
            <div className='flex flex-row justify-start items-center'>
                <div className="relative h-[40px] w-[40px] rounded-full overflow-hidden">
                    <video 
                        src={photo} 
                        className="object-cover h-full w-full" 
                        style={{ 
                            filter: item.filter === "diz-overlay" ? 
                                   FILTERS.BW_FILTER : 
                                   (item.filter === "blue-overlay" ? "none" : item.filter),
                            transform: 'scaleX(-1)'
                        }} 
                        muted 
                        controls={false} 
                    />
                    
                    {item.filter === "blue-overlay" && (
                        <div 
                            className="absolute inset-0" 
                            style={{
                                backgroundColor: 'rgba(0, 0, 255, 0.5)',
                                mixBlendMode: 'multiply',
                                pointerEvents: 'none'
                            }}
                        />
                    )}
                    
                    {item.filter === "diz-overlay" && (
                        <div 
                            className="absolute inset-0" 
                            style={{
                                background: `radial-gradient(
                                    circle at center,
                                    rgba(255, 0, 0, 0.2) 0%,
                                    rgba(255, 0, 0, 0.35) 50%,
                                    rgba(255, 0, 0, 0.5) 100%
                                )`,
                                mixBlendMode: 'multiply',
                                pointerEvents: 'none'
                            }}
                        />
                    )}
                </div>
                <div className='flex flex-col items-start justify-start p-2'>
                    <p className='text-white' style={{ fontSize: '12px' }}>{title}</p>
                </div>
            </div>
            <div className='flex flex-row w-auto items-center'>
                {saveType === 'draft' && <p className='text-white' style={{ fontSize: '12px' }}>draft</p>}
                {saveType === 'schedule' && <p className='text-white' style={{ fontSize: '12px' }}>scheduled</p>}
                {saveType === 'publish' && <p className='text-white' style={{ fontSize: '12px' }}>preview</p>}
                <More className='h-[20px] w-[20px] cursor-pointer ml-7' onClick={() => {
                    setOverlayVisible(true)
                    setSaveType(saveType)
                    setLink(photo)
                    setTitleState(title)
                    setTimerBackend(timeBackend)
                }} />
            </div>
        </div>
    )
}

export default Card
