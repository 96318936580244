import { createSlice } from "@reduxjs/toolkit";
import {
    addCustomLinkSectionToSectionThunk, addCustomLinkToSectionThunk,
    addEventHeaderToSectionThunk, addEventToSectionThunk, addFormToSectionThunk,
    addMusicToSectionThunk, addPlaylistToSectionThunk, addProductToSectionThunk,
    addSocialFeedToSectionThunk, addVideoToSectionThunk, getAllSectionsThunk,
    getCustomLinkSectionThunk, getCustomLinkThunk, getEventThunk, getFormThunk,
    getMusicThunk, getPlaylistThunk, getProductThunk, getSocialFeedThunk, getVideoThunk,
    searchMusicForReleaseThunk, updateProductThunk, updateSocialfeedThunk,
    addItemToSectionThunk
} from "./addsectionThunk";

const initialState = {
    music: null,
    video: null,
    event: [],
    product: null,
    socialfeed: null,
    playlist: null,
    form: null,
    eventHeader: null,
    error: null,
    videoLoading: false,
    musicLoading: false,
    otherLoading: false,
    searchMusic: null,
    customlinksection: [],
    customlinksectionLoading: false,
    sectionItems: {}
};

const getAllSectionsCases = (builder) => {
    builder.addCase(getAllSectionsThunk.pending, (state) => {
        state.error = null;
    }).addCase(getAllSectionsThunk.fulfilled, (state, action) => {
        const data = action.payload?.data?.response || action.payload;
        console.log(`slice data::`, data);
        state.music = data?.music || [];
        state.video = data?.video || [];
        state.event = data?.event || [];
        state.eventHeader = data?.eventHeader || null;
        state.product = data?.product || [];
        state.playlist = data?.playlist || [];
        state.form = data?.form || [];
        state.socialfeed = data?.socialfeed || [];
        state.customlink = data?.customlink || [];
        state.other = data?.other || {};
    }).addCase(getAllSectionsThunk.rejected, (state, action) => {
        state.error = action.payload || 'Redux Cases Error';
    });
};
const searchForReleaseMusicCases = (builder) => {
    builder.addCase(searchMusicForReleaseThunk.pending, (state) => {
        state.musicLoading = true;
        state.error = null;
    }).addCase(searchMusicForReleaseThunk.fulfilled, (state, action) => {
        state.musicLoading = false;
        state.searchMusic = action.payload
    }).addCase(searchMusicForReleaseThunk.rejected, (state, action) => {
        state.musicLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const addMusicToSectionCases = (builder) => {
    builder.addCase(addMusicToSectionThunk.pending, (state) => {
        state.musicLoading = true;
        state.error = null;
    }).addCase(addMusicToSectionThunk.fulfilled, (state, action) => {
        state.musicLoading = false;
        // state.searchMusic = action.payload
    }).addCase(addMusicToSectionThunk.rejected, (state, action) => {
        state.musicLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getMusicCases = (builder) => {
    builder.addCase(getMusicThunk.pending, (state) => {
        // state.musicLoading = true;
        state.error = null;
    }).addCase(getMusicThunk.fulfilled, (state, action) => {
        // state.musicLoading = false;
        state.music = action.payload
    }).addCase(getMusicThunk.rejected, (state, action) => {
        // state.musicLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}
const addVideoToSectionCases = (builder) => {
    builder.addCase(addVideoToSectionThunk.pending, (state) => {
        state.videoLoading = true;
        state.error = null;
    }).addCase(addVideoToSectionThunk.fulfilled, (state, action) => {
        state.videoLoading = false;
        // state.searchMusic = action.payload
    }).addCase(addVideoToSectionThunk.rejected, (state, action) => {
        state.videoLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getVideoCases = (builder) => {
    builder.addCase(getVideoThunk.pending, (state) => {
        state.videoLoading = true;
        state.error = null;
    }).addCase(getVideoThunk.fulfilled, (state, action) => {
        state.videoLoading = false;
        state.video = action.payload
    }).addCase(getVideoThunk.rejected, (state, action) => {
        state.videoLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}
const addEventToSectionCases = (builder) => {
    builder.addCase(addEventToSectionThunk.pending, (state) => {
        state.eventLoading = true;
        state.error = null;
    }).addCase(addEventToSectionThunk.fulfilled, (state, action) => {
        state.eventLoading = false;
        // state.searchMusic = action.payload
    }).addCase(addEventToSectionThunk.rejected, (state, action) => {
        state.eventLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getEventCases = (builder) => {
    builder.addCase(getEventThunk.pending, (state) => {
        state.eventLoading = true;
        state.error = null;
    }).addCase(getEventThunk.fulfilled, (state, action) => {
        state.eventLoading = false;
        state.event = action.payload
    }).addCase(getEventThunk.rejected, (state, action) => {
        state.eventLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}
const addProductToSectionCases = (builder) => {
    builder.addCase(addProductToSectionThunk.pending, (state) => {
        state.productLoading = true;
        state.error = null;
    }).addCase(addProductToSectionThunk.fulfilled, (state, action) => {
        state.productLoading = false;
    }).addCase(addProductToSectionThunk.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getProductCases = (builder) => {
    builder.addCase(getProductThunk.pending, (state) => {
        state.productLoading = true;
        state.error = null;
    }).addCase(getProductThunk.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload
    }).addCase(getProductThunk.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}
const addPlaylistToSectionCases = (builder) => {
    builder.addCase(addPlaylistToSectionThunk.pending, (state) => {
        state.playlistLoading = true;
        state.error = null;
    }).addCase(addPlaylistToSectionThunk.fulfilled, (state, action) => {
        state.playlistLoading = false;
    }).addCase(addPlaylistToSectionThunk.rejected, (state, action) => {
        state.playlistLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getPlaylistCases = (builder) => {
    builder.addCase(getPlaylistThunk.pending, (state) => {
        state.playlistLoading = true;
        state.error = null;
    }).addCase(getPlaylistThunk.fulfilled, (state, action) => {
        state.playlistLoading = false;
        state.playlist = action.payload
    }).addCase(getPlaylistThunk.rejected, (state, action) => {
        state.playlistLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}
const addFormToSectionCases = (builder) => {
    builder.addCase(addFormToSectionThunk.pending, (state) => {
        state.formLoading = true;
        state.error = null;
    }).addCase(addFormToSectionThunk.fulfilled, (state, action) => {
        state.formLoading = false;
    }).addCase(addFormToSectionThunk.rejected, (state, action) => {
        state.formLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}
const getFormCases = (builder) => {
    builder.addCase(getFormThunk.pending, (state) => {
        state.formLoading = true;
        state.error = null;
    }).addCase(getFormThunk.fulfilled, (state, action) => {
        state.formLoading = false;
        state.form = action.payload
    }).addCase(getFormThunk.rejected, (state, action) => {
        state.formLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}


const addSocialFeedToSectionCases = (builder) => {
    builder.addCase(addSocialFeedToSectionThunk.pending, (state) => {
        state.socialfeedLoading = true;
        state.error = null;
    }).addCase(addSocialFeedToSectionThunk.fulfilled, (state, action) => {
        state.socialfeedLoading = false;
    }).addCase(addSocialFeedToSectionThunk.rejected, (state, action) => {
        state.socialfeedLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getSocialFeedCases = (builder) => {
    builder.addCase(getSocialFeedThunk.pending, (state) => {
        state.socialfeedLoading = true;
        state.error = null;
    }).addCase(getSocialFeedThunk.fulfilled, (state, action) => {
        state.socialfeedLoading = false;
        state.socialfeed = action.payload
    }).addCase(getSocialFeedThunk.rejected, (state, action) => {
        state.socialfeedLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}


const addCustomLinkSectionToSectionCases = (builder) => {
    builder.addCase(addCustomLinkSectionToSectionThunk.pending, (state) => {
        state.customlinksectionLoading = true;
        state.error = null;
    }).addCase(addCustomLinkSectionToSectionThunk.fulfilled, (state, action) => {
        state.customlinksectionLoading = false;
    }).addCase(addCustomLinkSectionToSectionThunk.rejected, (state, action) => {
        state.customlinksectionLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getCustomLinkSectionCases = (builder) => {
    builder.addCase(getCustomLinkSectionThunk.pending, (state) => {
        state.customlinksectionLoading = true;
        state.error = null;
    }).addCase(getCustomLinkSectionThunk.fulfilled, (state, action) => {
        state.customlinksectionLoading = false;
        state.customlinksection = action.payload
    }).addCase(getCustomLinkSectionThunk.rejected, (state, action) => {
        state.customlinksectionLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}


const addCustomLinkToSectionCases = (builder) => {
    builder.addCase(addCustomLinkToSectionThunk.pending, (state) => {
        state.customlinkLoading = true;
        state.error = null;
    }).addCase(addCustomLinkToSectionThunk.fulfilled, (state, action) => {
        state.customlinkLoading = false;
    }).addCase(addCustomLinkToSectionThunk.rejected, (state, action) => {
        state.customlinkLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const getCustomLinkCases = (builder) => {
    builder.addCase(getCustomLinkThunk.pending, (state) => {
        state.customlinkLoading = true;
        state.error = null;
    }).addCase(getCustomLinkThunk.fulfilled, (state, action) => {
        state.customlinkLoading = false;
        state.customlink = action.payload
    }).addCase(getCustomLinkThunk.rejected, (state, action) => {
        state.customlinkLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

//event
const addEventHeaderToSectionCases = (builder) => {
    builder.addCase(addEventHeaderToSectionThunk.pending, (state) => {
        state.otherLoading = true;
        state.error = null;
    }).addCase(addEventHeaderToSectionThunk.fulfilled, (state, action) => {
        state.otherLoading = false;
        state.other = { 
            ...state.other, 
            event: action.payload.data.response || action.payload.data 
        };
    }).addCase(addEventHeaderToSectionThunk.rejected, (state, action) => {
        state.otherLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const addsectionSlice = createSlice({
    name: 'addsection',
    initialState,
    reducers: {
        clearSearchMusic: (state) => {
            state.searchMusic = null
        },
        clearAddSection: (state) => {
            state.error = null;
            state.videoLoading = false;
            state.musicLoading = false;
            state.searchMusic = null;
        },
        clearEventHeader: (state) => {
            state.other.event = ''
        },
        setEventHeader: (state, action) => {
            state.other = {
                ...state.other,
                event: action.payload
            };
        },
        clearEventHeader: (state) => {
            state.other = {
                ...state.other,
                event: null
            };
        }
    },
    extraReducers: (builder) => {
        getAllSectionsCases(builder);
        searchForReleaseMusicCases(builder);
        addMusicToSectionCases(builder);
        getMusicCases(builder);
        addVideoToSectionCases(builder);
        getVideoCases(builder);
        addEventToSectionCases(builder);
        getEventCases(builder);
        addProductToSectionCases(builder);
        getProductCases(builder);
        addPlaylistToSectionCases(builder);
        getPlaylistCases(builder);
        addFormToSectionCases(builder);
        getFormCases(builder);
        addSocialFeedToSectionCases(builder);
        getSocialFeedCases(builder);
        addCustomLinkSectionToSectionCases(builder);
        getCustomLinkSectionCases(builder);
        addCustomLinkToSectionCases(builder);
        getCustomLinkCases(builder);
        addEventHeaderToSectionCases(builder);

        // Add product update cases
        builder
            .addCase(updateProductThunk.fulfilled, (state, action) => {
                if (action.payload?.product) {
                    state.product = action.payload.product;
                }
                state.loading = false;
            })
            .addCase(updateProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProductThunk.rejected, (state) => {
                state.loading = false;
            });

        // Update socialfeed cases
        builder
            .addCase(updateSocialfeedThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSocialfeedThunk.fulfilled, (state, action) => {
                state.loading = false;
                // Update the socialfeed state with the new data
                if (action.payload?.data?.socialfeed) {
                    state.socialfeed = action.payload.data.socialfeed;
                }
            })
            .addCase(updateSocialfeedThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        // Add item to section cases
        builder.addCase(addItemToSectionThunk.fulfilled, (state, action) => {
            const { sectionId, item } = action.payload;
            if (!state.sectionItems[sectionId]) {
                state.sectionItems[sectionId] = [];
            }
            state.sectionItems[sectionId].push(item);
            state.loading = false;
        });

        builder.addCase(addItemToSectionThunk.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addItemToSectionThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export default addsectionSlice.reducer;

export const { clearSearchMusic, clearAddSection, clearEventHeader, setEventHeader } = addsectionSlice.actions;
