import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteSocialFeedThunk, updateSocialfeedThunk, addSocialFeedToSectionThunk, getSocialFeedThunk } from '../../../store/addsection/addsectionThunk';
import { clearAlerts, setErrorAlert } from '../../../store/alert/alertSlice';
import { Calendar } from '@nayojs/react-datetime-picker';
import DizeeTimeInput from '../../DizeeComponent/DizeeTimeInput';
import DizeeModal from '../../DizeeComponent/DizeeModal';
import DizeeButton from '../../DizeeComponent/DizeeButton';
import DizeeSelect from '../../DizeeComponent/DizeeSelect';
import { uploadImage } from '../../../utils/upload';
import { removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';

export default function SocialFeedOverlay({ setOverlayVisible, previousUrl, itemForBackend, linkForBackend, setLink, setImagePreview, setSelectedImage, setPreviousUrl }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();

    const [showEditor, setShowEditor] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');
    const [preview, setPreview] = useState(itemForBackend?.avatar || null);
    const [selectedFile, setSelectedFile] = useState(null);

    const [editForm, setEditForm] = useState({
        link: itemForBackend?.links?.[0]?.url || '',
        title: itemForBackend?.title || ''
    });

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    const selectDateHandler = (date) => {
        setDate(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
    }

    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    }

    const handlePublishClick = () => {
        const payload = {
            ...itemForBackend,
            publish: {
                value: 'publish',
                time: null
            }
        };
        dispatch(clearAlerts());
        dispatch(addSocialFeedToSectionThunk({ 
            token, 
            payload,
            previousUrl: linkForBackend
        })).then((res) => {
            if (res.payload.status === 200) {
                dispatch(getSocialFeedThunk({ token }));
                setOverlayVisible(false);
            }
        });
    };

    const handleDraftClick = () => {
        const payload = {
            ...itemForBackend,
            publish: {
                value: 'draft',
                time: null
            }
        };
        dispatch(clearAlerts());
        dispatch(addSocialFeedToSectionThunk({ 
            token, 
            payload,
            previousUrl: linkForBackend
        })).then((res) => {
            if (res.payload.status === 200) {
                dispatch(getSocialFeedThunk({ token }));
                setOverlayVisible(false);
            }
        });
    };

    const handleScheduleClick = () => {
        dispatch(clearAlerts());
        setShowCalendar(true);
    };

    const onSaveSchedule = () => {
        if (date.length < 1 || time.length < 5) {
            return;
        }
        const payload = {
            ...itemForBackend,
            publish: {
                value: 'schedule',
                time: `${date} ${time} ${pm ? 'PM' : 'AM'}`
            }
        };
        dispatch(addSocialFeedToSectionThunk({ 
            token, 
            payload,
            previousUrl: linkForBackend
        })).then((res) => {
            if (res.payload.status === 200) {
                dispatch(getSocialFeedThunk({ token }));
                setShowCalendar(false);
                setOverlayVisible(false);
            }
        });
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const onSaveEdit = async () => {
        dispatch(clearAlerts());
        
        try {
            let avatarUrl;
            if (selectedFile) {
                avatarUrl = await uploadImage(selectedFile);
                if (!avatarUrl) return;
            }

            const updates = {
                link: editForm.link,
                title: editForm.title,
                avatar: avatarUrl || itemForBackend.avatar
            };

            const result = await dispatch(updateSocialfeedThunk({ 
                previousUrl: linkForBackend,
                saveType: "edit",
                updates,
                token 
            })).unwrap();

            if (result?.status === 200) {
                await dispatch(getSocialFeedThunk({ token }));
                setShowEditor(false);
                setOverlayVisible(false);
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            dispatch(setErrorAlert('Error saving changes'));
        }
    };

    const handleEditClick = () => {
        setShowEditor(true);
    };

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(deleteSocialFeedThunk({ token: token, link: previousUrl }));
        setOverlayVisible(false);
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            {itemForBackend.publish.value === "draft" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : itemForBackend.publish.value === "schedule" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Edit Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDraftClick()}>Move to Draft</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDraftClick()}>Move to Draft</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>}

            {showEditor && (
                <DizeeModal isOverlayVisible={showEditor} setOverlayVisible={setShowEditor}>
                    <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                        <div className='px-4 my-[50px] flex w-full justify-between'>
                            <p className='cursor-pointer text-white'>Edit details</p>
                            <div className='cursor-pointer flex gap-x-4'>
                                <DizeeButton label={'Confirm'} onClick={onSaveEdit} />
                                <DizeeButton label={'Go back'} onClick={() => setShowEditor(false)} />
                            </div>
                        </div>
                        <div className='px-4 my-[80px] flex flex-col w-full gap-y-6'>
                            <div className='flex flex-row items-center justify-between mb-6'>
                                <p className="cursor-pointer text-white" onClick={() => document.getElementById("cover-image").click()}>
                                    Change cover image
                                </p>
                                {preview && (
                                    <img 
                                        src={removeUploadsFromUrl(preview)}
                                        alt="Social Feed Cover"
                                        className="object-cover h-[50px] w-[50px] rounded-[3px] ml-4"
                                        onClick={() => document.getElementById("cover-image").click()}
                                    />
                                )}
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                id="cover-image"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                            <input
                                value={editForm.title}
                                onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                                className='bg-transparent border-none focus:outline-none w-full text-white'
                                placeholder="Title"
                            />
                            <input
                                value={editForm.link}
                                onChange={(e) => setEditForm(prev => ({ ...prev, link: e.target.value }))}
                                className='bg-transparent border-none focus:outline-none w-full text-white'
                                placeholder="Link"
                            />
                        </div>
                    </div>
                </DizeeModal>
            )}

            {showCalendar &&
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <Calendar selectDateHandler={selectDateHandler} calendarStyles={{
                            containerStyles: { backgroundColor: '#000' },
                            dateStyles: { color: '#fff' },
                            selectedDateStyles: { backgroundColor: '#000', color: '#ff2' },
                            headerStyles: { background: '#000' },
                            headerTitleTextStyles: { color: '#fff' },
                            headerTitleStyles: { color: '#fff' },
                        }} />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime='11:12'
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            }
        </div>
    )
}
