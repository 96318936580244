import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postDataAPI } from '../../utils/fetchData';
import { setLoader, setErrorAlert } from '../alert/alertSlice';

export const updateBandsintownSettings = createAsyncThunk(
    'bandsintown/updateSettings',
    async (data, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const { token, artistName, apiKey } = data;
            
            const response = await postDataAPI(
                'user/bandsintown-settings',
                { 
                    artistName: artistName?.trim() || '',
                    apiKey: apiKey?.trim() || '',
                    autoSync: true
                },
                token
            );
            
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const syncBandsintownEvents = createAsyncThunk(
    'bandsintown/syncEvents',
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/bandsintown-sync', {}, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'Failed to sync events'));
            return thunkAPI.rejectWithValue(error?.response?.data || 'An error occurred');
        }
    }
);

const bandsintownSlice = createSlice({
    name: 'bandsintown',
    initialState: {
        settings: JSON.parse(localStorage.getItem('bandsintownSettings')) || null,
        loading: false,
        error: null,
        lastSync: null
    },
    reducers: {
        clearSettings: (state) => {
            state.settings = null;
            state.lastSync = null;
        },
        updateLocalSettings: (state, action) => {
            state.settings = {
                ...(state.settings || {}),
                ...action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateBandsintownSettings.fulfilled, (state, action) => {
                state.settings = action.payload.settings;
                state.loading = false;
                state.error = null;
            })
            .addCase(updateBandsintownSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(syncBandsintownEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.lastSync = new Date().toISOString();
                state.error = null;
            })
            .addCase(syncBandsintownEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'An error occurred';
            });
    }
});

export const { clearSettings, updateLocalSettings } = bandsintownSlice.actions;
export default bandsintownSlice.reducer; 