import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { postDataAPI } from "../../utils/fetchData";
import { useNavigate } from "react-router";
import { clearAlerts, setErrorAlert } from "../../store/alert/alertSlice";
import { useDispatch } from "react-redux";
import { protect } from "../../store/auth/authSlice";
import dizeeLogo from "../../assets/images/dizee logo.svg";
import TypeWriter from "../../components/OnBoard/Typewriter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
`;

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  font-size: 12px;
  padding: 0;
  color: white;
  height: 18px;
  line-height: 1.5;
  margin: 2px 0;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    caret-color: white;
  }
`;

const NextButton = styled.button`
  font-size: 12px;
  color: white;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.5 : 1};
  text-align: left;
  margin-top: 40px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
  
  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: underline;
  }
`;

export default function Protect() {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("Component rendering");

  const handleButtonClick = () => {
    console.log("Button clicked");
    handleSubmit();
  };

  const handlePasswordChange = (e) => {
    console.log("Password changing");
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    console.log("Submit function called");
    
    if (!password) {
      console.log("No password entered");
      return;
    }

    try {
      console.log("Making API call");
      const res = await postDataAPI("auth/protect", { password });
      console.log("API response received:", res);

      if (res?.data?.status === 200) {
        dispatch(protect());
        navigate("/");
      } else {
        dispatch(setErrorAlert(res?.data?.message));
      }
    } catch (err) {
      console.error("API error:", err);
      dispatch(setErrorAlert(err?.response?.data?.message));
    }
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text="Enter password" speed={45} />
        <div onChange={() => console.log("Input container change")}>
          <Input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onClick={() => console.log("Input clicked")}
            placeholder="••••••••"
            autoFocus
          />
        </div>
        <div onClick={() => console.log("Button container clicked")}>
          <NextButton 
            onClick={handleButtonClick}
            disabled={!password}
          >
            Next
          </NextButton>
        </div>
        
      </FormSection>
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          By clicking "Next" you agree to the DIZEE TOS{'\n'}
          and Privacy Policy.
        </FooterText>
      </Footer>
    </Container>
  );
}
