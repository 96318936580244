import React from 'react';
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import Plus from '../../../svg/Plus';
import Instagram from '../../../svg/Social/Instagram';
import Youtube from '../../../svg/Social/Youtube';
import Tiktok from '../../../svg/Social/Tiktok';
import Facebook from '../../../svg/Social/Facebook';
import Twitter from '../../../svg/Social/TwitterX';
import Snapchat from '../../../svg/Social/Snapchat';
import Threads from '../../../svg/Social/Thread';
import Douyin from '../../../svg/Social/Douyin';
import WeChat from '../../../svg/Social/Wechat';
import CopyLink from '../../../svg/CopyLink';

const platformMapping = {
    Instagram: <Instagram className='h-[14px] w-[14px]' />,
    Facebook: <Facebook className='h-[14px] w-[14px]' />,
    TikTok: <Tiktok className='h-[14px] w-[14px]' />,
    Twitter: <Twitter className='h-[14px] w-[14px]' />,
    YouTube: <Youtube className='h-[14px] w-[14px]' />,
    Snapchat: <Snapchat className='h-[14px] w-[14px]' />,
    Threads: <Threads className='h-[14px] w-[14px]' />,
    Douyin: <Douyin className='h-[16px] w-[16px] ml-[6px]' />,
    WeChat: <WeChat className='h-[16px] w-[16px]' />,
    igreel: <Instagram className='h-[14px] w-[14px]' />,
    iggrid: <Instagram className='h-[14px] w-[14px]' />,
    ytshorts: <Youtube className='h-[14px] w-[14px]' />,
    tt: <Tiktok className='h-[14px] w-[14px]' />,
    Link: <CopyLink className='h-[14px] w-[14px]' />
};

export default function QueuedSocialFeedCard({ socialFeed, onDelete }) {
    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                <img
                    src={removeUploadsFromUrl(socialFeed?.avatar)}
                    alt='Social Feed Cover'
                    className='object-cover h-[50px] w-[50px] rounded-[3px]'
                />
                <div className='flex flex-col items-start justify-start h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                        {maxLength(socialFeed?.title || '', 30)}
                    </p>
                    <div className='text-gray-400'>
                        {platformMapping[socialFeed.platform]}
                    </div>
                </div>
            </div>
            <button onClick={onDelete}>
                <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer text-white' />
            </button>
        </div>
    );
} 