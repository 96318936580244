import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../components/DizeeComponent/DixeeInput2';
import ImageSelectionCard from '../../../../components/ImageSelectionCard';
import { clearAlerts, setErrorAlert } from '../../../../store/alert/alertSlice';
import { getCustomLinkThunk, addCustomLinkToSectionThunk } from '../../../../store/addsection/addsectionThunk';
import AddedCustomLink from '../../../../components/AddSection/Customlink/AddedCustomLink';
import QueuedCustomLinkCard from '../../../../components/AddSection/Customlink/QueuedCustomLinkCard';
import { SECTION_TEMPLATES } from '../../../../constants/sectionTemplates';
import { uploadImage } from '../../../../utils/upload';
import { updateSection } from '../../../../store/customlinkData/customlinkDataSlice';

export default function AddCustomlink() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('dizeeToken');
    
    const [entries, setEntries] = useState([]);
    const customlink = useSelector((state) => state.addsection.customlink);
    const section = useSelector((state) => state.customlinkdata.section);
    const selectedTemplate = useSelector((state) => state.customlinkdata.selectedTemplate);
    
    const [formData, setFormData] = useState({
        title: '',
        image: null,
        link: '',
        description: '',
        date: '',
        location: '',
        price: ''
    });

    // Check if current form is completely filled based on template requirements
    const isFormComplete = () => {
        const template = SECTION_TEMPLATES[selectedTemplate];
        if (!template) return false;
        return template.fields.every(field => !!formData[field]);
    };

    // Check if form is empty
    const isFormEmpty = () => {
        return Object.values(formData).every(value => !value);
    };

    // Add these button visibility conditions after the existing form checks
    const showQueue = isFormComplete();
    const showNext = entries.length > 0 || isFormComplete();
    const showClear = !isFormEmpty() && !isFormComplete();

    const handleClear = () => {
        setFormData({
            title: '',
            image: null,
            link: '',
            description: '',
            date: '',
            location: '',
            price: ''
        });
        dispatch(updateSection(null));
    };

    const handleDeleteFromQueue = (index) => {
        setEntries(prev => prev.filter((_, i) => i !== index));
    };

    const handleAddToQueue = () => {
        if (!isFormComplete()) {
            dispatch(setErrorAlert('Please fill all required fields'));
            return;
        }

        setEntries(prev => [...prev, { ...formData, section }]);
        handleClear();
    };

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = await uploadImage(file);
                handleInputChange('image', imageUrl);
            } catch (error) {
                dispatch(setErrorAlert('Failed to upload image'));
            }
        }
    };

    const handleAddCustomLink = async () => {
        dispatch(clearAlerts());
        
        if (entries.length === 0 && !isFormComplete()) {
            dispatch(setErrorAlert('Please add at least one link'));
            return;
        }

        try {
            // Add current form to queue if complete
            if (isFormComplete()) {
                await handleAddToQueue();
            }

            // Process all entries
            for (const entry of entries) {
                const payload = {
                    sectionTitle: section,
                    ...entry
                };
                await dispatch(addCustomLinkToSectionThunk({ token, payload }));
            }
            
            // Clear queue and form
            setEntries([]);
            handleClear();
            dispatch(getCustomLinkThunk({ token }));
        } catch (error) {
            dispatch(setErrorAlert('Failed to add custom links'));
        }
    };

    const renderTemplateInputs = () => {
        const template = SECTION_TEMPLATES[selectedTemplate];
        if (!template) return null;

        return (
            <div className="w-full px-4">
                {template.fields.map((field) => (
                    <div key={field} className="mb-4">
                        {field === 'image' ? (
                            <ImageSelectionCard
                                txt="Add cover image"
                                onImageChange={handleImageChange}
                                imagePreview={formData.image}
                                resetImage={() => handleInputChange('image', null)}
                            />
                        ) : field === 'date' ? (
                            <DizeeInput2
                                label="Date"
                                type="date"
                                value={formData.date}
                                onChange={(e) => handleInputChange('date', e.target.value)}
                                className="dizee-input w-full text-white"
                            />
                        ) : field === 'description' ? (
                            <DizeeInput2
                                label="Description"
                                placeholder="Enter description"
                                value={formData.description}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                                className="dizee-input w-full text-white"
                                multiline
                                rows={3}
                            />
                        ) : (
                            <DizeeInput2
                                label={field.charAt(0).toUpperCase() + field.slice(1)}
                                placeholder={`Enter ${field}`}
                                value={formData[field]}
                                onChange={(e) => handleInputChange(field, e.target.value)}
                                className="dizee-input w-full text-white"
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <span className='text-white'>Add custom link</span>
                    <div className='flex gap-[30px]'>
                        {section && (
                            <>
                                {showQueue && (
                                    <button 
                                        onClick={handleAddToQueue}
                                        className='text-white cursor-pointer'
                                    >
                                        Queue
                                    </button>
                                )}
                                {showNext && (
                                    <button 
                                        onClick={handleAddCustomLink}
                                        className='text-white cursor-pointer'
                                    >
                                        Next
                                    </button>
                                )}
                                {showClear && (
                                    <button 
                                        onClick={handleClear}
                                        className='text-white cursor-pointer'
                                    >
                                        Clear
                                    </button>
                                )}
                            </>
                        )}
                        <button 
                            onClick={() => navigate('/add-section')}
                            className='text-white cursor-pointer'
                        >
                            Go back
                        </button>
                    </div>
                </div>

                {/* Section Selection */}
                <div className='w-full px-4 py-3 flex justify-between items-center'>
                    <span className='text-white text-[12px]'>
                        {section ? `Section: ${section}` : 'Select a section'}
                    </span>
                    <button 
                        onClick={() => navigate('/add-section/create-section')}
                        className='text-white text-[12px]'
                    >
                        {section ? 'Change' : 'Select'}
                    </button>
                </div>

                {/* Only show inputs and queue if section is selected */}
                {section && (
                    <>
                        {/* Template-specific inputs */}
                        <div className="w-full space-y-[50px]">
                            {renderTemplateInputs()}
                        </div>

                        {/* Queue Section */}
                        {entries.length > 0 && (
                            <div className="w-full mt-[50px]">
                                <div className='px-4 pb-[20px]'>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <p>In Queue</p>
                                    </div>
                                </div>
                                {entries.map((item, index) => (
                                    <QueuedCustomLinkCard
                                        key={index}
                                        item={item}
                                        onDelete={() => handleDeleteFromQueue(index)}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}

                {/* Added Custom Links - Always visible */}
                {customlink?.length > 0 && (
                    <div className="flex flex-col w-full mt-[50px]">
                        <div className='px-4 pb-[20px]'>
                            <p className='text-white text-[12px]'>Added Custom links</p>
                        </div>
                        {customlink.map((item, index) => (
                            <AddedCustomLink 
                                key={index} 
                                item={item}
                            />
                        ))}
                    </div>
                )}
            </div>
        </LayoutHeader>
    );
}
