import React from 'react'
import More from '../../svg/More';
import { getContrastColor } from '../DizeeComponent/DixeeInput2';

export const Socialfeed = (props) => {
    const { userArticle, socialfeed, setOverlay, title } = props;

    const publishedSocialfeed = socialfeed?.filter(s => s?.publish?.value === 'publish');

    const handleClick = (url) => {
        if (url) {
            const fullUrl = url.startsWith('http') ? url : `https://${url}`;
            window.open(fullUrl, '_blank');
        }
    };

    return (
        <>
            {publishedSocialfeed?.length > 0 && (
                <div className='w-full px-4 relative mb-[40px]'>
                    <div className="text-[#FDFAFA] mb-4 flex w-full flex-row justify-between items-center">
                        <div>
                            <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                                {title || 'SOCIAL POST'}
                            </p>
                        </div>
                        <More 
                            className='h-[20px] w-[20px] cursor-pointer' 
                            color={getContrastColor(userArticle?.background) ? `#${getContrastColor(userArticle?.background)}` : '#ffffff'} 
                            onClick={() => setOverlay(true)} 
                        />
                    </div>
                    <div className="w-full overflow-x-auto flex flex-row gap-x-4 pb-2">
                        {publishedSocialfeed.map((item, index) => (
                            <img 
                                key={index} 
                                src={item?.avatar} 
                                alt="imageurl" 
                                className="h-[190px] w-[150px] cursor-pointer rounded-[3px] object-cover" 
                                onClick={() => handleClick(item?.links?.[0]?.url)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}