import { useEffect, useState } from "react";
import DizeeInput from "../../../components/DizeeComponent/DizeeInput";
import { verifyPhone } from "../../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../../store/alert/alertSlice";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import { NextButton } from "../../../components/DizeeComponent/NextButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  display: flex;
  align-items: center;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const Step0 = ({ setStep, setUser, user }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const { sentOTP } = useSelector((state) => state.auth);
  const { email } = useSelector((state) => state.user);
  const [isDisable, setDisable] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (sentOTP) setStep(1);
  }, [sentOTP, setStep]);

  useEffect(() => {
    if (phone !== "") setDisable(false);
    else setDisable(true);
  }, [phone]);

  const onChangePhone = (e) => {
    setPhone(e.target.value);
    setUser({ ...user, phone: e.target.value });
  };

  const sendVerifyCode = (e) => {
    e.preventDefault();
    dispatch(clearAlerts());
    dispatch(verifyPhone(phone));
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"Enter your phone number"} speed={45} />
        <form onSubmit={sendVerifyCode}>
          <DizeeInput
            placeholder={"Mobile phone #"}
            value={phone}
            onChange={onChangePhone}
          />
          <ButtonContainer show={phone.length > 0}>
            <NextButton
              disabled={isDisable}
              type="submit"
            >
              Send verification code
            </NextButton>
          </ButtonContainer>
        </form>
      </FormSection>
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          By clicking "Next" you agree to the DIZEE TOS{'\n'}
          and Privacy Policy.
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step0;
