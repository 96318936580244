import React from 'react'
import { getContrastColor, maxLength } from '../DizeeComponent/DixeeInput2'
import { getAccentLightStyle, getAccentStyle, haxToRgbLight } from '../../constants/constants'
import { postDataAPI } from '../../utils/fetchData'
import { useParams } from 'react-router'
import Spotify from '../../svg/Social/Spotify'
import AppleMusic from '../../svg/Social/AppleMusic'
import AmazonMusic from '../../svg/MusicPlatform/AmazonMusic'
import SoundCloud from '../../svg/MusicPlatform/SoundCloud'
import Tidal from '../../svg/MusicPlatform/Tidal'
import MusicDataPage from '../../pages/Main/Pages/Music/MusicDataPage'
import Play from '../../svg/Play'

const componentMapping = {
    spotify: (props) => <Spotify {...props} />,
    apple_music: (props) => <AppleMusic {...props} />,
    amazon: (props) => <AmazonMusic {...props} />,
    soundcloud: (props) => <SoundCloud {...props} />,
    tidal: (props) => <Tidal {...props} />
};
export default function Music(props) {
    let { userArticle, music, setMusicOverlay, title } = props
    const { domain: dynamicVar } = useParams();
    const [showModal, setShowModal] = React.useState(false);
    const [id, setId] = React.useState(null);

    const contrastColor = userArticle?.accent;
    const accentStyle = getAccentStyle(contrastColor);
    const accentLightStyle = getAccentLightStyle(haxToRgbLight(contrastColor));
    const handleClickMusic = async (link, item) => {
        let url = link?.url;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
        try {
            await postDataAPI('analytics/create-music-analytics', { 
                link: url, 
                domain: dynamicVar, 
                title: item?.title, 
                source: link?.source 
            }, null);
        } catch (err) {
            console.log(err);
        }
    }

    console.log('Music component data:', music);

    const handleClick = (item) => {
        if (item?.playback) {
            setShowModal(true);
            setId(item?.id);
        } else if (item?.links?.length === 1 && !item?.links[0]?.source) {
            handleClickMusic(item.links[0], item);
        } else {
            setShowModal(true);
            setId(item?.id);
        }
    };

    return (
        <>
            {music?.length > 0 && music.filter(m => m?.publish?.value === 'publish').length > 0 &&

                <div className="text-[#FDFAFA] my-4 lg:w-[900px] sm:w-[639px] w-[350px] flex px-4 flex-row justify-between items-start">
                    <div className='w-full'>
                        <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>{title || `MUSIC`}</p>
                    </div>
                    {/* <More className='h-[20px] w-[20px] cursor-pointer' color={getContrastColor(userArticle?.background) ? `#${getContrastColor(userArticle?.background)}` : '#ffffff'} onClick={() => setMusicOverlay(true)} /> */}
                    {/* <img src={more} alt='sp' className='h-[3px] w-[12px] cursor-pointer' onClick={() => setOverlayVisible(true)} /> */}
                </div>
            }
            {music?.length > 0 && (
                <div className="lg:w-[900px] sm:w-[639px] w-[350px] mb-[50px] overflow-x-auto px-4 flex flex-row gap-x-4">
                    {music?.filter(m => m?.publish?.value === 'publish').map((item, index) => (
                        <div key={index} className="cursor-pointer" onClick={() => handleClick(item)}>
                            <div className="relative">
                                <img
                                    src={item?.avatar}
                                    alt={item?.title || 'music cover'}
                                    className="h-[130px] object-cover w-[130px] rounded-[3px]"
                                />
                                {item?.playback && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                                        <Play className="h-8 w-8 text-white opacity-80" />
                                    </div>
                                )}
                            </div>
                            <div className="w-full pt-3 flex flex-col items-center gap-y-1">
                                <p
                                    className="text-white w-full text-[12px] text-start"
                                    style={accentStyle}
                                >
                                    {maxLength(item?.title, 15)}
                                </p>
                                <p
                                    className="text-gray-300 w-full text-[12px] text-start"
                                    style={accentLightStyle}
                                >
                                    {maxLength(item?.artist || item?.description || '', 15)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showModal &&
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">

                    <MusicDataPage domain={dynamicVar} id={id} setShowModal={setShowModal} />
                </div>
            }
        </>
    )
}
