import React, { useEffect, useState } from 'react'
import CusCard from '../../components/CusCard';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_articles_thunk, update_user_article_thunk } from '../../store/user/userThunk';
import { clearAlerts } from '../../store/alert/alertSlice';
import { articlDataDefault, articlDataDefaultArray, getAccentStyle, SOCIAL_PLATFORMS_SVG } from '../../constants/constants';
import { useNavigate, useParams } from 'react-router';
import LayoutHeader from '../../layout/LayoutHeader'
import { getContrastColor, removeUploadsFromUrl } from '../../components/DizeeComponent/DixeeInput2'
import { getAllSectionsThunk } from '../../store/addsection/addsectionThunk'
import Music from '../../components/PublicArticle/Music';
import Event from '../../components/PublicArticle/Event'
import Product from '../../components/PublicArticle/Product'
import Video from '../../components/PublicArticle/Video'
import Playlist from '../../components/PublicArticle/Playlist'
import Form from '../../components/PublicArticle/Form'
import Plus from '../../svg/Plus'
import More from '../../svg/More'
import PhotoOverlay from '../../components/Main/ArticleEdit/PhotoOverlay'
import { updatePhotoOverlay, updateSocialApiCall } from '../../store/user/userSlice'
import { Socialfeed } from '../../components/Main/Socialfeed'
import { get_domain_articles_thunk, getDomainAllSectionsThunk, getDomainDataForFocusThunk } from '../../store/domain/domainThunk';
import BrandPage from './BrandPage';
import SingleMusic from './Music/SingleMusic';
import MultipleMusic from './Music/MultipleMusic';
import SingleProduct from './Product/SingleProduct';
import MultipleProduct from './Product/MultipleProduct';
import MultipleEvent from './Event/MultipleEvent';
import SingleEvent from './Event/SingleEvent';
import SingleNewsletter from './Newsletter/SingleNewsletter';
import MultipleNewsletterPage from './Newsletter/MultipleNewsletter';

export default function Domain() {
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const dispatch = useDispatch();

    const { domain: dynamicVar } = useParams();
    const { focuspage: focuspageVar } = useParams();

    const focusData = useSelector((state) => state.domain.focusData);
    const musicArticleData = useSelector((state) => state.domain?.focusData?.music);
    const eventArticleData = useSelector((state) => state.domain?.focusData?.event);
    const newsletterArticleData = useSelector((state) => state.domain?.focusData?.newsletter);
    const productArticleData = useSelector((state) => state.domain?.focusData?.product);
    const [focusLoader, setFocusLoader] = useState(false)


    const userArticle = useSelector((state) => state.domain.userArticle);
    const videoMessage = useSelector((state) => state.domain.videoMessage);
    const music = useSelector((state) => state.domain.music);
    const event = useSelector((state) => state.domain.event);
    const product = useSelector((state) => state.domain.product);
    const video = useSelector((state) => state.domain.video);
    const playlist = useSelector((state) => state.domain.playlist);
    const form = useSelector((state) => state.domain.form);
    const socialfeed = useSelector((state) => state.domain.socialfeed);
    const [articlDataDefault, setArticlDataDefault] = useState(articlDataDefaultArray);
    const [isFirst, setIsFirst] = useState(true);

    let getPublishVideoMessage = videoMessage?.length > 0 && videoMessage?.filter((item) => item.saveType === 'post-on-page');
    const navigation = useNavigate()


    const [currentPage, setCurrentPage] = useState(null);
    const [type, setType] = useState(null);
    const [page, setPage] = useState(true);
    const [extension, setExtension] = useState(null);
    const [background, setBackground] = useState(userArticle?.background);
    const [textColor, setTextColor] = useState(userArticle?.textColor);
    const [publish, setPublish] = useState(null)


    useEffect(() => {
        console.log(userArticle?.publish)
        if (userArticle?.publish?.production) {
            setPublish(userArticle?.publish)
        }
        else {
            setPublish({
                ...userArticle?.publish,
                production: false,
            })

        }
    }, [userArticle?.publish])

    useEffect(() => {
        if (dynamicVar) {
            dispatch(get_domain_articles_thunk({ domain: dynamicVar }));
            dispatch(getDomainAllSectionsThunk({ domain: dynamicVar }));
            dispatch(getDomainDataForFocusThunk({ domain: dynamicVar }));
        }
        // else {
        //     dispatch(updateSocialApiCall(false));
        // }
    }, [dispatch, dynamicVar]);


    useEffect(() => {
        if (dynamicVar && !focuspageVar) {
            setCurrentPage('brand')
        }
    }, [])

    useEffect(() => {

        if (focuspageVar && isFirst) {
            musicArticleData?.length > 0 && musicArticleData?.map((item) => {
                if (item?.extension === focuspageVar) {
                    setCurrentPage('music')
                    setType(item?.type)
                    setPage(item?.page)
                    setExtension(item?.extension)
                    setBackground(item?.background)
                    setTextColor(item?.textColor)
                    setIsFirst(false)
                    // setPublish(item?.publish)
                    return

                }
            })
            eventArticleData?.length > 0 && eventArticleData?.map((item) => {
                if (item?.extension === focuspageVar) {

                    setCurrentPage('event')
                    setType(item?.type)
                    setPage(item?.page)
                    setExtension(item?.extension)
                    setBackground(item?.background)
                    setTextColor(item?.textColor)
                    setIsFirst(false)
                    // setPublish(item?.publish)

                    return
                }
            })
            newsletterArticleData?.length > 0 && newsletterArticleData?.map((item) => {
                if (item?.extension === focuspageVar) {
                    setCurrentPage('newsletter')
                    setType(item?.type)
                    setPage(item?.page)
                    setExtension(item?.extension)
                    setBackground(item?.background)
                    setTextColor(item?.textColor)
                    setIsFirst(false)
                    // setPublish(item?.publish)

                    return

                }
            })
            productArticleData?.length > 0 && productArticleData?.map((item) => {
                if (item?.extension === focuspageVar) {

                    setCurrentPage('product')
                    setType(item?.type)
                    setPage(item?.page)
                    setExtension(item?.extension)
                    setBackground(item?.background)
                    setTextColor(item?.textColor)
                    setIsFirst(false)
                    // setPublish(item?.publish)

                    return
                }
            })


        }

    }, [dispatch, focuspageVar, page, extension, musicArticleData, eventArticleData, newsletterArticleData, productArticleData, isFirst]);

    useEffect(() => {
        if (userArticle?.articlDataDefault) {
            setArticlDataDefault(userArticle?.articlDataDefault)
        }
    }, [getPublishVideoMessage]);

    return (
        <div className='h-auto bg-black w-full'>
            {!currentPage ? <div></div> :
                <>
                    {publish?.production &&
                        <div className='w-full flex justify-center bg-black'>
                            <div className="w-[420px] relative">
                                <BrandPage />
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

