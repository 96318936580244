import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Check from '../../../../svg/Check'
import LayoutHeader from '../../../../layout/LayoutHeader'
import { useNavigate } from 'react-router'
import { postDataAPI } from '../../../../utils/fetchData'
import { clearAlerts, setErrorAlert } from '../../../../store/alert/alertSlice'
import { changePlanStripePaymentThunk } from '../../../../store/auth/authThunk'

export default function Subscription() {
    const token = localStorage.getItem('dizeeToken')
    const user = useSelector(state => state?.user?.userArticle)
    const [planActive, setPlanActive] = useState(user?.plan || 0)
    const [selectedPlan, setSelectedPlan] = useState(0)
    const [planType, setPlanType] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchApi = async () => {
            try {
                const res = await postDataAPI('stripe/get_payment_history_auth', {}, token);
                if (res.data.data.response) {
                    const activePlan = res.data.data.response.plan;
                    setPlanActive(activePlan);
                }
            } catch (error) {
                console.log('error', error)
                dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'))
            }
        }
        fetchApi()
    }, [])

    const handleStripePayment = async () => {
        dispatch(clearAlerts());
        if (selectedPlan === planActive) {
            dispatch(setErrorAlert('You are already using this plan'))
            return
        }
        dispatch(changePlanStripePaymentThunk({ plan: selectedPlan, token }));
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Change subscription</p>
                    <div className='flex gap-[20px]'>
                        <button 
                            className='text-white cursor-pointer' 
                            onClick={handleStripePayment}
                        >
                            Confirm
                        </button>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex justify-between items-center">
                            <button
                                onClick={() => setSelectedPlan(0)}
                                className="flex items-center text-white">
                                Basic plan
                                <div className="ml-2 h-[12px] w-[18px]">
                                    {selectedPlan === 0 && 
                                        <Check className="h-full w-full mt-[2px]" />
                                    }
                                </div>
                            </button>
                            <button 
                                onClick={() => setSelectedPlan(0)}
                                className="text-white cursor-pointer"
                            >
                                ${planType === 0 ? "5" : "50"}
                            </button>
                        </div>

                        <div className="py-6 flex justify-between items-center">
                            <button
                                onClick={() => setSelectedPlan(1)}
                                className="flex items-center text-white">
                                Pro plan
                                <div className="ml-2 h-[12px] w-[18px]">
                                    {selectedPlan === 1 && 
                                        <Check className="h-full w-full mt-[2px]" />
                                    }
                                </div>
                            </button>
                            <button 
                                onClick={() => setSelectedPlan(1)}
                                className="text-white cursor-pointer"
                            >
                                ${planType === 0 ? "20" : "200"}
                            </button>
                        </div>

                        <div className="py-6 flex justify-between items-center">
                            <button
                                onClick={() => setPlanType(planType === 0 ? 1 : 0)}
                                className="text-white cursor-pointer"
                            >
                                {planType === 0 ? "Switch to yearly" : "Switch to monthly"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}
