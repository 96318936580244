import React, { useState } from 'react'
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import { useDispatch } from 'react-redux';
import More from '../../../svg/More';
import PreviewOverlay from '../../PreviewOverlay';


export default function AddedEvent({ item, setLinkForBackend, setOverlayVisible, setItemForBackend }) {
    const dispatch = useDispatch();
    const [previewOverlayOpen, setPreviewOverlayOpen] = useState(false);

    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
            {/* <div className='flex flex-row justify-start items-center gap-x-[11px]'> */}


            {/* <img src={removeUploadsFromUrl(item?.avatar)} alt='alt' className='object-cover h-[50px]  w-[50px] rounded-[3px]  ' /> */}



            <div className='flex flex-col items-start justify-start  h-full py-2'>
                <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>{maxLength(item?.venue, 50)}</p>
                <p className='text-[white]' style={{ fontSize: '12px' }}>
                    {maxLength(item?.date, 50)}
                </p>
            </div>
            <div className='flex'>
                <div 
                    className='text-white cursor-pointer mr-7'
                    onClick={() => setPreviewOverlayOpen(true)}
                >
                    {item?.publish?.value === 'publish' ? 'preview' : item?.publish?.value}
                </div>
                <More className='h-[20px] w-[20px] cursor-pointer' onClick={() => {
                    setItemForBackend(item)
                    setOverlayVisible(true)
                    setLinkForBackend(item?.links[0]?.url)
                }} />
            </div>

            {previewOverlayOpen && (
                <PreviewOverlay 
                    isOpen={previewOverlayOpen}
                    onClose={() => setPreviewOverlayOpen(false)}
                    selectedSection="event"
                    item={item}
                    type="event"
                />
            )}
        </div>
    )
}
