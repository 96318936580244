import { createAsyncThunk } from "@reduxjs/toolkit";
import { setErrorAlert, setLoader } from "../alert/alertSlice";
import { getDataAPI, postDataAPI } from "../../utils/fetchData";
import { setVideoMessage } from "../user/userSlice";
import axios from "axios";
import { setEventHeader } from './addsectionSlice';


export const getAllSectionsThunk = createAsyncThunk(
    "/user/get-all-sections",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await getDataAPI('user/get-all-sections', tempData.token);
            console.log('Raw API response:', response);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);


export const searchMusicForReleaseThunk = createAsyncThunk(
    "/user/search-music-for-release",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('user/search-music-for-release', { search: tempData.search }, tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
);

export const addMusicToSectionThunk = createAsyncThunk(
    "/user/add-music-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-music-to-section', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getMusicThunk = createAsyncThunk(
    "/user/get-music",
    async (tempData, thunkAPI) => {
        try {
            // thunkAPI.dispatch(setLoader(true));
            const response = await getDataAPI('user/get-music', tempData.token);
            // thunkAPI.dispatch(setLoader(false));
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteMusicThunk = createAsyncThunk(
    "/user/delete-music",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-music', { avatar: tempData.avatar },
                tempData.token);
            thunkAPI.dispatch(getMusicThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateMusicThunk = createAsyncThunk(
    "/user/update-music",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-music', {
                time: tempData.timer,
                avatar: tempData.avatarForBackend,
                saveType: tempData.saveType,
                playback: tempData.playback,
                selectedLinks: tempData.selectedLinks,
                link: tempData.link,
                updates: tempData.updates
            }, tempData.token);
            thunkAPI.dispatch(getMusicThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const addVideoToSectionThunk = createAsyncThunk(
    "/user/add-video-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-video-to-section', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getVideoThunk = createAsyncThunk(
    "/user/get-video",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI('user/get-video', tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)


export const deleteVideoThunk = createAsyncThunk(
    "/user/delete-video",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-video', { avatar: tempData.avatar }, tempData.token);
            thunkAPI.dispatch(getVideoThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateVideoThunk = createAsyncThunk(
    "/user/update-video",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-video', { time: tempData.timer, avatar: tempData.avatarForBackend, saveType: tempData.saveType, link: tempData.link }, tempData.token);
            thunkAPI.dispatch(getVideoThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false))
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred')
        }
    }
)


// event
export const addEventToSectionThunk = createAsyncThunk(
    "/user/add-event-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-event-to-section', { payload: tempData.payload, previousUrl: tempData.previousUrl }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const addEventHeaderToSectionThunk = createAsyncThunk(
    "/user/add-event-header-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            
            console.log('Thunk: Sending payload:', tempData.payload);

            const response = await postDataAPI(
                'user/add-event-header-to-section', 
                tempData.payload,
                tempData.token
            );
            
            console.log('Thunk: API Response:', response.data);
            
            // Dispatch the setEventHeader action directly
            thunkAPI.dispatch(setEventHeader(tempData.payload.url));
            
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);

export const deleteEventHeaderToSectionThunk = createAsyncThunk(
    "/user/delete-event-header-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-event-header-to-section', {}, tempData.token)
            return response.data
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getEventThunk = createAsyncThunk(
    "/user/get-event",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI('user/get-event', tempData.token);
            console.log('Get event response:', response);
            // Return the correct data structure
            return response?.data?.data?.response || response?.data?.response || [];
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteEventThunk = createAsyncThunk(
    "/user/delete-event",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-event', { link: tempData.link }, tempData.token);
            thunkAPI.dispatch(getEventThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateEventThunk = createAsyncThunk(
    "/user/update-event",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-event', {
                time: tempData.timer,
                link: tempData.linkForBackend,
                saveType: tempData.saveType,
                updates: tempData.updates
            }, tempData.token);
            thunkAPI.dispatch(getEventThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)


// product
export const addProductToSectionThunk = createAsyncThunk(
    "/user/add-product-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-product-to-section', { payload: tempData.payload, previousUrl: tempData.previousUrl }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getProductThunk = createAsyncThunk(
    "/user/get-product",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI('user/get-product', tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteProductThunk = createAsyncThunk(
    "/user/delete-product",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-product', { link: tempData.link }, tempData.token);
            thunkAPI.dispatch(getProductThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)
export const deleteBulkProductThunk = createAsyncThunk(
    "/user/delete-product",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-bulk-product', { link: tempData.link, title: tempData.title }, tempData.token);
            thunkAPI.dispatch(getProductThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateProductThunk = createAsyncThunk(
    'addsection/updateProduct',
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-product', {
                time: tempData.timer,
                link: tempData.link,
                saveType: tempData.saveType,
                updates: tempData.updates
            }, tempData.token);
            thunkAPI.dispatch(getProductThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);

// playlist
export const addPlaylistToSectionThunk = createAsyncThunk(
    "/user/add-playlist-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-playlist-to-section', { payload: tempData.payload, previousUrl: tempData.previousUrl }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getPlaylistThunk = createAsyncThunk(
    "/user/get-playlist",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI('user/get-playlist', tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deletePlaylistThunk = createAsyncThunk(
    "/user/delete-playlist",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-playlist', { avatar: tempData.avatar }, tempData.token);
            thunkAPI.dispatch(getPlaylistThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updatePlaylistThunk = createAsyncThunk(
    "/user/update-playlist",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-playlist', { time: tempData.timer, link: tempData.avatarForBackend, saveType: tempData.saveType }, tempData.token);
            thunkAPI.dispatch(getPlaylistThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

// form
export const addFormToSectionThunk = createAsyncThunk(
    "/user/add-form-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-form-to-section', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getFormThunk = createAsyncThunk(
    "/user/get-form",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI(`user/get-form`, tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteFormThunk = createAsyncThunk(
    "/user/delete-form",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-form', { title: tempData.title, type: tempData.type }, tempData.token);
            thunkAPI.dispatch(getFormThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateFormThunk = createAsyncThunk(
    "/user/update-form",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-form', { time: tempData.timer, link: tempData.linkForBackend, item: tempData.itemForBackend, saveType: tempData.saveType }, tempData.token);
            thunkAPI.dispatch(getFormThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)


// socialfeed
export const addSocialFeedToSectionThunk = createAsyncThunk(
    "/user/add-socialfeed-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-socialfeed-to-section', { payload: tempData.payload, previousUrl: tempData.previousUrl }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getSocialFeedThunk = createAsyncThunk(
    "/user/get-socialfeed",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI(`user/get-socialfeed`, tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteSocialFeedThunk = createAsyncThunk(
    "/user/delete-socialfeed",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-socialfeed', { link: tempData.link }, tempData.token);
            thunkAPI.dispatch(getSocialFeedThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateSocialfeedThunk = createAsyncThunk(
    'addsection/updateSocialfeed',
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            
            // Log the update data
            console.log('Updating socialfeed with:', tempData);

            // Make the update request
            const response = await postDataAPI(
                'user/update-socialfeed', 
                {
                    previousUrl: tempData.previousUrl,
                    saveType: tempData.saveType,
                    updates: tempData.updates
                }, 
                tempData.token
            );

            // If successful, fetch the latest data
            if (response?.status === 200) {
                const updatedData = await getDataAPI('user/get-socialfeed', tempData.token);
                thunkAPI.dispatch(setLoader(false));
                return {
                    status: 200,
                    data: {
                        socialfeed: updatedData?.data?.data?.response
                    }
                };
            }

            thunkAPI.dispatch(setLoader(false));
            return response;
        } catch (error) {
            console.error('Error in updateSocialfeedThunk:', error);
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);


// custom link
export const addCustomLinkToSectionThunk = createAsyncThunk(
    "/user/add-customlink-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-customlink-to-section', { payload: tempData.payload, previousUrl: tempData.previousUrl }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getCustomLinkThunk = createAsyncThunk(
    "/user/get-customlink",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI('user/get-customlink', tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteCustomLinkThunk = createAsyncThunk(
    "/user/delete-customlink",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-customlink', { link: tempData.link }, tempData.token);
            thunkAPI.dispatch(getCustomLinkThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateCustomLinkThunk = createAsyncThunk(
    "/user/update-customlink",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/update-customlink', { time: tempData.timer, link: tempData.linkForBackend, saveType: tempData.saveType }, tempData.token);
            thunkAPI.dispatch(getCustomLinkThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

// custom link section
export const addCustomLinkSectionToSectionThunk = createAsyncThunk(
    "/user/add-customlinksection-to-section",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/add-customlinksection-to-section', { payload: tempData.payload, previousUrl: tempData.previousUrl }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const getCustomLinkSectionThunk = createAsyncThunk(
    "/user/get-customlinksection",
    async (tempData, thunkAPI) => {
        try {
            const response = await getDataAPI('user/get-customlinksection', tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

export const deleteCustomLinkSectionThunk = createAsyncThunk(
    "/user/delete-customlinksection",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('user/delete-customlinksection', { title: tempData.title }, tempData.token);
            thunkAPI.dispatch(getCustomLinkSectionThunk({ token: tempData.token }));
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const addItemToSectionThunk = createAsyncThunk(
    'addsection/addItemToSection',
    async ({ token, payload, sectionId }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/section/custom/${sectionId}/item`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to add item to section');
            }

            const data = await response.json();
            return { sectionId, item: data };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);