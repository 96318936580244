import React from 'react';
import { maxLength } from '../../../components/DizeeComponent/DixeeInput2';
import Plus from '../../../svg/Plus';

export default function QueuedEventCard({ event, onDelete }) {
    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
            <div className='flex flex-col items-start justify-start h-full py-2'>
                <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                    {maxLength(event?.venue, 30)}
                </p>
                <p className='text-[#696974]' style={{ fontSize: '12px' }}>
                    {maxLength(event?.date, 30)}
                </p>
                <p className='text-[#696974]' style={{ fontSize: '12px' }}>
                    {maxLength(event?.location, 30)}
                </p>
            </div>
            <button onClick={onDelete}>
                <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer' />
            </button>
        </div>
    );
} 