import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContrastColor, removeUploadsFromUrl, maxLength } from './DizeeComponent/DixeeInput2';
import { getAccentStyle, getAccentLightStyle, haxToRgbLight, SOCIAL_PLATFORMS_SVG, articlDataDefaultArray, getAccentColor } from '../constants/constants';
import { get_domain_articles_thunk, getDomainAllSectionsThunk, getDomainDataForFocusThunk } from '../store/domain/domainThunk';
import Music from './PublicArticle/Music';
import Event from './PublicArticle/Event';
import Product from './PublicArticle/Product';
import Video from './PublicArticle/Video';
import Playlist from './PublicArticle/Playlist';
import Form from './PublicArticle/Form';
import { Socialfeed } from './PublicArticle/Socialfeed';
import Play from '../svg/Play';
import CrossArrow from '../svg/CrossArrow';

const SECTION_ORDER = [
    'music',
    'video',
    'event',
    'product',
    'playlist',
    'socialfeed'
];

export default function PreviewOverlay({ isOpen, onClose, selectedSection, item, type }) {
    const dispatch = useDispatch();
    const sectionRefs = useRef({});
    const userData = useSelector((state) => state.user.userArticle);
    const {
        music,
        event,
        product,
        video,
        playlist,
        form,
        socialfeed,
        articlDataDefault,
        userArticle
    } = useSelector((state) => state.domain);

    useEffect(() => {
        if (isOpen && userData?.domain) {
            dispatch(get_domain_articles_thunk({ domain: userData.domain }));
            dispatch(getDomainAllSectionsThunk({ domain: userData.domain }));
            dispatch(getDomainDataForFocusThunk({ domain: userData.domain }));
        }
    }, [dispatch, isOpen, userData?.domain]);

    useEffect(() => {
        articlDataDefaultArray.forEach(({ type }) => {
            sectionRefs.current[type] = React.createRef();
        });
    }, []);

    useEffect(() => {
        if (isOpen && selectedSection && sectionRefs.current[selectedSection]) {
            setTimeout(() => {
                sectionRefs.current[selectedSection].current?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'center'
                });
            }, 100);
        }
    }, [isOpen, selectedSection]);

    useEffect(() => {
        if (articlDataDefault?.length > 0) {
            const orderedSections = {};
            articlDataDefault.forEach(section => {
                orderedSections[section.type] = section.position;
            });
            
            // Create ordered array based on positions
            const orderedTypes = Object.entries(orderedSections)
                .sort(([,a], [,b]) => a - b)
                .map(([type]) => type);
                
            // Update refs order
            sectionRefs.current = orderedTypes.reduce((acc, type) => ({
                ...acc,
                [type]: sectionRefs.current[type] || React.createRef()
            }), {});
        }
    }, [articlDataDefault]);

    const sections = {
        music: music?.length > 0 && (
            <>
                {music?.length > 0 && music.filter(m => m?.publish?.value === 'publish').length > 0 && (
                    <div className="text-[#FDFAFA] my-4 w-[350px] sm:w-[390px] flex px-4 flex-row justify-between items-start">
                        <div className='w-full'>
                            <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                                {articlDataDefault?.find(item => item.type === 'music')?.title || 'MUSIC'}
                            </p>
                        </div>
                    </div>
                )}
                {music?.length > 0 && (
                    <div className="w-[350px] sm:w-[390px] mb-[50px] overflow-x-auto px-4 flex flex-row gap-x-4">
                        {music?.filter(m => m?.publish?.value === 'publish').map((item, index) => (
                            <div key={index} className="pointer-events-none">
                                <div className="relative">
                                    <img
                                        src={item?.avatar}
                                        alt={item?.title || 'music cover'}
                                        className="h-[130px] w-[130px] object-cover rounded-[3px]"
                                    />
                                    {item?.playback && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                                            <Play className="h-8 w-8 text-white opacity-80" />
                                        </div>
                                    )}
                                </div>
                                <div className="w-[130px] pt-3 flex flex-col items-start gap-y-1">
                                    <p
                                        className="text-white w-full text-[12px] text-start"
                                        style={getAccentStyle(userArticle?.accent)}
                                    >
                                        {maxLength(item?.title, 15)}
                                    </p>
                                    <p
                                        className="text-gray-300 w-full text-[12px] text-start"
                                        style={getAccentLightStyle(haxToRgbLight(userArticle?.accent))}
                                    >
                                        {maxLength(item?.artist || item?.description || '', 15)}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </>
        ),
        event: event?.length > 0 && (
            <div className="pointer-events-none w-[350px] sm:w-[390px] mb-[60px]">
                <div className="text-[#FDFAFA] my-4 flex w-full px-4 flex-row justify-between items-center">
                    <div>
                        <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                            {articlDataDefault?.find(item => item.type === 'event')?.title || 'EVENT'}
                        </p>
                    </div>
                </div>
                
                <div className='w-full flex flex-col justify-center items-center gap-y-[16px]'>
                    {event
                        ?.filter(e => e?.publish?.value === 'publish')
                        .sort((a, b) => a.position - b.position)
                        .map((item, index) => (
                        <React.Fragment key={index}>
                            <div className='flex w-full items-center justify-between px-4'>
                                <p className='text-white' style={getAccentStyle(userArticle?.accent)}>
                                    {item?.date?.split(',')[1]}
                                </p>
                                <p className='text-white' style={getAccentStyle(userArticle?.accent)}>
                                    {item?.date?.split(',')[0]}
                                </p>
                                <p className='text-white' style={getAccentStyle(userArticle?.accent)}>
                                    {maxLength(item?.location, 10)}
                                </p>
                                <p className='text-white' style={getAccentStyle(userArticle?.accent)}>
                                    {maxLength(item?.venue, 10)}
                                </p>
                                <CrossArrow color={userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF'} />
                            </div>
                            <div className='w-full mx-4'>
                                <div className='mx-4' style={{ 
                                    borderBottom: `.3px solid ${userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF'}`
                                }}></div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        ),
        product: product?.length > 0 && (
            <div className="pointer-events-none w-[350px] sm:w-[390px] mb-[60px]">
                <div className="text-[#FDFAFA] my-4 flex w-full px-4 flex-row justify-between items-center">
                    <div>
                        <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                            {articlDataDefault?.find(item => item.type === 'product')?.title || 'PRODUCT'}
                        </p>
                    </div>
                </div>
                
                <div className='w-full overflow-x-auto relative hide-scrollbar'>
                    <div className='flex flex-row gap-x-4 px-4'>
                        {product?.filter(p => p?.publish?.value === 'publish').map((item, index) => (
                            <div key={index} className="flex flex-shrink-0 gap-x-4">
                                <img 
                                    src={item?.image}
                                    alt={item?.productName || 'product'} 
                                    className='h-[100px] w-[100px] object-cover rounded-[3px]' 
                                />
                                <div className='flex flex-col justify-start gap-y-1'>
                                    <p className='text-[12px] text-white' style={getAccentStyle(userArticle?.accent)}>
                                        {maxLength(item?.productName, 30)}
                                    </p>
                                    <p className='text-[12px] text-gray-300' style={getAccentLightStyle(haxToRgbLight(userArticle?.accent))}>
                                        ${item?.price}
                                    </p>
                                    <p className='text-[12px] text-gray-300' style={getAccentLightStyle(haxToRgbLight(userArticle?.accent))}>
                                        {maxLength(item?.description, 80)}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        video: video?.length > 0 && (
            <div className="pointer-events-none w-full">
                <div className="text-[#FDFAFA] my-4 flex w-full flex-row justify-between items-center px-4">
                    <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                        {articlDataDefault?.find(item => item.type === 'video')?.title || 'VIDEO'}
                    </p>
                </div>
                
                <div className='w-full overflow-x-auto relative'>
                    <div className='flex'>
                        {video?.filter(v => v?.publish?.value === 'publish').map((item, index) => {
                            let videoId = null;
                            if (item?.type === 0 && item?.links?.[0]?.url) {
                                try {
                                    const urlObj = new URL(item.links[0].url);
                                    videoId = urlObj.searchParams.get("v");
                                } catch (error) {
                                    console.error("Invalid URL:", item.links[0].url);
                                }
                            }
                            
                            return (
                                <div key={index} className='flex-shrink-0 flex w-full px-4'>
                                    <div className="relative w-[320px] sm:w-[360px]">
                                        {item?.type === 0 ? (
                                            <iframe
                                                className='object-cover h-[460px] w-full'
                                                src={`https://www.youtube.com/embed/${videoId}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded YouTube Video"
                                            />
                                        ) : (
                                            <video
                                                src={item?.url}
                                                className="object-cover h-[460px] w-full"
                                                muted
                                                controls={false}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        ),
        playlist: playlist?.length > 0 && (
            <div className="pointer-events-none w-[350px] sm:w-[390px] mb-[60px]">
                <div className="text-[#FDFAFA] my-4 flex w-full px-4 flex-row justify-between items-center">
                    <div>
                        <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                            {articlDataDefault?.find(item => item.type === 'playlist')?.title || 'PLAYLIST'}
                        </p>
                    </div>
                </div>
                
                <div className='w-full flex flex-col justify-center items-center gap-y-[16px]'>
                    {playlist?.filter(p => p?.publish?.value === 'publish').map((item, index) => (
                        <React.Fragment key={index}>
                            <div className='flex w-full items-center justify-between px-4'>
                                <p className='text-white' style={getAccentStyle(userArticle?.accent)}>
                                    {maxLength(item?.title, 30)}
                                </p>
                                <CrossArrow color={userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF'} />
                            </div>
                            <div className='w-full mx-4'>
                                <div className='mx-4' style={{ 
                                    borderBottom: `.3px solid ${userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF'}`
                                }}></div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        ),
        form: form?.length > 0 && (
            <div className="pointer-events-none">
                <Form 
                    userArticle={userArticle} 
                    form={form} 
                    title={articlDataDefault?.find(item => item.type === 'form')?.title} 
                />
            </div>
        ),
        socialfeed: socialfeed?.length > 0 && (
            <div className="pointer-events-none w-[350px] sm:w-[390px] mb-[60px]">
                <div className="text-[#FDFAFA] my-4 flex w-full px-4 flex-row justify-between items-center">
                    <div>
                        <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                            {articlDataDefault?.find(item => item.type === 'socialfeed')?.title || 'SOCIAL POSTS'}
                        </p>
                    </div>
                </div>
                <div className="px-4">
                    <div className='w-full overflow-x-auto flex flex-row gap-x-4'>
                        {socialfeed?.filter(s => s?.publish?.value === 'publish').map((item, index) => (
                            <img 
                                key={index} 
                                src={item?.avatar} 
                                alt="social post" 
                                className="h-[190px] w-[150px] rounded-[3px] object-cover" 
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    };

    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target.id === 'preview-overlay') {
            onClose();
        }
    };

    return (
        <div
            id="preview-overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex flex-col justify-center items-center z-50 gap-0"
            onClick={handleOverlayClick}
        >
            {/* Close Button - Positioned above the preview viewport */}
            <div className="mb-4 w-[350px] sm:w-[390px] flex justify-end">
                <button 
                    onClick={onClose}
                    className="text-[12px] text-white hover:text-white/80 transition-colors"
                >
                    Close
                </button>
            </div>

            <div className="relative w-[350px] sm:w-[390px] h-[85vh] overflow-y-auto bg-black rounded-lg hide-scrollbar">
                {/* Main Content */}
                <div className={`w-full pb-[16px] flex flex-col justify-start items-center relative rounded-[20px]`}
                    style={{ 
                        backgroundColor: userData?.background ? `#${userData.background}` : '#000000',
                        filter: `grayscale(${userData?.grayscale}%) contrast(${userData?.contrast}%) saturate(${userData?.saturation})`
                    }}>
                    {/* Hero Section */}
                    <div className="w-full h-[490px] sm:h-[700px] flex relative rounded-t-[20px] rounded-br-[30px] overflow-hidden">
                        <img 
                            src={removeUploadsFromUrl(userData?.avatar)} 
                            alt="profile" 
                            className="w-full h-full object-cover" 
                        />
                        <div
                            className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end pl-4"
                            style={{
                                backgroundImage: `linear-gradient(to top, black, transparent)`,
                            }}
                        >
                            <div className='flex flex-col justify-center pb-2'>
                                <div className='flex items-center'>
                                    <p className='text-xl text-white font-bold' style={{ color: '#FBFBFD' }}>{userData?.username}</p>
                                </div>
                                <div className='flex flex-row mt-2 space-x-2'>
                                    {userData?.social && Object.keys(userData.social).map(key => {
                                        const platformData = SOCIAL_PLATFORMS_SVG.find(p => p.value === key);
                                        if (platformData?.Component) {
                                            const SocialIcon = platformData.Component;
                                            return (
                                                <div key={key} className='h-[15px] w-[15px]'>
                                                    <SocialIcon className="w-[15px] h-[15px]" color="#FBFBFD" />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add spacing after hero section */}
                    <div className="h-[40px]"></div>

                    {/* Scrollable Content Container */}
                    <div className="w-full flex flex-col items-center">
                        {SECTION_ORDER
                            .map(sectionType => {
                                const sectionData = articlDataDefault?.find(item => item.type === sectionType);
                                return {
                                    type: sectionType,
                                    content: sections[sectionType],
                                    position: sectionData?.position ?? SECTION_ORDER.indexOf(sectionType)
                                };
                            })
                            .filter(section => section.content) // Only show sections that have content
                            .map(({ type, content }) => (
                                <div 
                                    key={type} 
                                    ref={sectionRefs.current[type]}
                                    className="w-full"
                                >
                                    <div className="w-[350px] sm:w-[390px] overflow-x-auto hide-scrollbar">
                                        <div className="inline-block min-w-0">
                                            {content}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    {/* Footer */}
                    <div className='w-[350px] sm:w-[390px] flex flex-col justify-center items-start gap-y-[16px] px-4 mt-[60px] text-[12px]' style={getAccentStyle(userData?.accent)}>
                        <div className='space-y-[15px]'>
                            <div className='flex flex-row gap-x-[10px] items-center'>
                                <p className='uppercase font-bold'>{userData?.username}</p>
                                <p className='pb-[2px]'>x</p>
                                <p className='uppercase text-[11px] tracking-[3px]'>DIZEE</p>
                            </div>
                            <p>Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 