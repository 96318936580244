import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../components/DizeeComponent/DixeeInput2';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert } from '../../../../store/alert/alertSlice';
import { addCustomLinkSectionToSectionThunk, getCustomLinkSectionThunk } from '../../../../store/addsection/addsectionThunk';
import AddedCustomSection from '../../../../components/AddSection/Customlink/AddedCustomSection';
import { updateSection, updateTemplate } from '../../../../store/customlinkData/customlinkDataSlice';
import TemplateSelector from '../../../../components/AddSection/Customlink/TemplateSelector';

export default function CreateNewSection() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('dizeeToken');
    
    const [title, setTitle] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [step, setStep] = useState('select-template');
    const customlinksection = useSelector((state) => state.addsection.customlinksection);
    const section = useSelector((state) => state.customlinkdata.section);
    const [showTemplateSelector, setShowTemplateSelector] = useState(false);

    useEffect(() => {
        dispatch(clearAlerts());
        dispatch(getCustomLinkSectionThunk({ token }));
    }, [dispatch, token]);

    const filterLink = (title) => {
        return customlinksection?.some(m => m.title === title);
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        dispatch(updateTemplate(template.id));
        setShowTemplateSelector(false);
    };

    const handleAddCustomLinkSection = async () => {
        const isExistLink = filterLink(title);
        dispatch(clearAlerts());

        if (isExistLink) {
            dispatch(setErrorAlert('Title already exists'));
            return;
        }

        if (!title) {
            dispatch(setErrorAlert('Please fill all the fields'));
            return;
        }

        if (!selectedTemplate) {
            dispatch(setErrorAlert('Please select a section style'));
            return;
        }

        let payload = { 
            type: 0, 
            title: title,
            template: selectedTemplate.id
        };

        try {
            await dispatch(addCustomLinkSectionToSectionThunk({ token, payload }));
            await dispatch(getCustomLinkSectionThunk({ token }));
            setTitle('');
            setSelectedTemplate(null);
        } catch (error) {
            dispatch(setErrorAlert('Failed to create section'));
        }
    };

    const handleConfirmSelection = () => {
        if (!section) {
            dispatch(setErrorAlert('Please select a section'));
            return;
        }
        navigate('/add-section/add-custom-link');
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <span className='text-white'>
                        {showTemplateSelector ? 'Select section style' : 'Create new section'}
                    </span>
                    {showTemplateSelector ? (
                        <div className='flex gap-[30px]'>
                            <button 
                                onClick={() => {
                                    if (!selectedTemplate) {
                                        dispatch(setErrorAlert('Please select a style'));
                                        return;
                                    }
                                    setShowTemplateSelector(false);
                                }}
                                className='text-white text-[12px] cursor-pointer'
                            >
                                Confirm
                            </button>
                        </div>
                    ) : (
                        <button 
                            onClick={handleAddCustomLinkSection}
                            className='text-white text-[12px] cursor-pointer'
                        >
                            Add
                        </button>
                    )}
                </div>

                {showTemplateSelector ? (
                    <TemplateSelector 
                        onSelect={setSelectedTemplate}
                        selectedTemplate={selectedTemplate}
                    />
                ) : (
                    <div className="flex flex-col items-center justify-center w-full gap-y-[50px]">
                        <div className='w-full'>
                            <div className='p-4 flex w-full justify-between items-center'>
                                <DizeeInput2
                                    label="Section title"
                                    placeholder="Add section title"
                                    className="dizee-input w-full text-white"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className='px-4 flex w-full justify-between items-center cursor-pointer'>
                                <span className='text-white text-[12px]'>
                                    {selectedTemplate?.label || 'Select style'}
                                </span>
                                <button 
                                    onClick={() => setShowTemplateSelector(true)}
                                    className='text-white text-[12px]'
                                >
                                    {selectedTemplate ? 'Change' : 'Select'}
                                </button>
                            </div>
                        </div>

                        {customlinksection?.length > 0 && (
                            <div className="flex flex-col w-full mt-6">
                                <div className='px-4 pb-[20px] flex justify-between items-center'>
                                    <p className='text-white text-[12px]'>Select existing section</p>
                                    <div className='flex gap-[30px]'>
                                        {section && (
                                            <button 
                                                onClick={handleConfirmSelection}
                                                className='text-white text-[12px] cursor-pointer'
                                            >
                                                Confirm
                                            </button>
                                        )}
                                        <button 
                                            onClick={() => navigate('/add-section')}
                                            className='text-white text-[12px] cursor-pointer'
                                        >
                                            Go back
                                        </button>
                                    </div>
                                </div>
                                {customlinksection?.map((item, index) => (
                                    <AddedCustomSection
                                        key={index}
                                        item={item}
                                        isSelected={section === item.title}
                                        onSelect={() => {
                                            dispatch(updateSection(item.title));
                                            dispatch(updateTemplate(item.template));
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </LayoutHeader>
    );
}