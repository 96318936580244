const reservedDomains = {
  "man@man.com": "man",
  "yotdmusic@gmail.com": "yotd",
  "woman@woman.com": "woman",
  "brand@email.com": "brand_official",
  "premium_domain": "conio",
  "special_brand": null,
  "sexmans": null
};

export default reservedDomains; 