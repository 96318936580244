import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorAlert } from "../../../store/alert/alertSlice";
import { checkDomain } from "../../../store/auth/authThunk";
import { postDataAPI } from "../../../utils/fetchData";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import styled from "styled-components";
import axios from "../../../utils/axios";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import Check from "../../../svg/Check";
import DizeeCloseIcon from "../../../components/DizeeComponent/DizeeCloseIcon";
import { NextButton } from "../../../components/DizeeComponent/NextButton";
import reservedDomains from '../../../assets/config/reservedDomains';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const DomainInputContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;
  height: 18px;
  margin: 2px 0;
  line-height: 18px;
`;

const DomainInput = styled.input.attrs(props => ({
  'data-available': props.isAvailable
}))`
  background: transparent;
  border: none;
  font-size: 12px;
  width: 80px;
  color: ${props => props.isAvailable ? '#90EE90' : 'white'};
  outline: none;
  padding: 0;
  margin-left: 4px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const comment = {
  empty: "Secure your personalized Dizee link to make it easier for your audience to find and engage with you.",
  available: "Great choice! This domain is available and ready for you to use.",
  unavailable: "This domain is already taken. Please try another one.",
  tooShort: "That's too short, please try to add 2 characters or more to find your domain",
  reserved: "We've reserved your domain, but if you want to change it feel free"
};

 const Step4 = ({ setStep, setUser, user }) => {
  const dispatch = useDispatch();
  const [available, setAvailable] = useState(false);
  const { checkdomain } = useSelector((state) => state.auth);

  // Add useEffect for scroll control
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  // Create a list of all reserved domains
  const reservedDomainsList = Object.values(reservedDomains);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        await postDataAPI("stripe/approve_subscription", {
          email: user?.email,
        });
      } catch (error) {
        // dispatch(setErrorAlert(error?.response?.data?.message || "An error occurred"));
      }
    };
    fetchApi();
  }, [dispatch, user?.email]);

  useEffect(() => {
    if (user?.email && reservedDomains[user.email]) {
      const reservedDomain = reservedDomains[user.email];
      setUser({ ...user, domain: reservedDomain });
      
      const checkReservedDomain = async () => {
        try {
          const res = await axios.post("/auth/checkdomain", {
            domain: reservedDomain,
          });
          if (res.data.data.success && !res.data.data.exist) {
            dispatch(checkDomain(true));
            setAvailable(true);
          }
        } catch (e) {
          console.log(e);
        }
      };
      
      checkReservedDomain();
    }
  }, [user?.email]);

  const onChangeDomain = async (e) => {
    try {
      // Allow alphanumeric characters and dashes
      const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
      
      // Prevent consecutive dashes and ensure dashes aren't at start/end
      const cleanedValue = sanitizedValue
        .replace(/-{2,}/g, '-')
        .replace(/^-/, '')
        .replace(/-$/, '');
      
      const truncatedValue = cleanedValue.slice(0, 20);
      
      setUser({ ...user, domain: truncatedValue });

      if (truncatedValue.length >= 2) {
        // Check if domain is directly reserved (without email)
        const isDirectlyReserved = Object.values(reservedDomains).includes(truncatedValue);
        
        // Check if domain is reserved for a specific email
        const isReservedForEmail = Object.entries(reservedDomains).some(([email, domain]) => 
          domain === truncatedValue && email !== null && email !== user?.email
        );

        if (isDirectlyReserved || isReservedForEmail) {
          dispatch(checkDomain(false));
          setAvailable(false);
          return;
        }

        // Proceed with normal domain check
        const res = await axios.post("/auth/checkdomain", {
          domain: truncatedValue,
        });
        if (res.data.data.success && !res.data.data.exist) {
          dispatch(checkDomain(true));
          setAvailable(true);
        } else {
          dispatch(checkDomain(false));
          setAvailable(false);
        }
      } else {
        dispatch(checkDomain(false));
        setAvailable(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (user.domain !== undefined && 
          user.domain.length >= 2 && 
          (available || checkdomain)) {
        setStep(5);
      }
    }
  };

  const getFooterText = () => {
    // Check if user has a reserved domain
    if (user?.email && 
        reservedDomains[user?.email] && 
        reservedDomains[user?.email] !== null && 
        reservedDomains[user?.email] === user?.domain) {
      return comment.reserved;
    }
    
    if (!user?.domain) return comment.empty;
    if (user.domain.length === 1) return comment.tooShort;
    return available ? comment.available : comment.unavailable;
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"Claim your domain"} speed={45} />
        <form onKeyDown={handleKeyDown}>
          <DomainInputContainer>
            <span>diz.ee/</span>
            <DomainInput
              type="text"
              value={user?.domain || ''}
              onChange={onChangeDomain}
              placeholder="yourdomain"
              data-available={available || checkdomain}
              maxLength={20}
            />
            <div className="w-[18px] h-[12px] ml-2">
              {user?.domain !== undefined &&
              user?.domain.length >= 2 &&
              (available || checkdomain) ? (
                <Check className="h-[12px] w-[18px]" />
              ) : (
                <DizeeCloseIcon className="h-[12px] w-[18px]" />
              )}
            </div>
          </DomainInputContainer>

          <ButtonContainer>
            <NextButton
              disabled={!available || (user?.domain?.length < 2)}
              className={`cursor-pointer mr-[22px] ${
                user?.domain !== undefined &&
                user?.domain.length >= 2 &&
                (available || checkdomain)
                  ? "font-default"
                  : "font-gray"
              }`}
              onClick={() =>
                user?.domain !== undefined &&
                user?.domain.length >= 2 &&
                (available || checkdomain) &&
                setStep(5)
              }
            >
              Next
            </NextButton>
            <NextButton className="cursor-pointer" onClick={() => setStep(3)}>
              Go back
            </NextButton>
          </ButtonContainer>
        </form>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          {getFooterText()}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step4;
