import React, { useEffect, useState } from 'react'
import CrossArrow from '../../svg/CrossArrow'
import { getContrastColor, maxLength } from '../DizeeComponent/DixeeInput2'
import { getAccentColor, getAccentStyle } from '../../constants/constants'
import More from '../../svg/More'
import { useDispatch, useSelector } from 'react-redux'
import { syncBandsintownEvents } from '../../store/bandsintown/bandsintownSlice'
import { getEventThunk } from '../../store/addsection/addsectionThunk'

export default function Event(props) {
    let { userArticle, event, setOverlay, title } = props
    const dispatch = useDispatch();
    const token = localStorage.getItem('dizeeToken');
    const [isSyncing, setIsSyncing] = useState(false);
    
    // Get accent color once for consistency
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#ffffff';
    
    // Get event header from both possible Redux locations
    const addsectionState = useSelector(state => state.addsection);
    const bandsintownSettings = useSelector(state => state.bandsintown.settings);
    const headerImage = addsectionState?.eventHeader?.url || localStorage.getItem('eventHeader');

    useEffect(() => {
        const syncEvents = async () => {
            if (isSyncing || !bandsintownSettings?.apiKey || !bandsintownSettings?.autoSync) return;
            setIsSyncing(true);
            try {
                await dispatch(syncBandsintownEvents({ token })).unwrap();
                await dispatch(getEventThunk({ token }));
            } catch (error) {
                console.error('Failed to sync events:', error);
            } finally {
                setIsSyncing(false);
            }
        };

        // Set up interval for auto-sync
        const syncInterval = setInterval(syncEvents, 30 * 60 * 1000); // Check every 30 minutes
        
        // Initial sync
        syncEvents();

        return () => clearInterval(syncInterval);
    }, [dispatch, token, bandsintownSettings, isSyncing]);

    return (
        <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative">
            <div className='flex justify-between items-center px-4 mb-4 w-full'>
                <h2 className='text-white' style={{ 
                    fontSize: '12px',
                    color: accentColor
                }}>{title || 'Events'}</h2>
                <More 
                    className='h-[20px] w-[20px] cursor-pointer' 
                    color={getContrastColor(userArticle?.background) ? `#${getContrastColor(userArticle?.background)}` : '#ffffff'} 
                    onClick={() => setOverlay && setOverlay(true)} 
                />
            </div>
            
            {headerImage && (
                <div className="w-[317px] h-[460px] flex relative">
                    <img 
                        src={headerImage} 
                        alt="Event Header"
                        className="w-full h-full object-cover" 
                    />
                </div>
            )}

            <div className='w-full flex flex-col justify-center items-center gap-y-[16px] mt-4 mb-[60px]'>
                {event?.length > 0 && event
                    .filter(item => item?.publish?.value === 'publish')
                    .map((item, index) => (
                    <React.Fragment key={index}>
                        <div className='flex w-full items-center px-2'>
                            <div className='grid w-full gap-2' style={{ 
                                gridTemplateColumns: '70px 1.4fr 0.6fr'
                            }}> 
                                {/* Day of week */}
                                <div className='text-white truncate pl-1' style={{ color: accentColor }}>
                                    {item?.date.split(',')[0]}
                                </div>
                                
                                {/* Location - with strict width control and proper accent color */}
                                <div style={{ 
                                    width: '100%',
                                    minWidth: 0,
                                    position: 'relative',
                                    paddingRight: '24px',
                                    overflow: 'hidden'
                                }}>
                                    <div className='truncate pl-1' style={{
                                        maxWidth: '70%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        color: accentColor
                                    }}>
                                        {maxLength(item?.location, 35)}
                                    </div>
                                </div>
                                
                                {/* Venue and arrow */}
                                <div style={{
                                    width: '100%',
                                    position: 'relative'
                                }}>
                                    <div className='flex justify-between items-center absolute' style={{
                                        left: '-80%',
                                        right: 0,
                                        top: 0,
                                        bottom: 0,
                                        zIndex: 1
                                    }}>
                                        <span className='truncate pl-1' style={{ 
                                            color: accentColor,
                                            width: 'calc(100% - 24px)',
                                            minWidth: 0,
                                            backgroundColor: userArticle?.background || '#000'
                                        }}>
                                            {maxLength(item?.venue, 15)}
                                        </span>
                                        <button 
                                            onClick={() => window.open(`${item.links[0]?.url}`, '_blank')}
                                            className="flex items-center justify-center flex-shrink-0 w-6"
                                        >
                                            <CrossArrow 
                                                className="h-[14px] w-[14px]"
                                                color={accentColor}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mx-2'>
                            <div className={`mx-2`} style={{ 
                                borderBottom: `.3px solid ${accentColor}`
                            }}></div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}