import React from 'react'

export default function WeChat(props) {
    const { color } = props
    return (
        <svg 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_5462_14916)">
                <mask id="mask0_5462_14916" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                    <path d="M16 0H0V16H16V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_5462_14916)">
                    <path d="M10.712 5.96602C9.42066 6.0335 8.29772 6.42496 7.38604 7.30938C6.46492 8.20294 6.04444 9.29786 6.15938 10.6552C5.65462 10.5927 5.1949 10.5239 4.73254 10.485C4.57286 10.4715 4.38338 10.4906 4.24812 10.5669C3.79916 10.8203 3.36876 11.1063 2.85862 11.4252C2.95222 11.0018 3.01282 10.6311 3.12006 10.2745C3.19894 10.0124 3.16242 9.86656 2.92098 9.6959C1.37079 8.60144 0.717345 6.96352 1.20636 5.27722C1.65879 3.71722 2.76982 2.77114 4.27948 2.27798C6.34002 1.60491 8.6557 2.29146 9.90866 3.92742C10.3612 4.51834 10.6387 5.1816 10.712 5.96602ZM4.7687 5.44058C4.7806 5.13216 4.51334 4.8543 4.1959 4.84502C3.8709 4.83546 3.60358 5.08384 3.5941 5.4041C3.5845 5.7287 3.83276 5.9888 4.16012 5.99712C4.48466 6.00534 4.7567 5.75664 4.7687 5.44058ZM7.86956 4.8448C7.55094 4.85066 7.28172 5.12202 7.28738 5.43168C7.29318 5.75264 7.5573 6.00374 7.88492 5.99978C8.2134 5.99584 8.46252 5.74208 8.45942 5.41434C8.4567 5.09264 8.19378 4.83888 7.86956 4.8448Z" fill={color || "white"}/>
                    <path d="M13.6164 14.0169C13.2075 13.8348 12.8324 13.5616 12.433 13.5199C12.0352 13.4784 11.617 13.7079 11.2008 13.7504C9.93303 13.8801 8.79723 13.5268 7.86071 12.6607C6.07953 11.0132 6.33403 8.48716 8.39479 7.13708C10.2263 5.93718 12.9123 6.33718 14.2036 8.0021C15.3305 9.4549 15.1981 11.3834 13.8224 12.6039C13.4243 12.9571 13.2811 13.2478 13.5365 13.7135C13.5836 13.7994 13.589 13.9083 13.6164 14.0169ZM8.96177 9.51026C9.22209 9.51052 9.43643 9.30678 9.44631 9.04966C9.45671 8.77746 9.23777 8.54914 8.96519 8.54802C8.69531 8.54678 8.46923 8.7783 8.47863 9.04646C8.48747 9.30268 8.70331 9.50994 8.96177 9.51026ZM11.9623 8.54908C11.7098 8.54732 11.4951 8.7541 11.4849 9.0093C11.4739 9.2822 11.686 9.5063 11.9561 9.507C12.2173 9.50786 12.4237 9.3103 12.4332 9.05036C12.4434 8.77682 12.2311 8.55094 11.9623 8.54908Z" fill={color || "white"}/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_5462_14916">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
