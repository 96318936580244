import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../layout/LayoutHeader';
import DizeeButton from '../../../../components/DizeeComponent/DizeeButton';
import DizeeSelect from '../../../../components/DizeeComponent/DizeeSelect';
import DizeeModal from '../../../../components/DizeeComponent/DizeeModal';
import { addMusicToSectionThunk, addVideoToSectionThunk, addEventToSectionThunk, addProductToSectionThunk, addPlaylistToSectionThunk, addFormToSectionThunk, addSocialFeedToSectionThunk, addCustomLinkToSectionThunk } from '../../../../store/addsection/addsectionThunk';
import { add_video_message_thunk } from '../../../../store/user/userThunk';
import { clearEvent } from '../../../../store/eventData/eventdataSlice';
import { clearAlerts } from '../../../../store/alert/alertSlice';
import { Calendar } from '@nayojs/react-datetime-picker';
import DizeeTimeInput from '../../../../components/DizeeComponent/DizeeTimeInput';
import { PLAYLIST_PLATFORMS } from '../../../../constants/constants';
import { clearCustomLinkData } from '../../../../store/customlinkData/customlinkDataSlice';
import { setErrorAlert } from '../../../../store/alert/alertSlice';


export default function PublishOption() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = localStorage.getItem('dizeeToken');
    const [option, setOption] = useState('draft');
    const [showCalendar, setShowCalendar] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');

    const confirm = () => {
        const type = findSection(location.pathname);
        if (option === 'draft' || option === 'publish') {
            handleAdd({ value: option }, type)
        } else if (option === 'schedule') {
            setShowCalendar(true);
        }
    }
    const findSection = (path) => {
        if (String(path).indexOf('/add-section/video-message/publish') > -1)
            return 'video-message'
        else if (String(path).indexOf('/add-section/music/publish') > -1)
            return 'music-search';
        else if (String(path).indexOf('/add-section/music/manual/publish') > -1)
            return 'music-manual';
        else if (String(path).indexOf('/add-section/video/publish') > -1)
            return 'video';
        else if (String(path).indexOf('/add-section/event/publish') > -1)
            return 'add-event';
        else if (String(path).indexOf('/add-section/product/manual/publish') > -1)
            return 'product-manual'
        else if (String(path).indexOf('/add-section/playlist/publish') > -1)
            return 'playlist'
        else if (String(path).indexOf('/add-section/add-form-link/publish') > -1)
            return 'form'
        else if (String(path).indexOf('/add-section/add-social-feed/publish') > -1)
            return 'social-feed'
        else if (String(path).indexOf('/add-section/add-custom-link/publish') > -1)
            return 'custom-link'
    }

    const handleAdd = (publish, type) => {
        console.log("Option.jsx - Received state:", location.state);
        
        let payload;

        dispatch(clearAlerts());

        if (type === 'video-message') {
            payload = {
                ...location.state,
                publish: {
                    value: publish.value,
                    time: publish.time || null
                },
                filter: location.state.filter
            }
            console.log("Publishing video with payload:", payload);
            dispatch(add_video_message_thunk({ videoMessage: payload, token })).then((res) => {
                console.log("Option.jsx - Response from add_video_message_thunk:", res);
                if (res.payload) {
                    navigate("/add-section/video-message")
                }
            });

        } else if (type === 'music-search') {
            const selectedLinks = location.state.musicItem?.links?.filter(link => location.state.selectedPlatforms.includes(link.source)) || [];
            payload = {
                type: 0,
                id: location.state.musicItem?.songstats_track_id,
                avatar: location.state.musicItem?.avatar,
                title: location.state.musicItem?.title,
                artist: location.state.musicItem?.artists[0].name,
                links: selectedLinks,
                publish,
                playback: location.state.playback
            }

            dispatch(addMusicToSectionThunk({ token: token, payload: payload })).then((res) => {
                if (res.payload.status === 200) {
                    navigate("/add-section/search-for-release");
                }
            });
        } else if (type === 'music-manual') {
            const { entries } = location.state;
            
            // Create array of promises for each entry
            const promises = entries.map(entry => {
                const payload = {
                    ...entry,
                    publish: {
                        value: publish.value,
                        time: publish.time || null
                    }
                }
                return dispatch(addMusicToSectionThunk({ token: token, payload: payload }))
            });
            
            // Wait for all entries to be added
            Promise.all(promises)
                .then((responses) => {
                    const hasError = responses.some(res => !res.payload || res.payload.status !== 200);
                    if (hasError) {
                        dispatch(setErrorAlert('Error adding one or more entries'));
                        return;
                    }
                    navigate("/add-section/custom-music-link");
                })
                .catch(error => {
                    console.error('Error adding entries:', error);
                    dispatch(setErrorAlert('Error adding entries'));
                });
        } else if (type === 'video') {
            dispatch(addVideoToSectionThunk({ token: token, payload: { ...location.state, publish } })).then((res) => {
                if (res.payload.status === 200) {
                    navigate("/add-section/add-video-link");
                }
            });
        } else if (type === 'add-event') {
            const { entries, returnPath } = location.state;
            
            if (!entries || entries.length === 0) {
                dispatch(setErrorAlert('No events to publish'));
                return;
            }

            const promises = entries.map(entry => {
                const payload = {
                    ...entry,
                    publish: {
                        value: option === 'schedule' ? 'schedule' : option,
                        time: publish?.time || null
                    }
                }
                return dispatch(addEventToSectionThunk({ token: token, payload: payload }))
            });
            
            Promise.all(promises)
                .then((responses) => {
                    const hasError = responses.some(res => !res.payload || res.payload.status !== 200);
                    if (hasError) {
                        dispatch(setErrorAlert('Error adding one or more entries'));
                        return;
                    }
                    localStorage.removeItem('eventQueueEntries');
                    dispatch(clearEvent());
                    navigate(returnPath || "/add-section/custom-event-link");
                })
                .catch(error => {
                    console.error('Error adding entries:', error);
                    dispatch(setErrorAlert('Error adding entries'));
                });
        } else if (type === 'product-manual') {
            const { entries, returnPath } = location.state;
            
            if (!entries || entries.length === 0) {
                dispatch(setErrorAlert('No products to publish'));
                return;
            }

            const promises = entries.map(entry => {
                const payload = {
                    ...entry,
                    id: entry.id,
                    publish: {
                        value: option === 'schedule' ? 'schedule' : option,
                        time: publish?.time || null
                    }
                }
                return dispatch(addProductToSectionThunk({ token, payload }));
            });
            
            Promise.all(promises)
                .then((responses) => {
                    const hasError = responses.some(res => !res.payload || res.payload.status !== 200);
                    if (hasError) {
                        dispatch(setErrorAlert('Error adding one or more entries'));
                        return;
                    }
                    localStorage.removeItem('productQueueEntries');
                    navigate(returnPath || "/add-section/manually-add-product");
                })
                .catch(error => {
                    console.error('Error adding entries:', error);
                    dispatch(setErrorAlert('Error adding entries'));
                });
        } else if (type === 'playlist') {
            const label = location.state.payload.links[0].source
            const name = PLAYLIST_PLATFORMS.find(platform => platform.label === label)?.value

            dispatch(addPlaylistToSectionThunk({ token: token, payload: { ...location.state.payload, publish } })).then((res) => {
                if (res.payload.status === 200) {
                    navigate("/add-section/add-playlist-link", { state: { iconKey: label, name: name } })
                }
            })
        } else if (type === 'form') {
            const type = location.state.payload.type
            dispatch(addFormToSectionThunk({ token: token, payload: { ...location.state.payload, publish } })).then((res) => {
                if (res.payload.status === 200) {
                    type === 0 ? navigate('/add-section/add-form-link') : navigate('/add-section/add-poll')
                }
            })
        } else if (type === 'social-feed') {
            const { entries, returnPath } = location.state;
            
            if (!entries || entries.length === 0) {
                dispatch(setErrorAlert('No social feed posts to publish'));
                return;
            }

            const promises = entries.map(entry => {
                const payload = {
                    ...entry,
                    title: entry.title || '',
                    avatar: entry.avatar,
                    platform: entry.platform,
                    links: entry.links,
                    publish: {
                        value: option === 'schedule' ? 'schedule' : option,
                        time: publish?.time || null
                    }
                };

                console.log('Publishing social feed entry:', payload);

                return dispatch(addSocialFeedToSectionThunk({ 
                    token, 
                    payload,
                    previousUrl: entry.links[0]?.url
                }));
            });

            Promise.all(promises)
                .then((responses) => {
                    const hasError = responses.some(res => !res.payload || res.payload.status !== 200);
                    if (hasError) {
                        dispatch(setErrorAlert('Error adding one or more entries'));
                        return;
                    }
                    localStorage.removeItem('socialFeedQueueEntries');
                    navigate(returnPath || "/add-section/add-social-feed");
                })
                .catch(error => {
                    console.error('Error adding entries:', error);
                    dispatch(setErrorAlert('Error adding entries'));
                });
        } else if (type === 'custom-link') {
            dispatch(clearCustomLinkData())
            dispatch(addCustomLinkToSectionThunk({ token: token, payload: { ...location.state.payload, publish }, previousUrl: location.state.previousUrl })).then((res) => {
                if (res.payload.status === 200) {
                    navigate('/add-section/add-custom-link')
                }
            })
        }
    }
    const selectDateHandler = (date) => {
        setDate(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
    }
    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    }
    const onSaveSchedule = () => {
        if (date.length < 1 || time.length < 5) {
            return;
        }
        handleAdd({ value: 'schedule', time: `${date} ${time} ${pm ? 'pm' : 'am'}` }, findSection(location.pathname));
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[90vh] bg-black flex flex-col  items-center relative">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Select preview option</p>
                    <div className='flex gap-x-4'>
                        <DizeeButton onClick={() => confirm()} label={'Done'} />
                        <DizeeButton onClick={() => navigate(-1)} label={'Go back'} />
                    </div>
                </div>
                {
                    <div className='w-full'>
                        <DizeeSelect selected={option === 'draft'} txt={'Draft'} onSelect={() => setOption('draft')} checkmark={true} />
                        <DizeeSelect selected={option === 'schedule'} txt={'Schedule'} onSelect={() => setOption('schedule')} checkmark={true} />
                        <DizeeSelect selected={option === 'publish'} txt={'Preview'} onSelect={() => setOption('publish')} checkmark={true} />
                    </div>
                }
            </div>
            {showCalendar &&
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <Calendar selectDateHandler={selectDateHandler} calendarStyles={{
                            containerStyles: { backgroundColor: '#000' },
                            dateStyles: { color: '#fff' },
                            selectedDateStyles: { backgroundColor: '#000', color: '#ff2' },
                            headerStyles: { background: '#000' },
                            headerTitleTextStyles: { color: '#fff' },
                            headerTitleStyles: { color: '#fff' },
                        }} />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime='11:12'
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            }
        </LayoutHeader>
    )
}