const allowedDomains = [
  'linktr.ee',
  'komi.io',
  'ffm.to',
  'taplink.at',
  'beacons.ai',
  'stan.store',
  'withkoji.com',
  'hopp.co',
  'contactinbio.com',
  'shor.by',
  'lnk.bio',
  'tap.bio',
  'campsite.bio',
  'bio.fm',
  'linkin.bio',
  'everlink.io',
  'shortstack.com',
  'milkshake.app',
  'leadpages.com'
];

export default allowedDomains; 