import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2, formatDate } from '../../../../../components/DizeeComponent/DixeeInput2';
import CopyLink from '../../../../../svg/CopyLink';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert } from '../../../../../store/alert/alertSlice';
import { getEventThunk } from '../../../../../store/addsection/addsectionThunk';
import { clearEvent, updateLink, updateLocation, updateVenue } from '../../../../../store/eventData/eventdataSlice';
import AddedEvent from '../../../../../components/AddSection/Event/AddedEvent';
import EventOverlay from '../../../../../components/AddSection/Event/EventOverlay';
import ImageSelectionCard from '../../../../../components/ImageSelectionCard';
import { uploadImage } from '../../../../../utils/upload';
import DizeeButton from '../../../../../components/DizeeComponent/DizeeButton';
import QueuedEventCard from '../../../../../components/AddSection/Event/QueuedEventCard';

export default function CustomEvent() {
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();

    const event = useSelector((state) => state.addsection.event);

    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [linkForBackend, setLinkForBackend] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [itemForBackend, setItemForBackend] = useState({});

    const link = useSelector((state) => state.eventdata.link);
    const evntLocation = useSelector((state) => state.eventdata.location);
    const eventVenue = useSelector((state) => state.eventdata.venue);
    const eventDate = useSelector((state) => state.eventdata.date);
    const previousUrl = useSelector((state) => state.eventdata.previousUrl);
    const previousSource = useSelector((state) => state.eventdata.previousSource);
    const type = useSelector((state) => state.eventdata.type);

    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [entries, setEntries] = useState([]);

    useEffect(() => {
        if (eventDate) {
            setFormattedDate(formatDate(eventDate));
        }
    }, [eventDate]);

    useEffect(() => {
        dispatch(clearAlerts());
        dispatch(getEventThunk({ token })).then((res) => {
            if (res.payload?.status === 200) {
                dispatch(clearAlerts());
            }
        });
    }, [dispatch, token]);

    useEffect(() => {
        const savedEntries = localStorage.getItem('eventQueueEntries');
        if (savedEntries) {
            setEntries(JSON.parse(savedEntries));
        }
    }, []);

    const filterLink = (link) => {
        if (event?.length > 0) {
            for (let m of event) {
                for (let musicLink of m.links) {
                    if (musicLink.url === link) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const handleAdd = async () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill all the fields'));
            return;
        }

        let url = '';
        if (selectedImage) {
            url = await uploadImage(selectedImage)
            if (!url) {
                dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                return
            }
        }

        let fixedLink = link;
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
            fixedLink = 'https://' + link;
        }

        const newEntry = {
            type: type ? type : 2,
            avatar: url || imagePreview,
            venue: eventVenue,
            date: formattedDate,
            location: evntLocation,
            link: fixedLink,
            links: [{
                source: previousSource ? previousSource : 'custom',
                url: fixedLink
            }]
        };

        console.log('Manual Queue Event:', {
            newEntry,
            type: typeof newEntry.type,
            hasAvatar: !!newEntry.avatar,
            linkStructure: newEntry.links,
            currentQueueLength: entries.length
        });

        const updatedEntries = [...entries, newEntry];
        console.log('All Queued Manual Events:', updatedEntries);

        setEntries(updatedEntries);
        localStorage.setItem('eventQueueEntries', JSON.stringify(updatedEntries));
        
        // Clear form after adding to queue
        dispatch(clearEvent());
        setSelectedImage(null);
        setImagePreview(null);
        setFormattedDate('');
    };

    const handleNext = async () => {
        if (entries.length === 0 && !isFormComplete) {
            dispatch(setErrorAlert('Please add at least one entry or complete the current form'));
            return;
        }

        if (isFormComplete) {
            let url = '';
            if (selectedImage) {
                url = await uploadImage(selectedImage);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
            }

            let fixedLink = link;
            if (!link.startsWith('http://') && !link.startsWith('https://')) {
                fixedLink = 'https://' + link;
            }

            const currentEntry = {
                type: type ? type : 2,
                avatar: url || imagePreview,
                venue: eventVenue,
                date: formattedDate,
                location: evntLocation,
                link: fixedLink,
                links: [{
                    source: previousSource ? previousSource : 'custom',
                    url: fixedLink
                }]
            };

            console.log('Manual Event Structure:', {
                currentEntry,
                type: typeof currentEntry.type,
                hasAvatar: !!currentEntry.avatar,
                linkStructure: currentEntry.links
            });

            const existingBulkEvents = JSON.parse(localStorage.getItem('eventQueueEntries') || '[]');
            if (existingBulkEvents.length > 0) {
                console.log('Existing Bulk Event Structure:', {
                    bulkEvent: existingBulkEvents[0],
                    type: typeof existingBulkEvents[0].type,
                    hasAvatar: !!existingBulkEvents[0].avatar,
                    linkStructure: existingBulkEvents[0].links
                });
            }

            const updatedEntries = [...entries, currentEntry];
            localStorage.setItem('eventQueueEntries', JSON.stringify(updatedEntries));
            navigate('/add-section/event/publish', { state: { entries: updatedEntries } });
        } else {
            localStorage.setItem('eventQueueEntries', JSON.stringify(entries));
            navigate('/add-section/event/publish', { state: { entries } });
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
        localStorage.setItem('eventQueueEntries', JSON.stringify(newEntries));
    };

    const isFormComplete = link && eventDate && formattedDate && eventVenue && evntLocation;
    const isFormEmpty = !link && !eventDate && !formattedDate && !eventVenue && !evntLocation;
    const showNext = entries.length > 0 || isFormComplete;
    const showQueue = isFormComplete;
    const showClear = !isFormEmpty && !isFormComplete;

    const handleClear = () => {
        dispatch(clearEvent());
        setSelectedImage(null);
        setImagePreview(null);
        setFormattedDate('');
    };

    const handleGoBack = () => {
        dispatch(clearEvent());
        navigate('/add-section/add-event');
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const resetImage = () => {
        setSelectedImage(null);
        setImagePreview(null);
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Add details</p>
                    <div className='flex gap-[30px]'>
                        {showQueue && (
                            <div style={{ transition: 'opacity 0.3s ease-in-out' }} 
                                 className="transition-opacity duration-300 ease-in-out">
                                <DizeeButton onClick={handleAdd} label={'Queue'} />
                            </div>
                        )}
                        
                        {showNext && (
                            <div style={{ transition: 'opacity 0.3s ease-in-out' }} 
                                 className="transition-opacity duration-300 ease-in-out">
                                <DizeeButton onClick={handleNext} label={'Next'} />
                            </div>
                        )}
                        
                        {showClear && (
                            <div style={{ transition: 'opacity 0.3s ease-in-out' }} 
                                 className="transition-opacity duration-300 ease-in-out">
                                <DizeeButton onClick={handleClear} label={'Clear'} />
                            </div>
                        )}

                        <p onClick={handleGoBack} className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>
                <div className='flex w-full flex-col gap-y-[50px]'>
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Enter a link"
                                    className="dizee-input w-full"
                                    value={link}
                                    onChange={(e) => dispatch(updateLink(e.target.value))}
                                />
                            </div>
                            <CopyLink className='h-[14px] w-[14px] mx-1' />
                        </div>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Add location"
                                    className="dizee-input w-full"
                                    value={evntLocation}
                                    onChange={(e) => dispatch(updateLocation(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Select a venue"
                                    className="dizee-input w-full"
                                    value={eventVenue}
                                    onChange={(e) => dispatch(updateVenue(e.target.value))}
                                />
                            </div>
                        </div>

                        {!formattedDate ?
                            <div className='p-4 flex w-full justify-between items-center'>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <button className='text-white text-[12px]' style={{ fontSize: '12px' }} onClick={(e) => {
                                        e.preventDefault()
                                        navigate('/add-section/date-picker', { state: { route: '/add-section/custom-event-link' } })
                                    }}
                                    >Select a date</button>
                                </div>
                            </div>
                            :
                            <div className="flex flex-col justify-center items-center w-full">
                                <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white'>
                                        <button 
                                            className='text-white text-[12px]' 
                                            style={{ fontSize: '12px' }} 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/add-section/date-picker', { 
                                                    state: { 
                                                        route: '/add-section/custom-event-link',
                                                        fromOverlay: false,
                                                        currentDate: eventDate
                                                    }
                                                });
                                            }}
                                        >
                                            Change date
                                        </button>
                                    </div>
                                    <div className=''>
                                        <p className='text-white text-[12px]'>{formattedDate}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Temporarily hidden
                        <ImageSelectionCard
                            txt="Add cover image"
                            dotimgclss={false}
                            onImageChange={handleImageChange}
                            imagePreview={imagePreview}
                            resetImage={resetImage}
                        /> */}
                    </div>

                    {entries?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>In Queue</p>
                                </div>
                            </div>
                            {entries.map((item, index) => (
                                <QueuedEventCard
                                    key={index}
                                    event={item}
                                    onDelete={() => handleDeleteFromQueue(index)}
                                />
                            ))}
                        </div>
                    )}

                    {event?.length > 0 && <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <p>Added event</p>
                            </div>
                        </div>
                        {event?.length > 0 && event?.map((item, index) => (
                            <AddedEvent 
                                key={index} 
                                item={item} 
                                setLinkForBackend={setLinkForBackend} 
                                setOverlayVisible={setOverlayVisible} 
                                setItemForBackend={setItemForBackend} 
                            />
                        ))}
                    </div>}
                </div>
            </div>
            {isOverlayVisible && <EventOverlay 
                isOverlayVisible={isOverlayVisible} 
                setOverlayVisible={setOverlayVisible} 
                linkForBackend={linkForBackend} 
                itemForBackend={itemForBackend} 
                setImagePreview={setImagePreview} 
                setSelectedImage={setSelectedImage} 
            />}
        </LayoutHeader>
    );
}