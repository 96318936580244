import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import SelectionCard from '../../../../components/SelectionCard';
import LayoutHeader from '../../../../layout/LayoutHeader';
import ImageSelectionCard from '../../../../components/ImageSelectionCard';
import { uploadImage } from '../../../../utils/upload';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorAlert, setLoader } from '../../../../store/alert/alertSlice';
import { addEventHeaderToSectionThunk, deleteEventHeaderToSectionThunk, getAllSectionsThunk } from '../../../../store/addsection/addsectionThunk';
import { clearEventHeader, setEventHeader } from '../../../../store/addsection/addsectionSlice';
import { store } from '../../../../store';

export default function AddEvent() {
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const addsectionState = useSelector((state) => state.addsection);

    const [imagePreview, setImagePreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        // const savedHeader = localStorage.getItem('eventHeader');
        // console.log(`savedHeader::`, savedHeader);
        // if (savedHeader) {
        //     setImagePreview(savedHeader);
        //     dispatch(setEventHeader(savedHeader));
        // }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setLoader(true));
                const response = await dispatch(getAllSectionsThunk({ token })).unwrap();

                // Wait for response to be fully resolved
                await new Promise(resolve => setTimeout(resolve, 100));

                const eventHeader = response?.data?.response?.eventHeader?.url;

                console.log(`savedHeader12::`, response);

                if (eventHeader) {
                    setImagePreview(eventHeader);
                    dispatch(setEventHeader(eventHeader));
                    localStorage.setItem('eventHeader', eventHeader);
                }
            } catch (error) {
                console.error('Error fetching sections:', error);
            } finally {
                dispatch(setLoader(false));
            }
        };

        if (token) {
            fetchData();
        }

        return () => {
            // Cleanup function to ensure state consistency
            dispatch(clearEventHeader());
        };
    }, [dispatch, token]);

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        dispatch(setLoader(true));

        const url = await uploadImage(file);
        if (!url) {
            dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
            return;
        }

        const payload = {
            url,
            headeractive: true
        };

        try {
            const response = await dispatch(addEventHeaderToSectionThunk({
                token,
                payload
            })).unwrap();

            console.log(`response::`, response);

            if (response?.status === 200) {
                setSelectedImage(file);
                setImagePreview(url);
                dispatch(setEventHeader(url));
            } else {
                throw new Error(response?.message || 'Failed to save image');
            }

        } catch (error) {
            console.error('Error uploading image:', error);
            setSelectedImage(null);
            setImagePreview(null);
            dispatch(setErrorAlert('Error saving image. Please try again.'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    const resetImage = async () => {
        if (!imagePreview) return;

        try {
            dispatch(setLoader(true));

            dispatch(clearEventHeader());

            const response = await dispatch(deleteEventHeaderToSectionThunk({ token })).unwrap();

            if (response.status === 200) {
                setSelectedImage(null);
                setImagePreview(null);
                localStorage.removeItem('eventHeader');
            } else {
                dispatch(setEventHeader(imagePreview));
                dispatch(setErrorAlert('Failed to delete image'));
            }
        } catch (error) {
            console.error('Error deleting image:', error);
            dispatch(setErrorAlert('Error deleting image'));
            dispatch(setEventHeader(imagePreview));
        } finally {
            dispatch(setLoader(false));
        }
    };

    const handleConfirm = async () => {
        try {
            dispatch(setLoader(true));

            // If there's no image, we need to ensure it's properly removed
            if (!imagePreview) {
                const response = await dispatch(deleteEventHeaderToSectionThunk({ token })).unwrap();

                if (response.status === 200) {
                    localStorage.removeItem('eventHeader');
                    dispatch(clearEventHeader());
                    navigate('/add-section');
                } else {
                    dispatch(setErrorAlert('Failed to remove header image'));
                }
                return;
            }

            // If we have an image to save
            let headerUrl = imagePreview;
            if (imagePreview.startsWith('blob:')) {
                headerUrl = await uploadImage(imagePreview);
                if (!headerUrl) {
                    dispatch(setErrorAlert('Failed to upload image'));
                    return;
                }
            }

            const payload = {
                url: headerUrl,
                headeractive: true
            };

            const response = await dispatch(addEventHeaderToSectionThunk({
                token,
                payload
            })).unwrap();

            if (response?.status === 200) {
                localStorage.setItem('eventHeader', headerUrl);
                dispatch(setEventHeader(headerUrl));
                navigate('/add-section');
            } else {
                dispatch(setErrorAlert('Failed to save header image'));
            }
        } catch (error) {
            console.error('Error confirming header:', error);
            dispatch(setErrorAlert('Error processing header image'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            {!isLoading && (
                <div className="w-[390px] h-auto bg-black flex flex-col justify-start items-center relative">
                    <div className='px-4 my-[50px] flex w-full justify-between'>
                        <p className='text-white' style={{ fontSize: '12px' }}>Add events</p>
                        <div className='flex gap-[30px]'>
                            <button
                                onClick={() => navigate('/add-section')}
                                className='text-white cursor-pointer'
                                style={{ fontSize: '12px' }}
                            >
                                Confirm
                            </button>
                            <Link to="/add-section">
                                <p className='text-white cursor-pointer' style={{ fontSize: '12px' }}>Go back</p>
                            </Link>
                        </div>
                    </div>

                    <ImageSelectionCard
                        txt={imagePreview ? "Header image" : "Add a header image"}
                        dotimgclss={false}
                        onImageChange={handleImageChange}
                        imagePreview={imagePreview}
                        resetImage={resetImage}
                    />
                    <SelectionCard txt="Add events" dotimgclss={false} link={'/add-section/custom-event-link'} />
                    <SelectionCard txt="Bulk import events" dotimgclss={false} link={'/add-section/bulk-import-events'} />
                </div>
            )}
        </LayoutHeader>
    );
}
