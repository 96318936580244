import React, { useEffect, useState } from 'react';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorAlert, setSuccessAlert } from '../../../../../store/alert/alertSlice';
import { update_user_article_thunk } from '../../../../../store/user/userThunk';
import { setUserArticle } from '../../../../../store/user/userSlice';
import styled from 'styled-components';

// Styled components for footer
const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  padding: 0 24px;
  width: 390px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
`;

export default function Redirect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const token = localStorage.getItem('dizeeToken');
    const { redirect } = useSelector(state => state.user.userArticle);
    const userArticle = useSelector(state => state.user.userArticle);

    useEffect(() => {
        if (redirect) setUrl(redirect);
    }, [redirect]);

    const handleAction = async () => {
        if (redirect) {
            // Clear functionality - only when there's an existing redirect
            setUrl('');
            let body = {
                ...userArticle,
                redirect: ''
            };
            dispatch(setUserArticle({
                ...userArticle, 
                redirect: ''
            }));
            dispatch(update_user_article_thunk({ token, body }));
            dispatch(setSuccessAlert('Redirect link is removed successfully'));
        } else {
            // Confirm functionality
            let body = {
                ...userArticle,
                redirect: url
            };
            dispatch(setUserArticle({
                ...userArticle, 
                redirect: url
            }));
            dispatch(update_user_article_thunk({ token, body }));
            dispatch(setSuccessAlert('Redirect link is added successfully'));
        }
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Redirect dizee page</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex flex-col gap-y-6">
                            <div className="text-white">
                                Enter a link to redirect your page
                            </div>
                            <div className="flex flex-col gap-y-[40px]">
                                <DizeeInput
                                    placeholder={"Enter a link"}
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                                <div className="flex justify-start w-full gap-[43px]">
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={handleAction}
                                    >
                                        {redirect ? 'Clear' : 'Confirm'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer>
                    <FooterText>
                        Redirect your page to another domain or link on the web.
                        After 30 days your dizee link will automatically be switched back.
                    </FooterText>
                </Footer>
            </div>
        </LayoutHeader>
    );
}
