const colorValidation = {
  combinations: {
    // Main colors and their forbidden accent combinations
    "#000000": {
      forbidden: ["#000080", "#000033"], // Very dark blues with black
      message: "Dark blues with black can be hard to see. Try a lighter blue or white instead."
    },

    // Add more problematic combinations as needed
  },
  
  validateAccentColor: (mainColor, accentColor) => {
    const mainHex = mainColor.toUpperCase();
    const accentHex = accentColor.toUpperCase();
    
    const combination = colorValidation.combinations[mainHex];
    if (combination && combination.forbidden.includes(accentHex)) {
      return {
        valid: false,
        message: combination.message
      };
    }
    
    return { valid: true };
  }
};

export default colorValidation; 