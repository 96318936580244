import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteEventThunk, updateEventThunk, } from '../../../store/addsection/addsectionThunk';
import { clearAlerts, setErrorAlert } from '../../../store/alert/alertSlice';
import { updateEventDate, updateLink, updateLocation, updatePreviousSource, updatePreviousUrl, updateType, updateVenue } from '../../../store/eventData/eventdataSlice';
import { updateEditEventDate } from '../../../store/eventEditData/eventEditDataSlice';
import DizeeModal from '../../DizeeComponent/DizeeModal';
import DizeeButton from '../../DizeeComponent/DizeeButton';
import DizeeSelect from '../../DizeeComponent/DizeeSelect';
import { DizeeInput2 } from '../../DizeeComponent/DixeeInput2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DizeeTimeInput from '../../DizeeComponent/DizeeTimeInput';

export default function EventOverlay({ setOverlayVisible, linkForBackend, itemForBackend }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const event = useSelector((state) => state.addsection.event)
    const index = event.findIndex(event => event.links[0].url === linkForBackend)

    const [showCalendar, setShowCalendar] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');

    const [editForm, setEditForm] = useState({
        venue: itemForBackend?.venue || '',
        location: itemForBackend?.location || '',
        date: itemForBackend?.date || '',
        link: itemForBackend?.link || ''
    });

    const [isSelecting, setIsSelecting] = useState(false);

    const eventDate = useSelector((state) => state.eventEditData?.date || null);

    useEffect(() => {
        if (event[index]) {
            setEditForm({
                venue: event[index].venue || '',
                location: event[index].location || '',
                date: event[index].date || '',
                link: event[index].links?.[0]?.url || ''
            });
        }
    }, [event, index]);

    useEffect(() => {
        if (location?.state?.dateUpdated && location?.state?.eventId === linkForBackend) {
            try {
                if (location?.state?.editForm?.date) {
                    setEditForm(prev => ({
                        ...prev,
                        date: location.state.editForm.date,
                        publish: event[index]?.publish || { value: 'draft' }
                    }));
                    
                    if (location?.state?.showEditor) {
                        setShowEditor(true);
                    }
                }
                
                window.history.replaceState({}, document.title);
            } catch (error) {
                console.error('Error updating date:', error);
                dispatch(setErrorAlert('Error updating date'));
            }
        }
    }, [location?.state, linkForBackend]);

    useEffect(() => {
        if (location?.state?.fromDatePicker && location?.state?.dateUpdated) {
            setShowEditor(true);
            setEditForm(prev => ({
                ...prev,
                date: location.state.editForm.date
            }));
            
            // Clear the navigation state
            window.history.replaceState({}, document.title);
        }
    }, [location?.state]);

    const selectDateHandler = (selectedDate) => {
        const date = `${new Date(selectedDate).getFullYear()}-${new Date(selectedDate).getMonth() + 1}-${new Date(selectedDate).getDate()}`;
        setDate(date);
        setEditForm(prev => ({ ...prev, date }));
    };

    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    };

    const onSaveSchedule = () => {
        dispatch(clearAlerts());
        if (date.length < 1 || time.length < 5) {
            return;
        }
        const timer = `${date} ${time} ${pm ? 'pm' : 'am'}`;
        setEditForm(prev => ({ ...prev, date: timer }));
        setShowCalendar(false);
    };

    const handleEditClick = () => {
        setShowEditor(true);
    };

    const handleScheduleClick = () => {
        dispatch(clearAlerts());
        setShowCalendar(true)
    };

    const handlePublishClick = () => {
        const saveType = "publish"
        dispatch(clearAlerts());
        dispatch(updateEventThunk({ linkForBackend, saveType, token }));
        setOverlayVisible(false);
    };

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(deleteEventThunk({ token: token, link: linkForBackend }));
        setOverlayVisible(false);
    };

    const onSaveEdit = async () => {
        dispatch(clearAlerts());
        
        if (!editForm.venue || !editForm.location || !editForm.date || !editForm.link) {
            dispatch(setErrorAlert('Please fill in all fields'));
            return;
        }
        
        try {
            console.log('Sending update:', {
                linkForBackend,
                updates: {
                    type: "event",
                    venue: editForm.venue,
                    location: editForm.location,
                    date: editForm.date,
                    link: editForm.link,
                    links: [{ url: editForm.link }]
                }
            });

            const result = await dispatch(updateEventThunk({ 
                linkForBackend, 
                saveType: "edit",
                updates: {
                    type: "event",
                    venue: editForm.venue,
                    location: editForm.location,
                    date: editForm.date,
                    link: editForm.link,
                    links: [{ url: editForm.link }]
                },
                token
            })).unwrap();

            if (result.status === 200) {
                setShowEditor(false);
                setOverlayVisible(false);
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
            }
            dispatch(setErrorAlert('Error saving changes'));
        }
    };

    const handleDateSelect = (e) => {
        e.preventDefault();
        const currentDate = editForm.date ? new Date(editForm.date) : new Date();
        
        dispatch(updateEditEventDate(currentDate));
        
        navigate('/add-section/date-picker', { 
            state: { 
                route: '/add-section/custom-event-link',
                isEditing: true,
                eventId: linkForBackend,
                currentDate: currentDate,
                fromOverlay: true,
                previousState: {
                    showEditor: false,
                    editForm: {
                        ...editForm,
                        publish: event[index]?.publish || { value: 'draft' }
                    },
                    overlayVisible: true,
                    returnToEdit: true,
                    keepOverlay: true
                }
            }
        });
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-1"
            onClick={(e) => {
                if (e.target.id === 'overlay' && !showEditor) {
                    setShowEditor(false);
                    setOverlayVisible(false);
                }
            }}
        >
            {!showEditor ? (
                <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" 
                    onClick={e => e.stopPropagation()}
                >
                    {event[index]?.publish?.value === "draft" ? (
                        <>
                            <p className="cursor-pointer pb-[10px]" 
                                onClick={() => {
                                    setShowEditor(true);
                                    setEditForm({
                                        venue: event[index].venue || '',
                                        location: event[index].location || '',
                                        date: event[index].date || '',
                                        link: event[index].links?.[0]?.url || ''
                                    });
                                }}
                            >
                                Edit details
                            </p>
                            <p className="cursor-pointer pb-[10px]" onClick={handleScheduleClick}>Schedule</p>
                            <p className="cursor-pointer pb-[10px]" onClick={handlePublishClick}>Preview</p>
                            <p className="cursor-pointer pb-[10px]" onClick={handleDeleteClick}>Delete</p>
                        </>
                    ) : (
                        <>
                            <p className="cursor-pointer pb-[10px]" 
                                onClick={() => {
                                    setShowEditor(true);
                                    setEditForm({
                                        venue: event[index].venue || '',
                                        location: event[index].location || '',
                                        date: event[index].date || '',
                                        link: event[index].links?.[0]?.url || ''
                                    });
                                }}
                            >
                                Edit details
                            </p>
                            <p className="cursor-pointer pb-[10px]" onClick={handleDeleteClick}>Delete</p>
                        </>
                    )}
                    <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
                </div>
            ) : (
                <div 
                    className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50"
                >
                    <div 
                        className="w-[390px] flex flex-col justify-start items-center relative z-[0] bg-black"
                    >
                        <div className='px-4 flex w-full justify-between items-center mt-[50px]'>
                            <p className='text-white text-[12px]'>Edit details</p>
                            <div className='flex gap-x-4'>
                                <DizeeButton label={'Confirm'} onClick={onSaveEdit} />
                                <DizeeButton label={'Go back'} onClick={() => setShowEditor(false)} />
                            </div>
                        </div>
                        <div className='px-4 mt-[80px] flex flex-col w-full gap-y-6'>
                            <DizeeInput2
                                label="Venue"
                                placeholder="Select a venue"
                                className="dizee-input w-full text-white"
                                value={editForm.venue}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setEditForm(prev => ({ ...prev, venue: e.target.value }));
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            />
                            <DizeeInput2
                                label="Location"
                                placeholder="Add a location"
                                className="dizee-input w-full text-white"
                                value={editForm.location}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setEditForm(prev => ({ ...prev, location: e.target.value }));
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            />
                            <div className='p-4 flex w-full justify-between items-center' style={{ padding: '16px 0' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    {!editForm.date ? (
                                        <button 
                                            className='text-white text-[12px] pl-0' 
                                            onClick={handleDateSelect}
                                        >
                                            Select a date
                                        </button>
                                    ) : (
                                        <div className='flex w-full justify-between items-center'>
                                            <button 
                                                className='text-white text-[12px] pl-0' 
                                                onClick={handleDateSelect}
                                            >
                                                Change date
                                            </button>
                                            <div>
                                                <p className='text-white text-[12px]'>{editForm.date}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <DizeeInput2
                                label="Link"
                                placeholder="Add a link"
                                className="dizee-input w-full text-white"
                                value={editForm.link}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setEditForm(prev => ({ ...prev, link: e.target.value }));
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                </div>
            )}

            {showCalendar && (
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <DatePicker
                            selected={date}
                            onChange={selectDateHandler}
                            inline
                            className="rounded-lg border-none text-white bg-black"
                        />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime={time}
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            )}
        </div>
    )
}
