import React, { useState } from 'react'
import more from '../../../assets/images/components/More.png';
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import More from '../../../svg/More';
import PreviewOverlay from '../../PreviewOverlay';


export default function AddedVideoCard({ video, setAvatarForBackend, setOverlayVisible }) {
    const [previewOverlayOpen, setPreviewOverlayOpen] = useState(false);

    return (
        <div className='w-[350px] sm:w-[390px] p-5 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                {
                    video?.type === 1 ? (
                        <video src={video?.avatar} alt='Preview' className='object-cover h-[50px]  w-[50px] rounded-[3px] ' muted controls={false} />

                    ) : (
                        <img src={removeUploadsFromUrl(video?.avatar)} alt='alt' className='object-cover h-[50px]  w-[50px] rounded-[3px]  ' />

                    )
                }
                <div className='flex flex-col items-start justify-start  h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>{maxLength(video?.title, 25)}</p>
                </div>
            </div>
            <div className='flex'>
                <div 
                    className='text-white cursor-pointer mr-7'
                    onClick={() => setPreviewOverlayOpen(true)}
                >
                    {video?.publish?.value === 'publish' ? 'preview' : video?.publish?.value}
                </div>
                <More className='h-[20px] w-[20px] cursor-pointer' onClick={() => {
                    setOverlayVisible(true)
                    setAvatarForBackend(video?.avatar)
                }} />
            </div>

            {previewOverlayOpen && (
                <PreviewOverlay 
                    isOpen={previewOverlayOpen}
                    onClose={() => setPreviewOverlayOpen(false)}
                    selectedSection="video"
                    item={video}
                    type="video"
                />
            )}
        </div>
    )
}
