import React, { useEffect, useState } from 'react'
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput'
import LayoutHeader from '../../../../../layout/LayoutHeader'
import { useNavigate } from 'react-router';
import { postDataAPI } from '../../../../../utils/fetchData';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert, setLoader, setSuccessAlert } from '../../../../../store/alert/alertSlice';
import { setNewPhone } from '../../../../../store/setting/settingSlice';
import { update_user_article_thunk } from '../../../../../store/user/userThunk';
import { verifyPhone } from '../../../../../store/auth/authThunk';


export default function NewPhone() {
    // const [phone,]
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken')
    const dispatch = useDispatch()
    const [phone, setPhone] = useState('')
    const userArticle = useSelector(state => state.user.userArticle);

    // const handleNewPhoneVerify = async () => {
    //     dispatch(setLoader(true))
    //     try {
    //         await postDataAPI("setting/update-new-phone", { phone: phone }, token).then((res) => {
    //             if (res.data.status === 200) {
    //                 dispatch(setNewPhone(phone))
    //                 dispatch(setLoader(false))
    //                 dispatch(setSuccessAlert(res.data.message))
    //                 navigate('/more/setting/verify-new-phone')
    //             } else {
    //                 dispatch(setLoader(false))
    //                 dispatch(setErrorAlert(res.data.message))
    //             }
    //         })
    //     } catch (error) {
    //         dispatch(setLoader(false))
    //         dispatch(setErrorAlert(error?.response?.data?.message || "An error occured"))
    //     }
    // }

    const sendVerifyCode = async () => {
        let body = { ...userArticle, phone };
        dispatch(clearAlerts());
        dispatch(update_user_article_thunk({ token, body }));
        dispatch(verifyPhone(phone));
        navigate('/more/setting/verify-new-phone');
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className="px-4 my-[50px] flex w-full justify-between">
                    <p className="text-white">Change phone number</p>
                    <div className='flex gap-[20px]'>
                        <p onClick={() => navigate('/more/setting')} className='text-white cursor-pointer'>Go back</p>
                    </div>
                </div>

                <div className="w-full px-4">
                    <div className="flex flex-col justify-between">
                        <div className="py-6 flex flex-col gap-y-6">
                            <div className="text-white">
                                Enter your new phone number
                            </div>
                            <div className="flex flex-col gap-y-[40px]">
                                <DizeeInput
                                    placeholder={"Mobile phone #"}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <div className="flex justify-start w-full">
                                    <button 
                                        className="text-white cursor-pointer text-[12px] h-[18px] leading-[18px]"
                                        onClick={sendVerifyCode}
                                    >
                                        Send verification code
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}
