import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAlerts,
  setErrorAlert,
  setSuccessAlert,
} from "../../../store/alert/alertSlice";
import { stripePaymentThunk } from "../../../store/auth/authThunk";
import Check from "../../../svg/Check";
import { postDataAPI } from "../../../utils/fetchData";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const PlanOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  font-size: 12px;
  color: white;
  margin: 2px 0;
  width: 280px;
`;

const PlanOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18px;
  line-height: 18px;
  gap: 35px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const comment = {
  0: "Get started with essential tools through the Basic plan. Ideal for those new to Dizee, this plan provides all the foundational features you need to begin engaging your audience.",
  1: "Unlock advanced features with the Pro plan. Enjoy enhanced customization, detailed analytics, and premium tools to boost your visibility and engagement. Perfect for creators, brands, and businesses looking to grow. ",
};

const Step3 = ({ setStep, setUser, user }) => {
  const [planType, setPlanType] = useState(0);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        await postDataAPI("stripe/get_payment_history", {
          phone: userData?.phone,
          email: userData?.email,
        }).then((res) => {
          if (res.data.data.response) {
            setStep(4);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchApi();
  }, [planType]);

  useEffect(() => {
    if (user && typeof user.plan === "number") {
      if (user.plan <= 1)
        setPlanType(0); // Monthly plans: 0 (Basic), 1 (Professional)
      else setPlanType(1); // Yearly plans: 2 (Basic), 3 (Professional)
    }
  }, [user]);

  const handleStripePayment = async (plan) => {
    const persistedData = localStorage.getItem("persist:root");
    const parsedData = JSON.parse(persistedData);
    let userData = JSON.parse(parsedData.user);
    let phone = userData.phone;
    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get("email");
    let email = emailParam;
    let userDataState = JSON.stringify(user);
    dispatch(clearAlerts());
    console.log("plan", plan);
    dispatch(stripePaymentThunk({ plan, user: userDataState, email, phone }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && user?.plan !== undefined) {
      handleStripePayment(user?.plan);
    }
  };

  useEffect(() => {
    // Attach event listener to detect 'Enter' key press
    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [user.professionRole]);

  const handleSkipTrial = async () => {
    dispatch(clearAlerts());
    try {
      const url = new URL(window.location.href);
      const emailParam = url.searchParams.get("email");

      const trialData = {
        email: emailParam || userData?.email,
        phone: userData?.phone,
        trialStartDate: new Date().toISOString(),
        trialStatus: "active",
      };

      // const response = await postDataAPI("auth/start-trial", trialData);

      // if (response.data.status === 200) {
      //   setUser({
      //     ...user,
      //     trialStartDate: trialData.trialStartDate,
      //     trialStatus: 'active'
      //   });

      //   dispatch(setSuccessAlert("3-day trial started"));
      //   setStep(4);
      // }

      dispatch(setSuccessAlert("3-day trial started"));
      setStep(4);
    } catch (error) {
      console.error("Trial start failed:", error);
      const errorMessage =
        error?.response?.data?.message || "Could not start trial";
      dispatch(setErrorAlert(errorMessage));
    }
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"Select a plan"} speed={45} />
        <PlanOptions>
          <PlanOption>
            <div
              className="cursor-pointer flex items-center"
              onClick={() => {
                setUser({ ...user, plan: planType === 0 ? 0 : 2 });
              }}
            >
              <div>Basic</div>
              <div className="w-[18px] h-[12px] ml-2">
                {(user?.plan === 0 || user?.plan === 2) && (
                  <Check className="h-[12px] w-[18px]" />
                )}
              </div>
            </div>
            <div>{planType === 0 ? "$10" : "$100"}</div>
          </PlanOption>

          <PlanOption>
            <div
              className="cursor-pointer flex items-center"
              onClick={() => {
                setUser({ ...user, plan: planType === 0 ? 1 : 3 });
              }}
            >
              <div>Professional</div>
              <div className="w-[18px] h-[12px] ml-2">
                {(user?.plan === 1 || user?.plan === 3) && (
                  <Check className="h-[12px] w-[18px]" />
                )}
              </div>
            </div>
            <div>{planType === 0 ? "$20" : "$200"}</div>
          </PlanOption>

          <PlanOption>
            <div
              className="cursor-pointer"
              onClick={() => setPlanType(planType === 0 ? 1 : 0)}
            >
              {planType === 0 ? "Switch to yearly" : "Switch to monthly"}
            </div>
            <div></div>
          </PlanOption>
        </PlanOptions>

        <ButtonContainer>
          <ActionButton
            className="mr-[43px]"
            onClick={() => {
              if (user?.plan !== undefined) {
                handleStripePayment(user.plan);
              }
            }}
          >
            Confirm
          </ActionButton>
          <ActionButton onClick={handleSkipTrial}>Skip</ActionButton>
        </ButtonContainer>
      </FormSection>

      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>Skip now and try DIZEE free for 3 days</FooterText>
      </Footer>
    </Container>
  );
};

export default Step3;
