export const SECTION_TEMPLATES = {
  'basic': {
    id: 'basic',
    label: 'Basic Link',
    fields: ['title', 'link'],
    layout: 'list',
    imageSize: 'none',
    displayOptions: {
      showTitle: true,
      showLink: true
    }
  },
  'image': {
    id: 'image',
    label: 'Image with Link',
    fields: ['title', 'image', 'link'],
    layout: 'grid',
    imageSize: 'medium',
    displayOptions: {
      showTitle: true,
      showImage: true,
      showLink: true
    }
  },
  'event': {
    id: 'event',
    label: 'Event',
    fields: ['title', 'image', 'date', 'location', 'description', 'link'],
    layout: 'card',
    imageSize: 'large',
    displayOptions: {
      showTitle: true,
      showImage: true,
      showDate: true,
      showLocation: true,
      showDescription: true,
      showLink: true
    }
  },
  'product': {
    id: 'product',
    label: 'Product',
    fields: ['title', 'image', 'price', 'description', 'link'],
    layout: 'grid',
    imageSize: 'medium',
    displayOptions: {
      showTitle: true,
      showImage: true,
      showPrice: true,
      showDescription: true,
      showLink: true
    }
  }
}; 