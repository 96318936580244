import React from 'react'
import styled from 'styled-components'
import { ReactComponent as DizeeLogo } from "../../../../assets/images/dizee logo.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
  padding-top: 100px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 0 35px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 48px;
  height: auto;
  margin-bottom: 16px;

  svg {
    width: 100%;
    height: auto;
    path {
      fill: ${props => `#${props.accentColor}`};
    }
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
  margin-left: 35px;
`;

const FooterText = styled.div`
  font-size: 12px;
  max-width: 280px;
  line-height: 1.5;
  color: ${props => `#${props.accentColor}`};
`;

export default function Template({ setStep, setUser, user }) {
    const colorTemplate = {
        "0D0D0D": "DARK",
        "FFFFFF": "LIGHT",
        "B22222": "WARM",
        "2B3A67": "COOL",
        "228B22": "ORGANIC",
    }
    return (
        <Container>
            <div className="font-default h-screen flex flex-col justify-between w-full" 
                 style={{ backgroundColor: `#${user?.background}`, color: `#${user?.accent}` }}>
                <MainContent>
                    <ContentWrapper>
                        <div>
                            <div className="mb-9">
                                {colorTemplate[user?.background]}
                            </div>
                            <div className="mb-9">
                                FOR PRESENTATION PURPOSES ONLY
                            </div>
                            <div className="flex">
                                <div className="cursor-pointer mr-[43px]"
                                    onClick={() => setStep(8)}>
                                    Select
                                </div>
                                <div className="cursor-pointer" 
                                    onClick={() => setStep(6)}>
                                    Go back
                                </div>
                            </div>
                        </div>
                    </ContentWrapper>
                </MainContent>
                
                <Footer>
                    <LogoWrapper accentColor={user?.accent}>
                        <DizeeLogo />
                    </LogoWrapper>
                    <FooterText accentColor={user?.accent}>
                        This is how your color scheme will look. The background color sets the mood while the accent color ensures your content stands out perfectly. This was made for presentation purposes.
                    </FooterText>
                </Footer>
            </div>
        </Container>
    );
}
