import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import search from '../../../../assets/images/components/search.png';
import SelectionCard from '../../../../components/SelectionCard';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getMusicThunk } from '../../../../store/addsection/addsectionThunk';
import { clearAlerts } from '../../../../store/alert/alertSlice';
import AddedMusicCard from '../../../../components/AddSection/Music/AddedMusicCard'
import MusicOverlay from '../../../../components/AddSection/Music/MusicOverlay'

export default function AddMusic() {
    const token = localStorage.getItem('dizeeToken')
    const dispatch = useDispatch()
    const [isOverlayVisible, setOverlayVisible] = useState(false)
    const [avatarForBackend, setAvatarForBackend] = useState('')
    
    // Get music from Redux store instead of local state
    const music = useSelector((state) => state.addsection.music)

    useEffect(() => {
        dispatch(getMusicThunk({ token }))
        dispatch(clearAlerts())
    }, [dispatch, token])

    return (
        <LayoutHeader>
            <div className="w-[390px] h-auto bg-black flex flex-col justify-start items-center relative">
                <div className='px-4 my-[50px] flex  w-full justify-between'>
                    <p className='text-white' style={{ fontSize: '12px' }}>Add music</p>
                    <Link to="/add-section">
                        <p className='text-white cursor-pointer ' style={{ fontSize: '12px' }}>Go back</p>
                    </Link>
                </div>

                <div className='px-4 py-6 flex  w-full justify-between items-center' style={{ fontSize: '12px' }}  >
                    <div className='w-auto items-center flex flex-row'>
                        <p className='text-white '>Search for a release</p>
                    </div>

                    <Link to="/add-section/search-for-release" className='cursor-pointer'>
                        <img src={search} alt='sp' className='h-[12px] w-[12px] mx-1' />
                    </Link>
                </div>

                <SelectionCard txt="Manual Entry " dotimgclss={false} link={'/add-section/custom-music-link'} />

                {/* Added Music Section */}
                {music?.length > 0 && (
                    <div className='w-full mt-[100px]'>
                        <p className='text-white px-4 mb-4' style={{ fontSize: '12px' }}>Added Music</p>
                        <div className='space-y-2'>
                            {music.filter(item => {
                                if (!item) return false;
                                if (item.publish && typeof item.publish === 'object' && !item.publish.value) return false;
                                return true;
                            }).map((item, index) => (
                                <AddedMusicCard
                                    key={index}
                                    music={item}
                                    setAvatarForBackend={setAvatarForBackend}
                                    setOverlayVisible={setOverlayVisible}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {isOverlayVisible && (
                <MusicOverlay 
                    isOverlayVisible={isOverlayVisible}
                    setOverlayVisible={setOverlayVisible} 
                    avatarForBackend={avatarForBackend} 
                />
            )}
        </LayoutHeader>
    )
}
