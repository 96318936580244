import React from 'react'
import RedirectCard from '../../components/More/RedirectCard'
import LayoutHeader from '../../layout/LayoutHeader'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { logoutThunk } from '../../store/auth/authThunk';
import { clearAlerts } from '../../store/alert/alertSlice';

export default function SelectOption() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(clearAlerts());
        dispatch(logoutThunk());
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Select an option</p>
                </div>
                <RedirectCard txt="Settings" dotimgclss={false} link={"/more/setting"} />
                <RedirectCard txt="Logout" dotimgclss={false} Click={handleLogout} />
            </div>
        </LayoutHeader>
    )
}
