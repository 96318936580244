import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    date: null
};

const eventEditDataSlice = createSlice({
    name: 'eventEditData',
    initialState,
    reducers: {
        updateEditEventDate: (state, action) => {
            state.date = action.payload;
        },
        clearEditEventDate: (state) => {
            state.date = null;
        }
    }
});

export const { updateEditEventDate, clearEditEventDate } = eventEditDataSlice.actions;
export default eventEditDataSlice.reducer; 