import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import Card from '../../../../components/AddSection/VideoMessage/Card';
import { useNavigate } from 'react-router';
import DotSvg from '../../../../svg/DotSvg';
import LayoutHeader from '../../../../layout/LayoutHeader';
import PlayCircle from '../../../../svg/PlayCircle';
import DizeeInput from '../../../../components/DizeeComponent/DizeeInput';
import { videoMessageOpts } from '../../../../constants/constants';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../store/alert/alertSlice';
import { postDataAPI } from '../../../../utils/fetchData';
import { useDispatch, useSelector } from 'react-redux';
import ModalSaveVideo from '../../../../components/AddSection/VideoMessage/ModalSaveVideo';
import { add_video_message_thunk, get_video_message_thunk } from '../../../../store/user/userThunk';
import DizeeButton from '../../../../components/DizeeComponent/DizeeButton';
import VideoMessageOverlay from '../../../../components/AddSection/VideoMessage/VideoMessageOverlay';
import Check from '../../../../svg/Check';
import { FILTERS } from '../../../../constants/constants';

const MAX_RECORDING_TIME = 15;
const DEFAULT_FILTER = "contrast(1.25) saturate(1.15) brightness(1.05) opacity(0.98) blur(0.2px) hue-rotate(1deg)";
const GRAIN_FILTER = "contrast(108%) brightness(102%) saturate(85%) sepia(15%) hue-rotate(5deg)";
const BLUE_FILTER = "brightness(90%) sepia(60%) saturate(800%) hue-rotate(190deg)";
const DIZ_FILTER = "brightness(95%) sepia(40%) saturate(500%) hue-rotate(-20deg)";

const CommonComponents = ({ text, action }) => {
    return (
        <div className='flex items-baseline justify-center flex-row'>
            <DotSvg width={6} height={6} color={'#FF4D4D'} className='mr-2' />
            <button className='text-white cursor-pointer' onClick={action} style={{ fontSize: '12px' }}>{text}</button>
        </div>
    );
};

export default function VideoMessage() {
    const token = localStorage.getItem('dizeeToken');
    const navigate = useNavigate();
    const [showWebcam, setShowWebcam] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [videoURL, setVideoURL] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [activedTab, setActivedTab] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const intervalRef = useRef(null);
    let videoRef = useRef(null);
    const [isOverlayVisible, setOverlayVisible] = useState(false);

    const dispatch = useDispatch();
    const [filter, setFilter] = useState(DEFAULT_FILTER);
    const [title, setTitle] = useState('')
    const [saveType, setSaveType] = useState('draft')
    const [link, setLink] = useState('')
    const [titleState, setTitleState] = useState(false)
    const [timerBackend, setTimerBackend] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [previosUrl, setPreviosUrl] = useState(null)
    const [webcamReady, setWebcamReady] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [isIOSSafari, setIsIOSSafari] = useState(false);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('none');

    const videoMessage = useSelector(state => state.user.videoMessage);

    useEffect(() => {
        if (token) {
            dispatch(clearAlerts());
            dispatch(get_video_message_thunk({ token }));
        }
    }, [token, dispatch]);

    useEffect(() => {
        return () => {
            if (videoURL) {
                URL.revokeObjectURL(videoURL);
            }
        };
    }, [videoURL]);

    useEffect(() => {
        // Detect iOS and Safari in a single useEffect
        const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        setIsIOS(ios);
        setIsIOSSafari(ios && isSafari);
    }, []); // Empty dependency array since userAgent won't change during session

    const onCloseModal = () => {
        setActivedTab(false);
    }


    const handleStopRecording = useCallback(() => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            setShowWebcam(false); // Hide webcam after stopping recording
            clearInterval(intervalRef.current); // Stop the timer
        }
    }, []);

    const handleDataAvailable = useCallback(({ data }) => {
        if (data.size > 0) {
            setRecordedChunks(prevChunks => [...prevChunks, data]);
        }
    }, []);

    useEffect(() => {
        let url;
        if (recordedChunks.length > 0 && !isRecording) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });
            url = URL.createObjectURL(blob);
            setVideoURL(url);
        }
        return () => {
            if (url) {
                URL.revokeObjectURL(url);
            }
        };
    }, [recordedChunks, isRecording]);

    const checkBrowserCompatibility = () => {
        // Check for iOS Safari specifically
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isIOS && isSafari) {
            dispatch(setErrorAlert("Video recording is not supported in iOS Safari. Please use Chrome or another browser."));
            dispatch(setLoader(false));
            return false;
        }

        // Check for general getUserMedia support
        if (!navigator.mediaDevices?.getUserMedia && !navigator.getUserMedia) {
            dispatch(setErrorAlert("Your browser doesn't support video recording. Please use a modern browser like Chrome or Firefox."));
            dispatch(setLoader(false));
            return false;
        }

        return true;
    };

    const handleStartRecording = useCallback(() => {
        dispatch(clearAlerts());
        
        // Check compatibility first
        if (!checkBrowserCompatibility()) {
            return;
        }

        // Use the legacy getUserMedia if needed
        const getUserMediaFunc = navigator.mediaDevices?.getUserMedia || 
            ((navigator.getUserMedia || 
              navigator.webkitGetUserMedia || 
              navigator.mozGetUserMedia || 
              navigator.msGetUserMedia).bind(navigator));

        getUserMediaFunc({ 
            video: {
                facingMode: 'user',
                width: { ideal: 1280 },
                height: { ideal: 720 }
            },
            audio: true 
        })
        .then(stream => {
            setShowWebcam(true);
            setIsRecording(true);
            setTimer(0);
            
            if (webcamRef.current && webcamRef.current.video) {
                webcamRef.current.video.muted = true;
            }
            
            intervalRef.current = setInterval(() => {
                setTimer(prevTime => {
                    if (prevTime >= MAX_RECORDING_TIME) {
                        handleStopRecording();
                    }
                    return prevTime + 1;
                });
            }, 1000);
            
            // Try different MIME types based on browser support
            const getMimeType = () => {
                const types = [
                    'video/mp4',
                    'video/webm;codecs=vp9,opus',
                    'video/webm;codecs=vp8,opus',
                    'video/webm',
                    'video/quicktime'
                ];
                
                for (const type of types) {
                    if (MediaRecorder.isTypeSupported(type)) {
                        console.log('Supported MIME type:', type);
                        return type;
                    }
                }
                console.log('No supported MIME type found');
                return '';
            };

            try {
                const options = {
                    mimeType: getMimeType(),
                    audioBitsPerSecond: 128000,
                    videoBitsPerSecond: 2500000
                };
                
                const mediaRecorder = new MediaRecorder(stream, options);
                mediaRecorderRef.current = mediaRecorder;
                mediaRecorder.ondataavailable = handleDataAvailable;
                mediaRecorder.start(10);
                dispatch(setLoader(false));
            } catch (error) {
                console.error('MediaRecorder error:', error);
                dispatch(setLoader(false));
                dispatch(setErrorAlert("Camera access failed. Please check permissions and try again."));
            }
        })
        .catch(error => {
            console.error('getUserMedia error:', error);
            dispatch(setLoader(false));
            if (error.name === 'NotAllowedError') {
                dispatch(setErrorAlert("Camera access was denied. Please allow camera access and try again."));
            } else if (error.name === 'NotFoundError') {
                dispatch(setErrorAlert("No camera found. Please make sure your device has a camera."));
            } else {
                dispatch(setErrorAlert("Failed to access camera. Please try using a different browser."));
            }
        });
    }, [handleDataAvailable, handleStopRecording, dispatch]);


    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleVideoEnded = () => {
        setIsPlaying(false); // Update isPlaying state when video playback ends
    };

    useEffect(() => {
        if (!isRecording) {
            clearInterval(intervalRef.current); // Clean up interval if the component is unmounted
        }
    }, [isRecording]);


    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        const secs = (seconds % 60).toString().padStart(2, '0');
        return `${hrs}:${mins}:${secs}`;
    };

    const onSaveClick = async () => {
        dispatch(clearAlerts())
        try {
            let videoUrl = await handleUpload()
            const payload = {
                link: videoUrl,
                title: title,
                time: timer,
                filter: filter,
                type: 'video-message',
                overlayType: filter === "blue-overlay" || filter === "diz-overlay" ? filter : null
            }
            console.log("VideoMessage.jsx - Saving video with payload:", payload);
            navigate('/add-section/video-message/publish', { 
                state: { ...payload }
            });
            setVideoURL(null);
            videoRef.current = null;
            setTitle("");
            setRecordedChunks([]);
            setShowWebcam(false);
            setIsRecording(false);
            setIsPlaying(false);
            setTimer(0);
        } catch (error) {
            console.error("Save Error:", error);
        }
    };
    const handleRemoveVideo = () => {
        setRecordedChunks([]);
        setVideoURL(null)
        setShowWebcam(false)
        setIsRecording(false)
        setIsPlaying(false)
        setTimer(0)
        videoRef.current = null;
        if (isEdit) {
            setPreviosUrl(videoURL)
            setIsEdit(false)
        }
    };

    const handleUpload = useCallback(async () => {
        dispatch(setLoader(true));
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const formData = new FormData();
        formData.append('video', blob, 'recording.webm');

        try {
            const videoResponse = await postDataAPI('aws/videoUpload', formData);
            const videoUrl = videoResponse.data.data.videoUrl;
            return videoUrl
        } catch (error) {
            dispatch(setLoader(false));
            console.error('Error uploading video and thumbnail:', error);
        } finally {
            dispatch(setLoader(false));
        }
    }, [recordedChunks, dispatch]);

    // const onAdd = async (saveType) => {
    //     dispatch(clearAlerts())
    //     // if (!title) {
    //     //     dispatch(setErrorAlert("title is required"))
    //     //     return
    //     // }

    //     let videoUrl = videoURL
    //     if (!isEdit) {
    //         videoUrl = await handleUpload()
    //     }

    //     if (!videoUrl) {
    //         return
    //     }
    //     const payload = { link: videoUrl };
    //     payload.saveType = saveType
    //     payload.title = title
    //     payload.time = timer
    //     payload.filter = filter
    //     let previosUrlVar = previosUrl
    //     dispatch(add_video_message_thunk({ videoMessage: payload, previosUrl: previosUrlVar, token }));
    //     setVideoURL(null)
    //     videoRef.current = null;
    //     setTitle("")
    //     setRecordedChunks([]);
    //     setShowWebcam(false)
    //     setIsRecording(false)
    //     setIsPlaying(false)
    //     setTimer(0)
    //     setIsEdit(false)
    // };

    const onEdit = () => {
        setOverlayVisible(false);
        setIsEdit(true)
        setVideoURL(link)
        setTitle(titleState)
        setTimer(timerBackend)
    }

    const handleFilterChange = (e) => {
        if (e.target.checked) {
            setFilter(e.target.value); // set the selected filter
        }
    };

    const recordingHandler = () => {
        if (title && title.length >= 2 && title.length <= 20) {
            dispatch(setLoader(true));
            try {
                handleStartRecording();
            } catch (error) {
                console.error('Recording handler error:', error);
                dispatch(setLoader(false));
                dispatch(setErrorAlert("Failed to start recording. Please try using a different browser."));
            }
        } else {
            if (!title || title.length < 2) {
                dispatch(setErrorAlert("Title must be at least 2 characters"));
            } else if (title.length > 20) {
                dispatch(setErrorAlert("Title must be less than 20 characters"));
            }
        }
    };

    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "user",
        frameRate: { ideal: 60, min: 30 }
    };

    const getVideoFilter = (currentFilter) => {
        if (currentFilter === "blue-overlay" || currentFilter === "diz-overlay") {
            return "none";  // No CSS filter for overlay filters
        }
        return currentFilter;
    };

    useEffect(() => {
        return () => {
            // Cleanup on unmount
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            dispatch(setLoader(false));
        };
    }, []);

    // iOS file upload handler
    const handleIOSVideoUpload = async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;
        
        dispatch(setLoader(true));
        dispatch(clearAlerts());

        try {
            // Check file type
            if (!file.type.startsWith('video/')) {
                dispatch(setErrorAlert('Please select a video file'));
                return;
            }

            // Check file size (e.g., 100MB limit)
            if (file.size > 100 * 1024 * 1024) {
                dispatch(setErrorAlert('Video file is too large. Maximum size is 100MB'));
                return;
            }

            const formData = new FormData();
            formData.append('video', file);

            const videoResponse = await postDataAPI('aws/videoUpload', formData);
            const videoUrl = videoResponse.data.data.videoUrl;

            if (videoUrl) {
                setVideoURL(videoUrl);
                // Handle successful upload
                // You might want to call your existing success handling code here
            }
        } catch (error) {
            console.error('Error uploading video:', error);
            dispatch(setErrorAlert('Failed to upload video. Please try again.'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    // Render different UI based on platform
    const renderRecordingInterface = () => {
        if (isIOS) {
            return (
                <div className="ios-upload-container">
                    <label 
                        htmlFor="ios-video-upload"
                        className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-lg cursor-pointer hover:bg-blue-600"
                    >
                        <span className="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                            </svg>
                        </span>
                        Select Video
                    </label>
                    <input
                        id="ios-video-upload"
                        type="file"
                        accept="video/*"
                        capture="user"
                        className="hidden"
                        onChange={handleIOSVideoUpload}
                    />
                    <p className="text-sm text-gray-500 mt-2">
                        You can record a new video or choose an existing one from your library
                    </p>
                </div>
            );
        }

        return (
            // Your existing recording UI for non-iOS devices
            <div className="recording-container">
                {/* Existing webcam and recording controls */}
                {showWebcam && (
                    <Webcam
                        ref={webcamRef}
                        audio={true}
                        videoConstraints={{
                            facingMode: "user"
                        }}
                    />
                )}
                {/* ... rest of your existing recording UI ... */}
            </div>
        );
    };

    const handleVideoUpload = async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;
        
        if (!title || title.length < 2 || title.length > 20) {
            dispatch(setErrorAlert('Please enter a title between 2-20 characters'));
            return;
        }

        // Check file size (50MB limit)
        if (file.size > 50 * 1024 * 1024) {
            dispatch(setErrorAlert('Video file is too large. Maximum size is 50MB'));
            return;
        }

        dispatch(setLoader(true));
        dispatch(clearAlerts());

        try {
            if (!file.type.startsWith('video/')) {
                dispatch(setErrorAlert('Please select a video file'));
                return;
            }

            const formData = new FormData();
            formData.append('video', file);
            formData.append('title', title);
            formData.append('type', 'video-message');
            formData.append('filter', DEFAULT_FILTER);

            const videoResponse = await postDataAPI('aws/videoUpload', formData, token);
            const videoUrl = videoResponse?.data?.data?.videoUrl;
            
            if (videoUrl) {
                // Batch state updates
                const updates = () => {
                    setVideoURL(videoUrl);
                    setShowWebcam(false);
                    setFilter(DEFAULT_FILTER);
                    setTitle('');
                };
                updates();

                await dispatch(add_video_message_thunk({ 
                    token,
                    videoMessage: {
                        link: videoUrl,
                        title,
                        filter: DEFAULT_FILTER,
                        type: 'video-message',
                        saveType: 'draft',
                        time: 0,
                        publish: { value: 'draft' }
                    }
                }));

                // Clear URL after successful upload
                setVideoURL(null);
                dispatch(setLoader(false));
            } else {
                throw new Error('No video URL received from server');
            }
        } catch (error) {
            console.error('Error uploading video:', error);
            if (error.response?.data) {
                console.log('Error response data:', error.response.data);
            }
            dispatch(setErrorAlert(
                error.response?.data?.message || 
                'Failed to upload video. Please try again.'
            ));
            dispatch(setLoader(false));
        }
    };

    // Helper function to get video duration
    const getVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                resolve(video.duration);
            };
            
            video.onerror = reject;
            video.src = URL.createObjectURL(file);
        });
    };

    return (
        <LayoutHeader>
            <div className="w-[350px] sm:w-[390px]   flex flex-col justify-start items-center relative z-[0]">
                {isOverlayVisible && <VideoMessageOverlay isOverlayVisible={isOverlayVisible} 
                setOverlayVisible={setOverlayVisible} link={link}/>}
                <div className='mt-[50px] w-full px-4 space-y-[24px]'>

                    <div className='  flex w-full justify-between text-white'>
                        <p onClick={() => navigate(-1)} className='text-white cursor-pointer' style={{ fontSize: '12px' }}>Video message</p>
                        <DizeeButton onClick={() => navigate(-1)} label={'Go back'} />
                    </div>

                </div>

                {
                    !videoURL && (
                        <div className='w-full h-full my-[50px] justify-center items-center flex'>
                            {showWebcam ? (
                                <div className="relative w-[350px] h-[350px] rounded-full overflow-hidden">
                                    <Webcam
                                        audio={true}
                                        ref={webcamRef}
                                        videoConstraints={videoConstraints}
                                        onUserMedia={() => {
                                            dispatch(setLoader(false));
                                            setWebcamReady(true);
                                            if (webcamRef.current && webcamRef.current.video) {
                                                webcamRef.current.video.muted = true;
                                            }
                                        }}
                                        muted={true}
                                        className="absolute w-full h-full object-cover"
                                        mirrored={false}
                                        style={{ 
                                            filter: filter === "diz-overlay" ? 
                                                   FILTERS.BW_FILTER : 
                                                   (filter === "blue-overlay" ? "none" : filter),
                                            transform: 'scaleX(-1)'
                                        }}
                                    />
                                    <div 
                                        className="absolute inset-0 pointer-events-none"
                                        style={{
                                            background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
                                            opacity: '0.03',
                                            mixBlendMode: 'overlay'
                                        }}
                                    />
                                    {filter === "red-overlay" && (
                                        <div 
                                            className="absolute inset-0" 
                                            style={{
                                                background: `radial-gradient(
                                                    circle at center,
                                                    rgba(255, 0, 0, 0.2) 0%,
                                                    rgba(255, 0, 0, 0.4) 40%,
                                                    rgba(255, 0, 0, 0.6) 100%
                                                )`,
                                                mixBlendMode: 'multiply',
                                                pointerEvents: 'none'
                                            }}
                                        />
                                    )}
                                    {filter === "blue-overlay" && (
                                        <div 
                                            className="absolute inset-0" 
                                            style={{
                                                backgroundColor: 'rgba(0, 0, 255, 0.5)',
                                                mixBlendMode: 'multiply',
                                                pointerEvents: 'none'
                                            }}
                                        />
                                    )}
                                    {filter === "diz-overlay" && (
                                        <div 
                                            className="absolute inset-0 pointer-events-none" 
                                            style={{
                                                background: `radial-gradient(
                                                    circle at center,
                                                    rgba(255, 0, 0, 0.2) 0%,
                                                    rgba(255, 0, 0, 0.35) 50%,
                                                    rgba(255, 0, 0, 0.5) 100%
                                                )`,
                                                mixBlendMode: 'multiply'
                                            }}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <div className="w-[350px] h-[350px] border border-white rounded-full flex justify-center items-center relative overflow-hidden">
                                        <p className='text-white text-center'>
                                            Add a title to record or upload
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }

                {
                    !showWebcam && !videoURL && (
                        <div className='mb-[40px] w-full px-4 overflow-x-hidden'>
                            <div className='flex justify-center w-full ml-[0px] mb-[40px]'>
                                <DizeeInput
                                    placeholder={"Add a title"}
                                    value={title}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 20) {
                                            setTitle(e.target.value);
                                        }
                                    }}
                                    style={{ textAlign: 'center' }}
                                    className="text-center"
                                />
                            </div>

                            <div className='mt-[30px] mb-[40px] flex justify-center gap-[36px]'>
                                <button 
                                    className={`text-white cursor-pointer flex items-center ${isIOSSafari ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={recordingHandler}
                                    disabled={isIOSSafari}
                                    style={{ fontSize: '12px' }}
                                >
                                    <DotSvg width={6} height={6} color={'red'} className='mr-2' />
                                    record
                                </button>

                                <label className='text-white cursor-pointer' style={{ fontSize: '12px' }}>
                                    upload
                                    <input
                                        type="file"
                                        accept="video/*"
                                        onChange={handleVideoUpload}
                                        className="hidden"
                                    />
                                </label>
                            </div>

                            {isIOSSafari && (
                                <div className='text-[#666666] text-center mt-[20px] mb-[40px]' style={{ fontSize: '12px' }}>
                                    Recording not available in iOS Safari. Please use upload.
                                </div>
                            )}
                        </div>
                    )
                }
                {showWebcam && webcamReady && !isRecording && (
                    <div className='mb-[30px]'>
                        <div className='w-full flex items-center mb-[30px] justify-center flex-row'>
                            <button className='text-white cursor-pointer' style={{ fontSize: '12px' }} onClick={handleStartRecording}>
                                Tap here to start recording
                            </button>
                        </div>
                        <div className='text-white flex flex-col gap-y-[10px]'>
                            <div className='flex justify-between gap-x-[8px]'>
                                <div className="flex items-center cursor-pointer" 
                                     onClick={() => setFilter(DEFAULT_FILTER)}>
                                    <span className="mr-2 text-right w-[45px]">Normal</span>
                                    <div className="w-[10px]">
                                        {filter === DEFAULT_FILTER && <Check className='h-[12px] w-[18px]' />}
                                    </div>
                                </div>
                                <div className="flex items-center cursor-pointer" 
                                     onClick={() => setFilter(FILTERS.BW_FILTER)}>
                                    <span className="mr-2 text-right w-[45px]">B&W</span>
                                    <div className="w-[10px]">
                                        {filter === FILTERS.BW_FILTER && <Check className='h-[12px] w-[18px]' />}
                                    </div>
                                </div>
                                <div className="flex items-center cursor-pointer" 
                                     onClick={() => setFilter(GRAIN_FILTER)}>
                                    <span className="mr-2 text-right w-[45px]">Grain</span>
                                    <div className="w-[10px]">
                                        {filter === GRAIN_FILTER && <Check className='h-[12px] w-[18px]' />}
                                    </div>
                                </div>
                                <div className="flex items-center cursor-pointer" 
                                     onClick={() => setFilter("blue-overlay")}>
                                    <span className="mr-2 text-right w-[45px]">Drip</span>
                                    <div className="w-[10px]">
                                        {filter === "blue-overlay" && <Check className='h-[12px] w-[18px]' />}
                                    </div>
                                </div>
                                <div className="flex items-center cursor-pointer" 
                                     onClick={() => setFilter("diz-overlay")}>
                                    <span className="mr-2 text-right w-[45px]">Diz</span>
                                    <div className="w-[10px]">
                                        {filter === "diz-overlay" && <Check className='h-[12px] w-[18px]' />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showWebcam && isRecording && (
                    <div className='mb-[60px] flex flex-col items-center justify-center gap-y-[15px]'>
                        <div className='mt-2 text-[12px] bg-[red] text-white px-4 py-1 rounded-md shadow-lg'>
                            <span className='font-bold'>{formatTime(timer)}</span>
                        </div>
                        <div className='w-full flex items-baseline  justify-center flex-row gap-[36px]'>

                            <button className='text-white cursor-pointer' onClick={handleStopRecording} style={{ fontSize: '12px' }}>stop</button>
                            <CommonComponents text={'recording'} action={null} />

                        </div>

                    </div>
                )}

                {videoURL && (
                    <div className='w-full h-full my-[50px] justify-center items-center flex'>
                        <div className="w-[350px] h-[350px] rounded-full cursor-pointer flex justify-center items-center relative overflow-hidden">
                            <video 
                                ref={videoRef} 
                                src={videoURL} 
                                className="object-cover w-full h-full" 
                                onEnded={handleVideoEnded}
                                style={{ 
                                    filter: filter === "diz-overlay" ? 
                                                   FILTERS.BW_FILTER : 
                                                   (filter === "blue-overlay" ? "none" : filter),
                                    transform: 'scaleX(-1)',
                                    WebkitTransform: 'scaleX(-1)',
                                    imageRendering: 'high-quality'
                                }}
                            />

                            {(filter === "blue-overlay" || filter === "diz-overlay") && (
                                <div 
                                    className="absolute inset-0" 
                                    style={{
                                        background: filter === "blue-overlay" ? 
                                            'rgba(0, 0, 255, 0.5)' : 
                                            `radial-gradient(
                                                circle at center,
                                                rgba(255, 0, 0, 0.2) 0%,
                                                rgba(255, 0, 0, 0.35) 50%,
                                                rgba(255, 0, 0, 0.5) 100%
                                            )`,
                                        mixBlendMode: 'multiply',
                                        pointerEvents: 'none',
                                        opacity: filter === "diz-overlay" ? 0.85 : 1
                                    }}
                                />
                            )}

                            <button
                                onClick={handlePlayPause}
                                className="absolute inset-0 flex justify-center items-center text-white text-lg z-10"
                            >
                                {isPlaying ? '' : <PlayCircle className="h-[40px] w-[40px]" />}
                            </button>
                        </div>

                    </div>

                )}
                {videoURL && (
                    <div className='flex gap-x-4 mb-[60px]'>
                        <button className='text-white' onClick={async () => {
                            onSaveClick();
                            // await handleUpload()
                        }}
                        >Save</button>
                        <CommonComponents text={'re record'} action={handleRemoveVideo} />
                    </div>
                )}

                {videoMessage?.length > 0 && <div className="flex flex-col justify-center items-center w-full">
                    <div className='p-4 pb-[20px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                        <div className='items-center flex flex-row text-white w-full'>
                            <p>Added video messages</p>
                        </div>
                    </div>
                    {
                        videoMessage?.length > 0 && videoMessage?.map((item, index) => (
                            <div key={index} className='w-full  '>
                                <Card photo={item?.link} title={item?.title} saveType={item?.publish.value}
                                    setOverlayVisible={setOverlayVisible} setSaveType={setSaveType}
                                    setLink={setLink} setTitleState={setTitleState}
                                    timeBackend={item?.time} setTimerBackend={setTimerBackend}
                                    item={item} />
                            </div>
                        ))
                    }
                </div>}

            </div>
            {/* <ModalSaveVideo
                title="Save video"
                isOpen={activedTab}
                onClose={onCloseModal}
                options={videoMessageOpts}
                onAdd={onAdd}
            /> */}

        </LayoutHeader>
    );
}

