import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateVideoThunk, deleteVideoThunk } from '../../../store/addsection/addsectionThunk';
import { clearAlerts, setErrorAlert, setLoader } from '../../../store/alert/alertSlice';
import { Calendar } from '@nayojs/react-datetime-picker';
import DizeeTimeInput from '../../DizeeComponent/DizeeTimeInput';
import DizeeModal from '../../DizeeComponent/DizeeModal';
import DizeeButton from '../../DizeeComponent/DizeeButton';
import DizeeSelect from '../../DizeeComponent/DizeeSelect';import { DizeeInput2 } from '../../DizeeComponent/DixeeInput2';
import CopyLink from '../../../svg/CopyLink';
import { removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';


export default function VideoOverlay({ isOverlayVisible, setOverlayVisible, avatarForBackend }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const video = useSelector((state) => state.addsection.video)
    const index = video.findIndex(video => video.avatar === avatarForBackend)

    const [showCalendar, setShowCalendar] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');
    const [showEditor, setShowEditor] = useState(false)
    const [link, setLink] = useState('')

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    const selectDateHandler = (date) => {
        setDate(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
    }
    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    }
    const onSaveSchedule = () => {
        dispatch(clearAlerts());
        if (date.length < 1 || time.length < 5) {
            return;
        }
        const saveType = "schedule"
        const timer = `${date} ${time} ${pm ? 'pm' : 'am'}`
        dispatch(updateVideoThunk({ timer, avatarForBackend, saveType, token }));
        setOverlayVisible(false);
    }

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(deleteVideoThunk({ token: token, avatar: avatarForBackend }));
        setOverlayVisible(false);
    };

    const handleScheduleClick = () => {
        dispatch(clearAlerts());
        setShowCalendar(true)
    };

    const handlePublishClick = () => {
        const saveType = "publish"
        dispatch(clearAlerts());
        dispatch(updateVideoThunk({ avatarForBackend, saveType, token }));
        setOverlayVisible(false);
    };

    const handleEditorClick = () => {
        dispatch(clearAlerts());
        setShowEditor(true)
    }

    const onSaveEdit = () => {
        dispatch(clearAlerts())
        if (!link.length > 0) {
            dispatch(setErrorAlert("Please enter a link."))
            return
        }

        if (link?.length > 0) {
            dispatch(updateVideoThunk({ avatarForBackend, link, token }));
        }
        setLoader(true)
        setLink('')
        setLoader(false)
        setShowEditor(false);
    }

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            {video[index].publish.value === "draft" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditorClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : video[index].publish.value === "schedule" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditorClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Edit Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditorClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>}

            {showCalendar &&
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <Calendar selectDateHandler={selectDateHandler} calendarStyles={{
                            containerStyles: { backgroundColor: '#000' },
                            dateStyles: { color: '#fff' },
                            selectedDateStyles: { backgroundColor: '#000', color: '#ff2' },
                            headerStyles: { background: '#000' },
                            headerTitleTextStyles: { color: '#fff' },
                            headerTitleStyles: { color: '#fff' },
                        }} />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime='11:12'
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            }

            {showEditor && <DizeeModal isOverlayVisible={showEditor} setOverlayVisible={setShowEditor}>
                <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                    <div className='px-4 my-[50px] flex w-full justify-between'>
                        <p className='cursor-pointer text-white'>Edit details</p>
                        <div className='cursor-pointer flex gap-x-4'>
                            <DizeeButton label={'Confirm'} onClick={() => onSaveEdit()} />
                            <DizeeButton label={'Go back'} onClick={() => setShowEditor(false)} />
                        </div>
                    </div>
                    <div className='px-4 my-[80px] flex flex-col w-full'>
                        <div className='cursor-pointer text-white flex items-center justify-between'>
                            <p>Change thumbnail image</p>
                            <img src={removeUploadsFromUrl(video[index]?.avatar)}
                                alt='Video Cover'
                                className='object-cover h-[50px] w-[50px] rounded-[3px]' />
                        </div>
                        <div className='py-10 pb-[40px] flex w-full justify-between items-center'>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Enter a link"
                                    className="dizee-input w-full"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                            <CopyLink className='h-[14px] w-[14px] mx-1' />
                        </div>
                    </div>
                </div>
            </DizeeModal>
            }
        </div>
    )
}
