import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrossArrow from '../../svg/CrossArrow';

export default function RedirectCard({ txt, link, Click }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (Click) {
      Click();
    } else if (link) {
      navigate(link);
    }
  };

  return (
    <div className="w-full px-4 py-6 flex justify-between items-center">
      <span 
        onClick={handleClick}
        className="text-[12px] text-white hover:text-[#4d4d4d] transition-colors duration-200 cursor-pointer"
      >
        {txt}
      </span>
      <div 
        onClick={handleClick}
        className="cursor-pointer text-white hover:text-[#4d4d4d] transition-colors duration-200"
      >
        <CrossArrow className="h-[14px] w-[14px]" />
      </div>
    </div>
  );
}
