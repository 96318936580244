import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { update_save_type_thunk, delete_video_message_thunk, get_video_message_thunk } from '../../../store/user/userThunk';
import { clearAlerts, setErrorAlert } from '../../../store/alert/alertSlice';
import { Calendar } from '@nayojs/react-datetime-picker';
import DizeeTimeInput from '../../DizeeComponent/DizeeTimeInput';
import DizeeModal from '../../DizeeComponent/DizeeModal';
import DizeeButton from '../../DizeeComponent/DizeeButton';
import DizeeSelect from '../../DizeeComponent/DizeeSelect';

export default function VideoMessageOverlay({ isOverlayVisible, setOverlayVisible, link }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();

    const [showCalendar, setShowCalendar] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');

    const selectDateHandler = (date) => {
        setDate(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
    }
    
    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    }
    const onSaveSchedule = () => {
        dispatch(clearAlerts());
        if (date.length < 1 || time.length < 5) {
            return;
        }
        const saveType = "schedule"
        const timer = `${date} ${time} ${pm ? 'pm' : 'am'}`
        dispatch(update_save_type_thunk({ timer, link, saveType, token }));
        setOverlayVisible(false);
    }

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    const handleScheduleClick = () => {
        dispatch(clearAlerts());
        setShowCalendar(true)
    };

    const handlePublishClick = async () => {
        dispatch(clearAlerts());
        
        try {
            // Get current state
            const currentState = await dispatch(get_video_message_thunk({ token }));
            const publishedVideo = currentState.payload.find(v => v.publish?.value === 'publish');
            
            // If there's a published video and it's not the current one
            if (publishedVideo && publishedVideo.link !== link) {
                // Set to draft
                const draftResult = await dispatch(update_save_type_thunk({ 
                    link: publishedVideo.link, 
                    saveType: 'draft', 
                    token,
                    filter: publishedVideo.filter,
                    overlayType: publishedVideo.overlayType
                }));
                
                if (draftResult.error) {
                    throw new Error('Failed to set current published video to draft');
                }
            }
            
            // Publish the new video
            const publishResult = await dispatch(update_save_type_thunk({ 
                link, 
                saveType: 'publish', 
                token,
                filter: videomessage[index].filter,
                overlayType: videomessage[index].overlayType
            }));
            
            if (publishResult.error) {
                throw new Error('Failed to publish new video');
            }
            
            // Update the state after all operations
            await dispatch(get_video_message_thunk({ token }));
            
            setOverlayVisible(false);
        } catch (error) {
            console.error('Error in handlePublishClick:', error);
            dispatch(setErrorAlert('Failed to update video status'));
        }
    };

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(delete_video_message_thunk({ link, token }));
        setOverlayVisible(false);
    };

    const videomessage = useSelector((state) => state.user.videoMessage);
    const index = videomessage.findIndex(video => video.link === link)

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >

            {videomessage[index].publish.value === "draft" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : videomessage[index].publish.value === "schedule" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Edit Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Preview</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>}

            {showCalendar &&
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <Calendar selectDateHandler={selectDateHandler} calendarStyles={{
                            containerStyles: { backgroundColor: '#000' },
                            dateStyles: { color: '#fff' },
                            selectedDateStyles: { backgroundColor: '#000', color: '#ff2' },
                            headerStyles: { background: '#000' },
                            headerTitleTextStyles: { color: '#fff' },
                            headerTitleStyles: { color: '#fff' },
                        }} />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime='11:12'
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            }
        </div>
    )
}
