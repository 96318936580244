import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearAlerts, setLoader } from "../../../store/alert/alertSlice";
import { postDataAPI } from "../../../utils/fetchData";
import { DizeeInput2 } from "../../../components/DizeeComponent/DixeeInput2";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import { NextButton } from "../../../components/DizeeComponent/NextButton";
import styled from "styled-components";
import allowedDomains from '../../../assets/config/allowedBioLinks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const Step5 = ({ setStep, setUser, user }) => {
  const [debouncedBio, setDebouncedBio] = useState(user?.bio || "");
  const [data, setData] = useState(null);
  const [isValidDomain, setIsValidDomain] = useState(false);
  const dispatch = useDispatch();

  // Add useEffect for scroll control
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  // Function to validate URL domain
  const isValidBioLink = (url) => {
    try {
      const urlObj = new URL(url);
      return allowedDomains.some(domain => 
        urlObj.hostname === domain || 
        urlObj.hostname === `www.${domain}`
      );
    } catch {
      return false;
    }
  };

  // Update validation when bio changes
  useEffect(() => {
    if (user?.bio) {
      setIsValidDomain(isValidBioLink(user.bio));
    } else {
      setIsValidDomain(false);
    }
  }, [user?.bio]);

  useEffect(() => {
    const fetchMetaData = async () => {
      if (debouncedBio) {
        try {
          dispatch(clearAlerts());
          dispatch(setLoader(true));
          const token = localStorage.getItem("dizeeToken");
          const response = await postDataAPI(
            `user/getUrlMetadata`,
            { url: user?.bio },
            token
          );
          if (response.data.data.response.title === "Page not available") {
            return;
          }
          const urlObj = new URL(response.data.data.response?.url);
          let pathParts = urlObj.pathname.split("/");
          pathParts = pathParts[pathParts.length - 1];
          if (response.data.data.response?.image) {
            setData(response.data.data.response?.image);
            setUser({
              ...user,
              avatar: response.data.data.response?.image,
              social: {
                ...user?.social,
                name: pathParts,
                ig: pathParts,
                tt: pathParts,
                x: pathParts,
                fb: pathParts,
                sc: pathParts,
                yt: pathParts,
              },
            });
          }
          dispatch(setLoader(false));
        } catch (error) {
          dispatch(setLoader(false));
          console.error("Error fetching metadata:", error);
        }
      }
    };

    fetchMetaData();
  }, [debouncedBio]);

  const getFooterText = () => {
    if (!user?.bio) {
      return "Import your existing bio link to quickly set up your Dizee profile with your current content.";
    }
    
    if (!isValidDomain) {
      return "Please enter a valid bio link from supported platforms like Linktree, Beacons, or others.";
    }
    
    return "Great! We'll import your content from this bio link.";
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"Drop in your existing link in bio to start off"} speed={45} />
        <div style={{ marginTop: '2px' }}>
          <DizeeInput2
            placeholder={"Add a link"}
            value={user?.bio}
            onChange={(e) => {
              const value = e.target.value;
              // Add https:// if not present
              const formattedValue = value.startsWith('http') ? 
                value : `https://${value}`;
              setUser({ ...user, bio: formattedValue });
            }}
            className={"dizee-input w-[200px]"}
          />
        </div>

        <ButtonContainer>
          <NextButton
            className={`cursor-pointer mr-[22px]`}
            onClick={() => setStep(6)}
          >
            {user?.bio && isValidDomain ? "Next" : "Skip"}
          </NextButton>
          <NextButton className="cursor-pointer" onClick={() => setStep(4)}>
            Go back
          </NextButton>
        </ButtonContainer>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          {getFooterText()}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step5;
