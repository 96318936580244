import React, { useState } from 'react'
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import { useDispatch } from 'react-redux';
import More from '../../../svg/More';
import PreviewOverlay from '../../PreviewOverlay';

export default function AddedProductCard({ item, setLinkForBackend, setOverlayVisible, setItemForBackend }) {
    const dispatch = useDispatch();
    const [previewOverlayOpen, setPreviewOverlayOpen] = useState(false);

    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                <img src={removeUploadsFromUrl(item?.image)} alt='alt' className='object-cover h-[50px] w-[50px] rounded-[3px]' />
                <div className='flex flex-col items-start justify-start h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>{maxLength(item?.productName, 30)}</p>
                </div>
            </div>
            <div className='flex'>
                <div 
                    className='text-white cursor-pointer mr-7'
                    onClick={() => setPreviewOverlayOpen(true)}
                >
                    {item?.publish?.value === 'publish' ? 'preview' : item?.publish?.value}
                </div>
                <More className='h-[20px] w-[20px] cursor-pointer' onClick={() => {
                    setItemForBackend(item)
                    setOverlayVisible(true)
                    setLinkForBackend(item?.links[0]?.url)
                }} />
            </div>

            {previewOverlayOpen && (
                <PreviewOverlay 
                    isOpen={previewOverlayOpen}
                    onClose={() => setPreviewOverlayOpen(false)}
                    selectedSection="product"
                    item={item}
                    type="product"
                />
            )}
        </div>
    )
}
