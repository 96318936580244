import React, { useEffect, useState } from 'react'
import { loginWithOtp, sendOtpToEmailThunk, loginPhoneNumberVerify } from '../../store/auth/authThunk';
import { useLocation, useNavigate } from 'react-router';
import DizeeInput from '../../components/DizeeComponent/DizeeInput';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../layout';
import { clearAlerts } from '../../store/alert/alertSlice';
import styled from 'styled-components';
import dizeeLogo from "../../assets/images/dizee logo.svg";
import TypeWriter from "../../components/OnBoard/Typewriter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 43px;
`;

const ActionButton = styled.button`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  background: transparent;
  border: none;
  padding: 0;
  font-family: inherit;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

export default function Otp() {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const location = useLocation();

    const [code, setCode] = useState("");
    const { email } = location.state || {};
    const { phone } = useSelector((state) => state.user);
    
    useEffect(() => {
        dispatch(clearAlerts())
        dispatch(loginPhoneNumberVerify({ phone, email }));
    }, [dispatch, phone, email]);

    useEffect(() => {
        if (code.length === 6) {
            dispatch(clearAlerts())
            dispatch(loginWithOtp({ email, code, phone })).then((res) => {
                if (res.payload.status === 200) navigation("/");
            });
        }
    }, [code, dispatch, phone, navigation, email]);

    const handleSendToEmail = () => {
        dispatch(sendOtpToEmailThunk(email))
            .then((res) => {
                if (res.payload.status === 200) {
                    navigation('/login-otp-email')
                }
            });
    };

    return (
        <Layout>
            <Container>
                <FormSection>
                    <TypeWriter 
                        text={`Code has been sent to (****) *** ${phone?.slice(-4)}`} 
                        speed={45} 
                    />
                    <form>
                        <div className="mb-[68px]">
                            <DizeeInput
                                placeholder={"Enter the code"}
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                            />
                        </div>
                        <ButtonContainer>
                            <ActionButton type="submit">
                                Confirm
                            </ActionButton>
                            <ActionButton 
                                type="button" 
                                onClick={handleSendToEmail}
                            >
                                Send to email
                            </ActionButton>
                        </ButtonContainer>
                    </form>
                </FormSection>
                
                <Footer>
                    <LogoImage src={dizeeLogo} alt="DIZEE" />
                    <FooterText>
                        Enter the verification code sent to your phone number.
                    </FooterText>
                </Footer>
            </Container>
        </Layout>
    );
}
