import { useDispatch } from "react-redux";
import axios from "./axios";
import { postDataAPI } from "./fetchData";

// Helper function to check file type
const isValidImageType = (file) => {
  const validTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/heic',
    'image/heif',
    'image/webp'
  ];
  return validTypes.includes(file.type.toLowerCase());
};

// Helper function to check file size
const isValidFileSize = (file, maxSizeMB = 10) => {
  return file.size <= maxSizeMB * 1024 * 1024;
};

export const uploadImage = async (file) => {
  if (!file) return true;

  try {
    // Validate file type and size
    if (!isValidImageType(file)) {
      throw new Error('Invalid file type. Please upload a valid image file.');
    }

    if (!isValidFileSize(file)) {
      throw new Error('File size too large. Maximum size is 10MB.');
    }

    // Process image before upload
    const processedFile = await processImageForUpload(file);
    
    // Create FormData with processed image
    const formData = new FormData();
    
    // Handle iOS HEIC/HEIF format
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isHeicFormat = ['heic', 'heif'].includes(fileExtension);
    
    // Rename file with timestamp and proper extension
    const fileName = `image-${Date.now()}.${isHeicFormat ? 'jpg' : fileExtension}`;
    const finalFile = new File(
      [processedFile], 
      fileName,
      { 
        type: isHeicFormat ? 'image/jpeg' : processedFile.type,
        lastModified: Date.now()
      }
    );

    formData.append('image', finalFile);

    // Implement retry logic
    const maxRetries = 3;
    let attempt = 0;
    let lastError = null;

    while (attempt < maxRetries) {
      try {
        const imageResponse = await postDataAPI('aws/imageUpload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          timeout: 30000, // 30 second timeout
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log('Upload progress:', percentCompleted + '%');
          }
        });

        const imageUrl = imageResponse?.data?.data?.imageUrl;
        
        if (imageUrl) {
          console.log('Image uploaded successfully:', imageUrl);
          return imageUrl;
        }

        throw new Error('No image URL received from server');
      } catch (err) {
        lastError = err;
        if (attempt === maxRetries - 1) break;
        
        // Exponential backoff
        const backoffTime = Math.min(1000 * Math.pow(2, attempt), 5000);
        await new Promise(resolve => setTimeout(resolve, backoffTime));
        attempt++;
      }
    }

    // If all retries failed, throw the last error
    console.error('Upload failed after retries:', lastError);
    throw lastError;

  } catch (err) {
    console.error('Error uploading file:', {
      message: err.response?.data?.message || err.message,
      status: err.response?.status,
      statusText: err.response?.statusText
    });
    return false;
  }
};

// Reference existing processImageForUpload function
const processImageForUpload = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;
        const maxDimension = 1200;

        if (width > height && width > maxDimension) {
          height = (height * maxDimension) / width;
          width = maxDimension;
        } else if (height > maxDimension) {
          width = (width * maxDimension) / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const processedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(processedFile);
          },
          'image/jpeg',
          0.8
        );
      };
      img.onerror = reject;
      img.src = e.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};