import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Layout from "../../layout";
import { DizeeInput2 } from "../../components/DizeeComponent/DixeeInput2";
import styled, { keyframes, css } from "styled-components";
import {
  checkEmail as Check,
  sendOtpToEmailThunk,
} from "../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../store/alert/alertSlice";
import dizeeLogo from "../../assets/images/dizee logo.svg";
import TypeWriter from "../../components/OnBoard/Typewriter";

const BaseInput = styled.input`
  background: transparent;
  border: none;
  font-size: 12px;
  padding: 0;
  color: white;
  height: 18px;
  line-height: 1.5;
  // margin: 10px 0 40px 0;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    caret-color: white;
  }
`;

// Add the shimmer animation keyframe
const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const Input = styled(BaseInput).attrs(props => ({
  'data-available': props.isAvailable,
  'data-recognized': props.isRecognized,
  isAvailable: undefined,
  isRecognized: undefined
}))`
  width: 100%;
  transition: all 0.3s ease;
  background: transparent;
  margin: 2px 0;
  position: relative;
  color: ${props => {
    if (!props['data-available'] || !props['data-recognized']) return 'white';
    return '#90EE90';
  }};

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    caret-color: white;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
  
  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  display: flex;
  align-items: center;
`;

const NextButton = styled.button`
  font-size: 12px;
  color: white;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.5 : 1};
  text-align: left;
  height: 18px;
  line-height: 18px;
`;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { checkemail } = useSelector((state) => state.auth);

  // Add this useEffect for scroll control
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    dispatch(clearAlerts());
    if (!isValidEmail(email)) return;
    if (email.length) dispatch(Check(email));
  }, [email, dispatch]);

  const resetEmail = () => {
    setEmail("");
  };

  const handleCreateAccount = (e) => {
    e.preventDefault(); // Prevents default form submission behavior
    if (!isValidEmail(email)) return;
    navigate(`/signup?email=${email}`);
  };

  return (
    <Layout>
      <Container>
        <FormSection>
          <TypeWriter text={"Login or sign up to get started"} speed={45} />
          <form onSubmit={handleCreateAccount}>
            <Input
              placeholder={"Enter your email"}
              value={email}
              isAvailable={isValidEmail(email)}
              isRecognized={checkemail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ButtonContainer show={email.length > 0 && isValidEmail(email)}>
              {!checkemail ? (
                <NextButton type="submit">
                  Create a new account
                </NextButton>
              ) : (
                <div style={{ display: 'flex', width: '100%' }}>
                  <NextButton 
                    type="button" 
                    style={{ marginRight: '43px' }}
                    onClick={() => navigate("/login-otp", { state: { email } })}
                  >
                    Login
                  </NextButton>
                  <NextButton 
                    type="button"
                    onClick={resetEmail}
                  >
                    Reset
                  </NextButton>
                </div>
              )}
            </ButtonContainer>
          </form>
        </FormSection>
        <Footer>
          <LogoImage src={dizeeLogo} alt="DIZEE" />
          <FooterText>
            By clicking "Next" you agree to the DIZEE TOS{'\n'}
            and Privacy Policy.
          </FooterText>
        </Footer>
      </Container>
    </Layout>
  );
};

export default Home;
