import React, { useState } from 'react';
import More from '../../../svg/More';
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import PreviewOverlay from '../../PreviewOverlay';

export default function AddedMusicCard({ music, setOverlayVisible, setAvatarForBackend }) {
    const [showPreview, setShowPreview] = useState(false);

    // Add safety check for avatar
    const getAvatarUrl = () => {
        if (!music?.avatar) return '';
        if (typeof music.avatar === 'string') {
            return removeUploadsFromUrl(music.avatar);
        }
        return '';
    };

    // Get publish status text safely
    const getPublishStatus = () => {
        try {
            if (!music?.publish) return '';
            if (typeof music.publish === 'string') {
                return music.publish === 'publish' ? 'preview' : music.publish;
            }
            if (typeof music.publish?.value === 'string') {
                return music.publish.value === 'publish' ? 'preview' : music.publish.value;
            }
            return '';
        } catch (error) {
            return '';
        }
    };

    const handlePreviewClick = (e) => {
        e.stopPropagation();
        setShowPreview(true);
    };

    // Only render if we have valid music data
    if (!music) return null;

    const publishStatus = getPublishStatus();

    return (
        <>
            <div className='w-[350px] sm:w-[390px] p-5 flex flex-row justify-between items-center'>
                <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                    <img
                        src={getAvatarUrl()}
                        alt='Music Cover'
                        className='object-cover h-[50px] w-[50px] rounded-[3px]'
                    />
                    <div className='flex flex-col items-start justify-start h-full p-2'>
                        <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                            {maxLength(music?.title || '', 30)}
                        </p>
                        <p className='text-[#696974]' style={{ fontSize: '12px' }}>
                            {maxLength(music?.artist || music?.description || '', 30)}
                        </p>
                    </div>
                </div>
                <div className='flex items-center'>
                    {publishStatus && (
                        <button 
                            className='text-white hover:text-gray-300 text-[12px]'
                            onClick={handlePreviewClick}
                        >
                            {publishStatus}
                        </button>
                    )}
                    <More 
                        className='h-[20px] w-[20px] cursor-pointer ml-7'
                        onClick={() => {
                            setOverlayVisible(true);
                            setAvatarForBackend(music?.avatar || '');
                        }} 
                    />
                </div>
            </div>

            <PreviewOverlay 
                isOpen={showPreview}
                onClose={() => setShowPreview(false)}
                selectedSection="music"
                item={{
                    ...music,
                    avatar: getAvatarUrl(),
                    title: music?.title || '',
                    artist: music?.artist || music?.description || '',
                    links: music?.links || []
                }}
                type="music"
            />
        </>
    );
}
