import { useState, useMemo } from "react";
import Check from "../../../svg/Check";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import styled from "styled-components";
import { DizeeInput2 } from "../../../components/DizeeComponent/DixeeInput2";
import { professionalCategories } from "../../../assets/categories/professionalCategories";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const OptionsContainer = styled.div`
  width: 200px;
  margin-bottom: 40px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 12px;
  margin-bottom: 40px;
  height: 180px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const comment = {
  brand: "Connect your brand's online presence in one place. Share your story and make it easier for customers to find you.",
  creator: "Bring together your content from across the internet. Make it simple for your audience to discover everything you do.",
  musician: "Unite your music presence and social platforms. Help fans find and follow your journey across all channels.",
  streamer: "Combine your streaming channels and content. Let your community easily find all your online activities.",
  business: "Centralize your business presence and professional networks. Make it simple for clients to connect with you.",
  athletic: "Link your fitness content and social channels. Help your community follow your journey across platforms.",
};

const processCategories = () => {
  return professionalCategories.map(category => ({
    label: category,
    value: category.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_')
  }));
};

const defaultOptions = [
  { label: "Brand", value: "brand" },
  { label: "Creator", value: "creator" },
  { label: "Musician", value: "musician" },
  { label: "Streamer", value: "streamer" },
  { label: "Business", value: "business" },
  { label: "Athletic", value: "athletic" }
];

const categories = processCategories();

const PersonalOption = styled.span`
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  opacity: 0.5;
`;

const Step6 = ({ setStep, setUser, user }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCategories = useMemo(() => {
    return categories.filter(cat => 
      cat.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  return (
    <Container>
      <FormSection>
        <OptionsContainer>
          <div className="mb-[68px]">
            <TypeWriter text={"What describes you best?"} speed={45} />
          </div>
          
          <div className="mb-4">
            <DizeeInput2
              placeholder="Search categories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="dizee-input w-[200px]"
            />
          </div>

          <Options>
            {(searchTerm ? filteredCategories : defaultOptions)
              .map((type) => (
                <div
                  key={type.value}
                  className="flex justify-between cursor-pointer"
                  onClick={() => setUser({ 
                    ...user, 
                    hobbyType: type.value 
                  })}
                >
                  <div className="flex items-center">
                    {type.label}
                    <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                      {user.hobbyType === type.value && (
                        <Check className="h-full w-full mt-[2px]" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </Options>

          <ButtonContainer>
            <div
              className={`mr-[43px] cursor-pointer ${
                user.hobbyType !== undefined ? "font-default" : "font-gray"
              }`}
              onClick={() => {
                if (user.hobbyType !== undefined) {
                  setStep(9);
                }
              }}
            >
              Next
            </div>
            <div className="cursor-pointer" onClick={() => setStep(5)}>
              Go back
            </div>
          </ButtonContainer>
        </OptionsContainer>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          {comment[user?.hobbyType] || "Select the option that best describes your content and audience. This will help us customize your Dizee experience."}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step6;
