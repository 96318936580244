import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import { updatePreviousUrl } from '../../../store/customlinkData/customlinkDataSlice';
import More from '../../../svg/More';

export default function AddedCustomLink({ item }) {
    const dispatch = useDispatch();
    const [showOverlay, setShowOverlay] = useState(false);

    const handleMoreClick = () => {
        dispatch(updatePreviousUrl(item?.links[0]?.url));
        setShowOverlay(true);
    };

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setShowOverlay(false);
        }
    };

    return (
        <>
            <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
                <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                    <img 
                        src={removeUploadsFromUrl(item?.image)} 
                        alt='preview' 
                        className='object-cover h-[50px] w-[50px] rounded-[3px]' 
                    />
                    <div className='flex flex-col items-start justify-start h-full p-2'>
                        <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                            {maxLength(item?.title, 30)}
                        </p>
                    </div>
                </div>
                <div className='flex items-center gap-x-4'>
                    <span className='text-white text-[12px]'>{item?.publish?.value}</span>
                    <More 
                        className='h-[20px] w-[20px] cursor-pointer text-white'
                        onClick={handleMoreClick}
                    />
                </div>
            </div>

            {showOverlay && (
                <div
                    id="overlay"
                    className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
                    onClick={handleOverlayClick}
                >
                    <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                        <p className="cursor-pointer pb-[10px]" onClick={() => {
                            setShowOverlay(false);
                            // Add edit functionality here
                        }}>Edit link</p>
                        <p className="cursor-pointer pb-[10px]" onClick={() => {
                            // Add delete functionality here
                            setShowOverlay(false);
                        }}>Delete</p>
                        <p className="cursor-pointer pb-[10px]" onClick={() => setShowOverlay(false)}>Cancel</p>
                    </div>
                </div>
            )}
        </>
    );
}
