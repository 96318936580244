import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DizeeInput2 } from '../../../components/DizeeComponent/DixeeInput2';
import DizeeButton from '../../../components/DizeeComponent/DizeeButton';
import { clearAlerts, setErrorAlert } from '../../../store/alert/alertSlice';
import { addCustomLinkSectionToSectionThunk, getCustomLinkSectionThunk } from '../../../store/addsection/addsectionThunk';

export default function EditSectionOverlay({ setOverlayVisible, section }) {
    const dispatch = useDispatch();
    const [title, setTitle] = useState(section.title);
    const token = localStorage.getItem('dizeeToken');

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    const handleUpdateSection = async () => {
        if (!title.trim()) {
            dispatch(setErrorAlert('Please enter a section title'));
            return;
        }

        const payload = { 
            type: 0, 
            title: title.trim(),
            id: section.id 
        };

        try {
            await dispatch(addCustomLinkSectionToSectionThunk({ 
                token, 
                payload, 
                previousUrl: section.title 
            }));
            await dispatch(getCustomLinkSectionThunk({ token }));
            setOverlayVisible(false);
        } catch (error) {
            dispatch(setErrorAlert('Failed to update section'));
        }
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            <div className="p-6 rounded-md w-[90%] max-w-[390px]" onClick={e => e.stopPropagation()}>
                <div className='px-4 flex w-full justify-between items-center'>
                    <p className='text-white text-[12px]'>Edit Section</p>
                    <div className='flex gap-x-4'>
                        <DizeeButton label={'Confirm'} onClick={handleUpdateSection} />
                        <DizeeButton label={'Go back'} onClick={() => setOverlayVisible(false)} />
                    </div>
                </div>
                <div className='px-4 mt-[80px] flex flex-col w-full gap-y-6'>
                    <DizeeInput2
                        label="Section Title"
                        placeholder="Enter section title"
                        className="dizee-input w-full text-white"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
} 