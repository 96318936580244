import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../../components/DizeeComponent/DixeeInput2';
import CopyLink from '../../../../../svg/CopyLink';
import ImageSelectionCard from '../../../../../components/ImageSelectionCard';
import { useDispatch, useSelector } from 'react-redux';
import AddedMusicCard from '../../../../../components/AddSection/Music/AddedMusicCard';
import { uploadImage } from '../../../../../utils/upload';
import { clearAlerts, setErrorAlert } from '../../../../../store/alert/alertSlice';
import { getMusicThunk } from '../../../../../store/addsection/addsectionThunk';
import MusicOverlay from '../../../../../components/AddSection/Music/MusicOverlay';
import DizeeButton from '../../../../../components/DizeeComponent/DizeeButton';
import QueuedMusicCard from '../../../../../components/AddSection/Music/QueuedMusicCard';

// Add this CSS at the top of your file or in your styles
const buttonWrapperStyle = {
    transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
};

// Add these styles at the top of your component
const fadeTransition = {
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: 1,
    transform: 'translateY(0)',
};

const fadeOut = {
    opacity: 0,
    transform: 'translateY(5px)',
    visibility: 'hidden',
    position: 'absolute',
};

// Add this helper function at the top of your component
const isValidMusicEntry = (music) => {
    // Check if publish status is properly formatted
    if (music?.publish) {
        if (typeof music.publish === 'object' && music.publish.value) {
            return true;
        }
        if (typeof music.publish === 'string') {
            return true;
        }
        return false;
    }
    return true; // Allow entries without publish status (new entries)
};

export default function CustomPage() {
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const music = useSelector((state) => state.addsection.music);
    const dispatch = useDispatch();

    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [avatarForBackend, setAvatarForBackend] = useState('');

    const [entries, setEntries] = useState([]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const resetImage = () => {
        setSelectedImage(null);
        setImagePreview(null);
    };
    useEffect(() => {
        dispatch(clearAlerts())
        dispatch(getMusicThunk({ token }))
    }, [dispatch, token])

    const handleAdd = async () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all fields'))
            return
        }

        // First upload the image
        const url = await uploadImage(selectedImage);
        if (!url) {
            dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
            return;
        }

        // Create properly structured entry
        const newEntry = {
            type: 2, // for manual entry
            avatar: url,
            title,
            description,
            link,
            links: [{
                url: link
            }]
        };

        setEntries([...entries, newEntry]);
        
        // Clear form after adding to queue
        setTitle('');
        setDescription('');
        setLink('');
        setSelectedImage(null);
        setImagePreview(null);
    };

    const handleNext = async () => {
        if (entries.length === 0 && !isFormComplete) {
            dispatch(setErrorAlert('Please add at least one entry or complete the current form'))
            return
        }
        
        // If form is complete, upload image and create new entry
        if (isFormComplete) {
            const url = await uploadImage(selectedImage);
            if (!url) {
                dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                return;
            }
            
            const currentEntry = {
                type: 2,
                avatar: url,
                title,
                description,
                link,
                links: [{
                    url: link
                }]
            };
            navigate('/add-section/music/manual/publish', { 
                state: { entries: [...entries, currentEntry] } 
            });
        } else {
            // If entries exist in queue, proceed with those
            navigate('/add-section/music/manual/publish', { state: { entries } });
        }
    }

    const showButtons = title && link && selectedImage && description;

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
    };

    // Check if current form is completely filled
    const isFormComplete = title && link && selectedImage && description;

    // Check if form is empty (no partial filling)
    const isFormEmpty = !title && !link && !selectedImage && !description;

    // Show Next if: 
    // 1. Queue has items AND form is empty, OR
    // 2. Form is complete (regardless of queue), OR
    // 3. Queue has items AND form is complete
    const showNext = entries.length > 0 || isFormComplete;

    // Show Queue button only when form is complete
    const showQueue = isFormComplete;

    // Show Clear when form is partially filled (not empty and not complete)
    const showClear = !isFormEmpty && !isFormComplete;

    // Add clear function
    const handleClear = () => {
        setTitle('');
        setDescription('');
        setLink('');
        setSelectedImage(null);
        setImagePreview(null);
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Add details</p>
                    <div className='flex gap-[30px]'>
                        {showQueue && (
                            <div style={buttonWrapperStyle} 
                                 className="transition-opacity duration-300 ease-in-out">
                                <DizeeButton onClick={handleAdd} label={'Queue'} />
                            </div>
                        )}
                        
                        {showNext && (
                            <div style={buttonWrapperStyle} 
                                 className="transition-opacity duration-300 ease-in-out">
                                <DizeeButton onClick={handleNext} label={'Next'} />
                            </div>
                        )}
                        
                        {showClear && (
                            <div style={buttonWrapperStyle} 
                                 className="transition-opacity duration-300 ease-in-out">
                                <DizeeButton onClick={handleClear} label={'Clear'} />
                            </div>
                        )}

                        <p onClick={() => navigate('/add-section/add-music')} 
                           className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full gap-y-[50px]" >
                    <div className='w-full'>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Add song title"
                                    className="dizee-input w-full"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Artist name"
                                    className="dizee-input w-full"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <ImageSelectionCard
                            txt="Add cover image"
                            dotimgclss={false}
                            onImageChange={handleImageChange}
                            imagePreview={imagePreview}
                            resetImage={resetImage}
                        />
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Enter a link"
                                    className="dizee-input w-full"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                            <CopyLink className='h-[14px] w-[14px] mx-1' />
                        </div>
                    </div>

                    {/* Show queued items from local state */}
                    {entries?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>In Queue</p>
                                </div>
                            </div>
                            {entries.map((item, index) => (
                                <QueuedMusicCard
                                    key={index}
                                    music={item}
                                    onDelete={() => handleDeleteFromQueue(index)}
                                />
                            ))}
                        </div>
                    )}

                    {/* Show already added music from Redux state */}
                    {music?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full mt-8">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added Music</p>
                                </div>
                            </div>
                            {music.filter(item => {
                                // Filter out malformed entries
                                if (!item) return false;
                                if (item.publish && typeof item.publish === 'object' && !item.publish.value) return false;
                                return true;
                            }).map((item, index) => (
                                <AddedMusicCard
                                    key={index}
                                    music={item}
                                    setOverlayVisible={setOverlayVisible}
                                    setAvatarForBackend={setAvatarForBackend}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {isOverlayVisible && <MusicOverlay isOverlayVisible={isOverlayVisible}
                setOverlayVisible={setOverlayVisible} avatarForBackend={avatarForBackend} />}

        </LayoutHeader>
    );
}
