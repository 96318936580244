import React from 'react';
import { SECTION_TEMPLATES } from '../../../constants/sectionTemplates';
import Check from '../../../svg/Check';

export default function TemplateSelector({ onSelect, selectedTemplate }) {
    return (
        <div className="flex flex-col w-full gap-4">
            {Object.values(SECTION_TEMPLATES).map((template) => (
                <div
                    key={template.id}
                    onClick={() => onSelect(template)}
                    className="p-4 flex flex-col cursor-pointer"
                >
                    <div className="flex items-center">
                        <p className="text-white text-[12px]">{template.label}</p>
                        <div className="ml-2 mt-[1px] h-[12px] w-[18px] flex items-center justify-center">
                            {selectedTemplate?.id === template.id && (
                                <Check className="h-full w-full" />
                            )}
                        </div>
                    </div>
                    <p className="text-gray-400 text-[12px] mt-1">
                        {template.fields.join(' ')}
                    </p>
                </div>
            ))}
        </div>
    );
} 