import React, { useEffect, useState } from 'react'
import LayoutHeader from '../../../../layout/LayoutHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { logoutThunk, sendOtpToEmailThunk, verifyEmailOTP, verifyPhone } from '../../../../store/auth/authThunk';
import { close_account } from '../../../../store/setting/settingThunk';

export default function CloseAccount() {
    const token = localStorage.getItem('dizeeToken')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userArticle = useSelector((state) => state.user.userArticle);
    const { email } = useSelector((state) => state.user.userArticle);
    const [flag, setFlag] = useState(true);

    const handleClose = () => {
        dispatch(close_account({ email, token }));
        dispatch(logoutThunk());
    };

    return (
        <div className="w-full flex flex-col h-screen justify-center items-center relative z-[0] text-white text-[12px]">

            {
                flag ?
                    <div className="flex flex-col gap-y-[30px]">
                        <span className="">Are you sure?</span>
                        <div className="flex flex-row items-center gap-x-[50px]">
                            <button onClick={() => { setFlag(false); handleClose(); }}>Confirm</button>
                            <button onClick={() => navigate('/more/setting/advanced')}>Go back</button>
                        </div>
                    </div> :
                    <div className="flex flex-col gap-y-[30px]">
                        <span className="">An email has been sent to confirm your account deletion</span>
                        <div className="flex flex-row items-center gap-x-[50px]">
                            <button onClick={() => navigate('/more/setting/advanced')}>Go back</button>
                        </div>
                    </div>
            }

        </div>
    )
}
