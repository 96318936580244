import React from 'react';
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import Plus from '../../../svg/Plus';

export default function QueuedProductCard({ product, onDelete }) {
    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                <img
                    src={removeUploadsFromUrl(product?.image)}
                    alt='Product Cover'
                    className='object-cover h-[50px] w-[50px] rounded-[3px]'
                />
                <div className='flex flex-col items-start justify-start h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                        {maxLength(product?.productName, 30)}
                    </p>
                    <p className='text-[#696974]' style={{ fontSize: '12px' }}>
                        {maxLength(product?.price, 30)}
                    </p>
                </div>
            </div>
            <button onClick={onDelete}>
                <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer' />
            </button>
        </div>
    );
} 