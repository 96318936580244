import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearAlerts } from '../../../store/alert/alertSlice';
import { deleteCustomLinkSectionThunk } from '../../../store/addsection/addsectionThunk';
import Check from '../../../svg/Check';
import More from '../../../svg/More';
import EditSectionOverlay from './EditSectionOverlay';

export default function AddedCustomSection({ item, isSelected, onSelect }) {
    const [showEditOverlay, setShowEditOverlay] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem('dizeeToken');

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setShowEditOverlay(false);
        }
    };

    const handleEditClick = () => {
        setShowEditor(true);
        setShowEditOverlay(false);
    };

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(deleteCustomLinkSectionThunk({ token, title: item.title }));
        setShowEditOverlay(false);
    };

    return (
        <>
            <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
                <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                    <div className='flex items-center justify-center gap-x-2 h-full py-2'>
                        <p onClick={onSelect} className='text-white cursor-pointer' style={{ fontSize: '12px' }}>
                            {item?.title}
                        </p>
                        {isSelected && <Check className='h-[12px] w-[18px] cursor-pointer' />}
                    </div>
                </div>
                <More 
                    className='h-[20px] w-[20px] cursor-pointer text-white'
                    onClick={() => setShowEditOverlay(true)}
                />
            </div>

            {showEditOverlay && (
                <div
                    id="overlay"
                    className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
                    onClick={handleOverlayClick}
                >
                    <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                        <p className="cursor-pointer pb-[10px]" onClick={handleEditClick}>Edit section</p>
                        <p className="cursor-pointer pb-[10px]" onClick={handleDeleteClick}>Delete</p>
                        <p className="cursor-pointer pb-[10px]" onClick={() => setShowEditOverlay(false)}>Cancel</p>
                    </div>
                </div>
            )}

            {showEditor && (
                <EditSectionOverlay 
                    section={item}
                    setOverlayVisible={setShowEditor}
                />
            )}
        </>
    );
}
