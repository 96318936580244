import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    section: null,
    selectedTemplate: null,
    previousUrl: '',
    title: '',
    image: null,
    link: ''
};

const customlinkDataSlice = createSlice({
    name: 'customlinkData',
    initialState,
    reducers: {
        updateSection: (state, action) => {
            state.section = action.payload;
        },
        updateTemplate: (state, action) => {
            state.selectedTemplate = action.payload;
        },
        clearCustomLinkData: () => initialState,
        updatePreviousUrl: (state, action) => {
            state.previousUrl = action.payload;
        },
        updateTitle: (state, action) => {
            state.title = action.payload;
        },
        updateImage: (state, action) => {
            state.image = action.payload;
        },
        updateLink: (state, action) => {
            state.link = action.payload;
        }
    }
});

export const {
    updateSection,
    updateTemplate,
    clearCustomLinkData,
    updatePreviousUrl,
    updateTitle,
    updateImage,
    updateLink
} = customlinkDataSlice.actions;

export default customlinkDataSlice.reducer;
